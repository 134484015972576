import React from 'react';

import Card from 'react-bootstrap/Card';

// used in daily ticket counts, create offer, add member modal, publish modal 
export default function WarningCard({ text, size = "md", margin = "mb", variant, iconStyle, styles }) {

    return (
        <Card body className={`card--xs info-card ${margin}-4 ${variant ? `background-${variant}` : ''} ${styles || ''}`}>
            <Card.Text className={`info-text ${size ? `info-text-${size}` : ''} ${variant ? `info-text-${variant}` : ''} ${iconStyle ? `info-text-${iconStyle}` : ''} `}>{text}.
            </Card.Text>
        </Card>
    );
}
