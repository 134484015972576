import React from 'react';

import Form from 'react-bootstrap/Form';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Button from 'react-bootstrap/Button';

import { InfoIcon } from '../../InfoIcon';

export default function Description({ description, setDescription, isEditable }) {

    return (
        <Form.Group className='form-group' controlId='description'>
            <div className="form-label--flex">
                <Form.Label id="event-description">Event description</Form.Label>
                <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip>Add information about this event</Tooltip>}>
                    <Button variant="link">
                        <InfoIcon />
                    </Button>
                </OverlayTrigger>
            </div>
            <Form.Control
                as="textarea" rows={5}
                name="description"
                aria-labelledby='event-description'
                disabled={isEditable}
                placeholder="Add description for this event"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
            />
        </Form.Group>
    );
}
