import React, { useState, useEffect } from 'react'
import { isEqual } from 'lodash'

import { removeRoles } from '../../../utilities/api';

import Stack from 'react-bootstrap/Stack'
import Button from 'react-bootstrap/Button'

import { Role } from "./Role";
import { CreateRoleModal } from './CreateRoleModal';
import { DeleteModal } from '../../DeleteModal';

export default function Roles({ roles, permissions, createRoles, updateRoles, hasPermission = true, isCreating }) {

    // since isCheckAll state is asynchronous, this flag tells if individual checkboxes were checked before the Select All checkbox
    let justIsCheckAll = false;

    // new and existing role so objects match for controlled input fields 
    const [role, setRole] = useState('');

    // for editing - save role id for flag when sending original role when editing
    // for deleting
    const [id, setId] = useState();

    const [initialEditState, setInitialEditState] = useState()

    const [isNameValid, setIsNameValid] = useState(true)

    const [isCheckAll, setIsCheckAll] = useState(false);

    const [isCheck, setIsCheck] = useState([])

    const [showCreate, setShowCreate] = useState(false);

    const [showDelete, setShowDelete] = useState(false);

    const [isDisabled, setIsDisabled] = useState(false)

    const [isRemoving, setIsRemoving] = useState(false)

    // reset permissions when adding or editing 
    useEffect(() => {
        const isEdit = id && role;
        // if adding role  
        if (!isEdit) setIsCheck([]);
        // if editing existing role
        if (isEdit) {
            setIsCheck([...isCheck, ...role?.organization_permissions?.map(item => item.id)])

            // set initial state if editing
            setInitialEditState({ role, permissions: [...role?.organization_permissions?.map(item => item.id)] })
        }
    }, [id])

    useEffect(() => {
        // if no checkboxes are checked 
        if (isCheck.length === 0) setIsCheckAll(false)
    }, [isCheck])

    // keep checking properties within initial state object when it changes 
    useEffect(() => {
        setIsDisabled((isEqual(initialEditState?.role, role) && (initialEditState?.permissions.every(i => isCheck.includes(i)) && initialEditState?.permissions?.length === isCheck.length)) || !role?.name || !isNameValid || isCreating)
    }, [initialEditState, isCheck, role?.name, isNameValid, isCreating])

    // reset validation
    useEffect(() => {
        if (!isNameValid) setIsNameValid(true)
    }, [role.name])

    useEffect(() => {
        if (!isNameValid) {
            const modal = document.querySelector('#create-role .modal-body')
            modal.scrollTop = 0;
        }
    }, [isNameValid])

    // replace one or more space with ""
    const handleValid = () => {
        if (isSameAsAccountOwner()) {
            setIsNameValid(false)
        }
    }

    const isSameAsAccountOwner = () => {
        return (role?.name?.toLowerCase().replace(/ +/g, "").includes('accountowner'))
    }

    // handle create and edit
    const handleShowCreate = (_, role) => {
        setShowCreate(true)
        if (role) {
            setId(role?.id)
            setRole(role)
        }
    }

    const handleCloseCreate = () => {
        setShowCreate(false);
        setIsCheck([]);
        setRole('')
        setId()
        setInitialEditState()
    }

    const handleShowDelete = (id) => {
        setShowDelete(true);
        setId(id)
    }

    const handleCloseDelete = () => {
        setShowDelete(false);
        setId()
    }

    const handleCheck = e => {
        const { id, checked } = e.target;
        setIsCheckAll(false)
        justIsCheckAll = false
        setIsCheck([...isCheck, Number(id)]);
        if (!checked) {
            setIsCheck(isCheck.filter(item => item !== Number(id)));
        }
    };

    const handleSelectAll = e => {
        const { name, checked } = e.target;

        const obj = name ? permissions[`${name}`] : [...permissions['settings'], ...permissions['management']];

        if (checked) {
            // set flag to say that other categories have been checked - is true on next update since it is asynchronous 
            setIsCheckAll(true)
            // synchronous flag 
            justIsCheckAll = true

            if (name) {
                // if other categories are checked, add items to selected, if not just replace 
                if (!isCheckAll && !justIsCheckAll) setIsCheck(obj.map(item => item.id))
                else setIsCheck([...isCheck, ...obj.map(item => item.id)])

            }
            // select all categories        
            else {
                setIsCheck([...obj.map(item => item.id)])
            }
        }
        else {
            const ids = obj.map(item => item.id)
            // remove obj ids from array - check if obj id is in the isCheck array
            const filteredArray = isCheck.filter((i) => ids.indexOf(i) == -1)
            setIsCheck([...filteredArray])
        }
    }

    // check if all checkboxes in category are checked 
    const checker = (arr) => {
        let isCheckAll = arr.every(v => isCheck.includes(v.id))

        // all checkboxes in category are checked, switch flag on
        if (isCheckAll) setIsCheckAll(true)

        return isCheckAll
    }

    const handleCreate = (e) => {
        e.preventDefault()
        handleValid()
        if (isEqual(initialEditState?.role, role) || isSameAsAccountOwner()) return
        createRoles({ roleName: role?.name, permissions: isCheck, currentRole: id ? role : undefined }).then(() => {
            handleCloseCreate();
        })
    }

    const removeRole = () => {
        setIsRemoving(true)
        removeRoles({ data: { roleId: id } })
            .then((res) => {
                updateRoles(res.data)
                handleCloseDelete()
                setIsRemoving(false)
            })
            .catch((err) => {
                console.error(err)
                setIsRemoving(false)
            })
    }

    return (
        <>
            <div className='heading--flex-space-between mb-4'>
                <h1 className='fs-md fs-md-bold'>Roles</h1>
                <Button variant='outline-light' className="btn-plus" onClick={handleShowCreate}>Create a new role</Button>
            </div>
            <Stack as="ul" gap={2}>
                {roles.map((role, index) => (
                    <Role key={index} role={role} handleShowCreate={handleShowCreate} handleShowDelete={handleShowDelete} hasPermission={hasPermission} />
                ))}
            </Stack>

            <CreateRoleModal show={showCreate} handleClose={handleCloseCreate} permissions={permissions} id={id} role={role} setRole={setRole} handleValid={handleValid} isValid={isNameValid} isCheck={isCheck} checker={checker} handleSelectAll={handleSelectAll} handleCheck={handleCheck} handleCreate={handleCreate} isCreating={isCreating} isDisabled={isDisabled} />

            <DeleteModal entity="role" show={showDelete} handleClose={handleCloseDelete} id={role.id} isRemoving={isRemoving} handleDelete={removeRole} />
        </>
    )
}
