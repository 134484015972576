import React from 'react'

import { formatCurrency } from '../../../../../../utilities/helpers'

import Card from 'react-bootstrap/Card'
import Stack from 'react-bootstrap/Stack'

export default function RevenueCard({ level, fromObjs, seatsToMove, impact, remove, sumRevenue, sumNewRevenues }) {

    // if level is array - has only one item - it is when deleting - from obj
    if (level.length) {
        level = level[0]
    }

    const getSeats = (hasTotalSeats) => {
        // either level seats and seatsToMove or just seatsToMove 
        if (hasTotalSeats) {
            return [...level.seats, ...seatsToMove]
        } else {
            return [...seatsToMove]
        }
    }

    // get level with different seats assigned if moving 
    const getLevel = (hasTotalSeats) => {
        // get level with just the seats to move 
        if (seatsToMove && !remove) return { ...level, seats: getSeats(hasTotalSeats) }
        else return level
    }

    const getNewPotentialRevenue = () => {
        const sumOfOtherPricingLevels = sumNewRevenues(level, fromObjs, seatsToMove)
        if (remove) {
            // sum all pricing levels except for the one to remove 
            return sumOfOtherPricingLevels
        }
        else {
            // sum all pricing levels except for current one and add level to sum 
            return sumOfOtherPricingLevels + sumRevenue(getLevel(true))
        }
    }
    return (
        <Card body className="card--sm card-with-border">
            <Stack gap={2}>
                <div className="split-row">
                    <span>Impact</span>
                    <span className={`${impact < 0 || remove ? 'text-danger' : 'text-success'}`}>{impact < 0 || remove ? '-' : '+'}{formatCurrency(sumRevenue(getLevel(false)))}</span>
                </div>
                <div className="split-row">
                    <span>New potential revenue</span>
                    <span>{formatCurrency(getNewPotentialRevenue())}</span>
                </div>
            </Stack>
        </Card >
    )
}