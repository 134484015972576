import React, { useEffect } from 'react';
import moment from 'moment'

import { formatDateTime } from '../../../utilities/helpers'

import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { DateInputWrapper } from '../../DateInputWrapper';
import { TimeInputWrapper } from '../../TimeInputWrapper';

export default function DateTime({ event, isEditable, handleChange, setEventStart, eventStart }) {

    useEffect(() => {

    }, [event])

    return (
        <fieldset className='form-group'>
            <legend>Event Date</legend>
            <Row>
                <Col>
                    <DateInputWrapper
                        id="event-start"
                        isReadOnly={isEditable}
                        setDate={setEventStart}
                        selectedDate={eventStart}
                        startDate={eventStart}
                        size="sm"
                    />
                </Col>
                <Col>
                    <TimeInputWrapper
                        id="event-start-time"
                        isReadOnly={isEditable}
                        setDate={setEventStart}
                        selectedDate={eventStart}
                        size="sm"
                    />
                </Col>
            </Row>
            <Form.Check type='checkbox' className="form-check-display mt-4" id="event-start-display-checkbox">
                <Form.Check.Input
                    name="display_start_time"
                    type='checkbox'
                    disabled={isEditable}
                    checked={event?.display_start_time}
                    onChange={(e) => { handleChange(e, e.target.checked) }} />
                <div>
                    <Form.Check.Label id="event-start-display-checkbox">Display start time</Form.Check.Label>
                    <small className='form-check-text'>
                        The start time of your event will be displayed to attendees
                    </small>
                </div>
            </Form.Check>
            <p className='mt-4 info-text info-text-md'>
                <span>
                    The event date will be displayed as:
                    <span className="ps-1 fw-medium">
                        <span>{formatDateTime(moment(eventStart), 'dateOnly')}</span>
                        {event?.display_start_time && (
                            <>
                                <span className='px-1'>at</span>
                                <span>{formatDateTime(moment(eventStart), 'timeOnly')}</span>
                            </>
                        )}
                    </span>
                </span>
            </p>
        </fieldset>
    );
}
