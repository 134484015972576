import React, { useState } from 'react';

import Card from 'react-bootstrap/Card';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';

import { UploadImage } from '../UploadImage';
import { RequiredText } from '../../RequiredText';

export default function EventImage({ eventName, image, setImage, isDisabled, requiredFieldErrorStatus }) {

    const [coordinates, setCoordinates] = useState()

    const handleRemove = () => {
        setImage()
        setCoordinates()
    }
    return (
        <>
            <div className="card-body-heading card-body-heading--flex">
                <Card.Title as="h5">Event Image</Card.Title>
                {requiredFieldErrorStatus?.image && (<RequiredText />)}
            </div>
            <p className='mb-3 info-text fw-medium info-text-md info-text-fill'>
                <small>Use a high quality image in 1:1 ratio (eg. 1080 x 1080px).</small>
            </p>
            <div className={`${requiredFieldErrorStatus?.image ? "error-border" : ''} `}>
                <UploadImage selectedImage={image} setSelectedImage={setImage} coordinates={coordinates} setCoordinates={setCoordinates} size="lg" altText={`Cover art for ${eventName} event`} />
            </div>
            <Stack direction='horizontal' className='btn-group-flex justify-content-start'>
                {image && (
                    <Button variant='outline-light' disabled={isDisabled} onClick={handleRemove}>Replace</Button>
                )}
            </Stack>
        </>
    );
}
