import React from 'react';

import Modal from 'react-bootstrap/Modal';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';

export default function InvitationSentModal({ show, handleClose, email }) {

    return (
        <Modal centered animation={false} fullscreen="md-down" show={show} onHide={handleClose} backdrop="static" className='modal--lg'>
            <Modal.Body>
                <div className="modal-body-heading">
                    <h1 className="modal-body-heading-title">Invitation sent</h1>
                    <p>Your invite was sent to {email}</p>
                    <p>Let your team member know they can find the invitation link in their email.</p>
                </div>
                <Stack className="btn-group-flex">
                    <Button size="lg" onClick={handleClose}>
                        Close
                    </Button>
                </Stack>
            </Modal.Body>
        </Modal>

    );
}
