import React from "react";

import Card from 'react-bootstrap/Card';

import { Event } from "./Event";
import { LoadingContainer } from '../../LoadingContainer'

import './eventsList.scss';

export default function EventsList({ isLoading, events, eventStatus }) {

    return (
        <>
            <Card body>
                {isLoading ? (
                    <LoadingContainer />
                ) : (
                    <>
                        {events?.length > 0 ? (
                            <div id="events-table" className="list-table four-col" role="table">
                                <div className="flex-row list-table-header" role="rowgroup">
                                    <div className='list-table-col list-table-col-header lg-2' role="columnheader">
                                        <span>Event</span>
                                    </div>
                                    <div className="list-table-col list-table-col-header" role="columnheader">
                                        <span>Tickets sold</span>
                                    </div>
                                    <div className="list-table-col list-table-col-header" role="columnheader">
                                        <span>Total sales</span>
                                    </div>
                                    {/* <div className="list-table-col list-table-col-header lg" role="columnheader">
                            <span>Secondary sold</span>
                        </div>
                        <div className="list-table-col list-table-col-header lg" role="columnheader">
                            <span>Royalties</span>
                        </div> */}
                                    <div className="list-table-col list-table-col-header text-center" role="columnheader">
                                        <span>Status</span>
                                    </div>
                                </div>
                                {events.map((event, index) => (
                                    <Event key={index} event={event} eventStatus={eventStatus} />
                                ))}
                            </div>
                        ) : (
                            <>
                                <h1 className="fs-md text-center">No events to show</h1>
                                <p className="small text-center">Create events now and start selling tickets</p>
                            </>
                        )}
                    </>
                )}
            </Card>
        </>
    )
}