import React from 'react';

import DatePicker from 'react-datepicker';

export default function DateInput({ id, setDate, selectedDate, startDate, endDate, reference, minDate, maxDate, isReadOnly }) {

    // get min date depending on date input control
    // - if end date input control, block out dates before start date  
    const getMinDate = () => {
        if (id.indexOf('start') !== -1) {
            return new Date()
        } else {
            return startDate
        }
    }

    return (
        <DatePicker
            showPopperArrow={false}
            id={id}
            autoComplete='off'
            selected={selectedDate}
            readOnly={isReadOnly}
            onChange={(date) => date && setDate(date)}
            minDate={minDate || getMinDate()}
            maxDate={maxDate}
            startDate={startDate}
            endDate={endDate}
            placeholderText='MM/DD/YYYY'
            wrapperClassName="date-picker"
            calendarClassName='calendar-container'
            popperClassName='popper-container'
            popperModifiers={[
                {
                    name: 'preventOverflow',
                    options: {
                        boundary: reference.current // align calendar to edge of date card 
                    }
                }
            ]}
        />
    );
}
