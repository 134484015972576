import React from 'react';

import Form from 'react-bootstrap/Form';

export default function UserInfo({ id, report, reference, tags, removeTag, handleChange }) {
    return (
        <Form.Group className='form-group' controlId="email">
            <Form.Label>Email</Form.Label>
            <div className='input-tag-container'>
                {/* list of tags */}
                <ul className='input-tags'>
                    {/* each tag */}
                    {tags?.map((tag, i) => (
                        <li key={tag} className="input-tag">
                            <span className='input-tag-label'>{tag}</span>
                            <button onClick={() => removeTag(i)}>x</button>
                        </li>
                    ))}

                    {/* actual input to add tags inline */}
                    <li className="input-tags-input-container">
                        <Form.Control
                            aria-label='email'
                            ref={reference}
                            type="email"
                            onKeyDown={handleChange}
                        />
                    </li>
                </ul>
            </div>
            <Form.Text>Enter one or more recipient emails seperated by spaces</Form.Text>
        </Form.Group>
    );
}

