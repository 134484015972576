import React, { createContext, useContext, useState } from 'react';

// Create the context
export const InventoryContext = createContext(undefined);

export const useInventory = () => {
    const context = useContext(InventoryContext);
    if (!context) {
        throw new Error('Context must be used within a InventoryProvider');
    }
    return context;
}

// Create the provider component
export const InventoryProvider = ({ children }) => {
    const [holdsColor, setHoldsColor] = useState("#EF466F")
    const [holds, setHolds] = useState({})
    const [killsColor, setKillsColor] = useState("#B1B5C4")
    const [kills, setKills] = useState({})
    const [soldColor, setSoldColor] = useState('#45B26B')
    const [sold, setSold] = useState({})
    // only exclusive offers to easily access these offers in seat map 
    const [exclusiveOffers, setExclusiveOffers] = useState({})

    return (
        <InventoryContext.Provider value={{ holdsColor, holds, setHolds, killsColor, kills, setKills, sold, setSold, soldColor, exclusiveOffers, setExclusiveOffers }}>
            {children}
        </InventoryContext.Provider>
    );
};