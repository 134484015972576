import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { createPromoLink } from '../../../utilities/api';

import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';

import { TrackingLink } from './TrackingLink';
import { Spinner } from '../../LoadingContainer/Spinner';

export default function TrackingLinkWrapper({ eventId, setIsChangesMade }) {

    const navigate = useNavigate();

    const [link, setLink] = useState({
        name: '',
        uuid: eventId
    })

    const [isValid, setIsValid] = useState(false)

    const [isSaving, setIsSaving] = useState(false)

    useEffect(() => {
        if (!isValid) {
            setIsValid(true)
        }

    }, [link.name])


    const handleChange = (e) => {
        setLink({ ...link, [e.target.name]: e.target.value })
    }

    const handleSave = (e) => {
        e.preventDefault();

        if (/^[a-zA-Z0-9]+$/.test(link?.name)) {
            setIsSaving(true)
            createPromoLink({ data: link })
                .then((res) => {
                    setIsSaving(false)

                    if (!isSaving) {
                        setIsChangesMade(true)
                        navigate(-1)
                    }
                })
                .catch((err) => {
                    console.error(err)
                    setIsSaving(false)
                })
        } else {
            setIsValid(false)
        }
    }

    const validInputs = (e) => {
        setIsValid(e.target.value !== "" && e.target.validity.valid)
    }

    return (
        <section className='wrapper'>
            <header className="section-header section-heading">
                <h1>Create a new tracking link</h1>
            </header>
            <Form onSubmit={handleSave}>
                <Card body className='card--sm'>
                    <TrackingLink link={link} handleChange={handleChange} isValid={isValid} validInputs={validInputs} />
                </Card>
                <Stack direction="horizontal" className="btn-group-flex">
                    <Button variant="outline-light" size="lg" onClick={() => navigate(-1)}>Cancel</Button>
                    <Button
                        type="submit"
                        size="lg"
                        className="btn-width-xl"
                        disabled={!link.name || !isValid || isSaving}
                    >
                        {isSaving ? (
                            <Spinner />
                        ) :
                            'Create tracking link'
                        }
                    </Button>
                </Stack>
            </Form>
        </section>
    );
}
