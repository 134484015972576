import React from 'react';

import Modal from 'react-bootstrap/Modal';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';
import CloseButton from 'react-bootstrap/CloseButton';

import { MapConfigurationCard } from '../../MapConfigurationCard';
import { NewMapCard } from '../../NewMapCard';
import { ExistingTemplateCard } from '../../ExistingTemplateCard';

export default function ResetConfigurationModal({ show, handleClose, handleClick }) {

    return (
        <Modal show={show} animation={false} onHide={handleClose} centered backdrop='static' dialogClassName="modal-1000w">
            <div className="header--close">
                <Stack direction='horizontal' className='justify-content-end'>
                    <CloseButton onClick={handleClose} />
                </Stack>
            </div>
            <Modal.Body className='text-center pt-0'>
                <div className="modal-body-heading modal-body-heading-lg">
                    <h1 className='modal-body-heading-title'>Reset map configurations</h1>
                    <small className='subtitle'>Choose how you want to create your tickets. <span className="fw-bold">Note:</span> Your changes will be effective immediately after you proceed with one of the options below.</small>
                </div>
                <Stack direction='horizontal' gap={5} className='justify-content-center'>
                    <MapConfigurationCard type="reset" title="Reset map" subtitle="Undo all changes made">
                        <Button onClick={handleClick}>Start over</Button>
                    </MapConfigurationCard>
                    <NewMapCard handleClick={handleClick} />
                    <ExistingTemplateCard handleClick={handleClick} />
                </Stack>
            </Modal.Body>
        </Modal>
    );
}
