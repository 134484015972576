import { useParams, useSearchParams } from 'react-router-dom'

import { SeatMapWrapper } from "../../components";

export default function SeatMapPage() {

    const { uuid } = useParams()

    const [searchParams] = useSearchParams();

    return (
        <SeatMapWrapper eventId={uuid} offerId={searchParams.get("id")} />
    )
}