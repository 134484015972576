import React, { useEffect, useState, useRef } from 'react';

import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';

import { RequiredText } from '../../RequiredText';

export default function TicketLimit({ ticketLimit, setTicketLimit, hasTicketLimit, setHasTicketLimit, handleValid, errors, findError, isEditable, requiredFieldErrorStatus }) {

    const inputRef = useRef(null)

    const [error, setError] = useState()

    const [isLimitChecked, setIsLimitChecked] = useState(false)

    // focus input field only if minimum resale checkbox is enabled again after disabling
    useEffect(() => {
        if (isLimitChecked) {
            if (inputRef.current) {
                inputRef.current.focus()
            }
        }
    }, [isLimitChecked])

    useEffect(() => {
        setError(findError('ticketLimit'))
    }, [errors])

    // checking No limit 
    const handleHasTicketLimit = (val) => {
        if (!val) setTicketLimit('')
        setHasTicketLimit(val)
        setIsLimitChecked(val)
    }

    return (
        <>
            <div className="card-body-heading card-body-heading--flex">
                <Card.Title as="h5">Event ticket limit</Card.Title>
                {(error || requiredFieldErrorStatus?.limit) && (<RequiredText />)}
            </div>
            <div className="form-check--with-desc">
                <Form.Check
                    name="ticketLimit"
                    id="no-limit"
                    type='radio'
                    label="No limit"
                    disabled={isEditable}
                    checked={!hasTicketLimit && hasTicketLimit !== null}
                    onChange={(e) => handleHasTicketLimit(!e.target.checked)}
                    className={`${hasTicketLimit === null && requiredFieldErrorStatus?.limit ? 'form-check-error' : ''}`}
                />
                <small className='subtitle'>
                    No restrictions on ticket quantities
                </small>
            </div>
            <div className="form-check--with-desc">
                <Form.Check
                    name="ticketLimit"
                    id="custom-limit"
                    type='radio'
                    label="Global ticket limit"
                    disabled={isEditable}
                    checked={hasTicketLimit}
                    onChange={(e) => handleHasTicketLimit(e.target.checked)}
                    className={`${error || requiredFieldErrorStatus?.limit ? 'form-check-error' : ''}`}
                />
                <small className='subtitle'>
                    Set the overall ticket limit between 1-50 applicable to all offers and price levels sold through the Blocktickets website.
                </small>
            </div>
            {hasTicketLimit && (
                <Form.Group className='form-group' controlId='ticketLimit'>
                    <Form.Label>Limit</Form.Label>
                    <Form.Control
                        ref={inputRef}
                        placeholder="Amount"
                        name="ticketLimit"
                        aria-describedby="limit"
                        pattern="^[0-9]*$"
                        disabled={isEditable}
                        value={ticketLimit}
                        onChange={(e) => setTicketLimit(e.target.value === '' || e.target.validity.valid ? e.target.value : ticketLimit)}
                        onBlur={handleValid}
                        className={`w-25 ${error || requiredFieldErrorStatus?.limit ? 'error-border' : ''}`}
                        required
                    />
                    {error && (
                        <Form.Text className='error'>{error?.message}</Form.Text>
                    )}
                </Form.Group>
            )}
        </>
    );
}
