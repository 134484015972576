import React from 'react';

import { LoadingContainer } from '../LoadingContainer';

import './pageLoadingContainer.scss';

// page loading
export default function PageLoadingContainer({ style = '', size = "xl" }) {

    return (
        <div id="page-loading-container" className={`${style} ${size}`}>
            <LoadingContainer>
                {size === 'xl' && (
                    <h1 className='loading-text'>Loading...</h1>
                )}
            </LoadingContainer>
        </div>
    );
}
