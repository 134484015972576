import React from 'react'

import ListGroup from 'react-bootstrap/ListGroup'
import Stack from 'react-bootstrap/Stack'

import { ListItem } from './ListItem'
import { Item } from '../Item'

export default function InventoryMenu({ isEventOnsale, holds, kills, offers, sold, moveFromIds, handleRemove, handleAdd }) {

    return (
        <div className='list-container inventory-container'>
            <ListGroup as="ul" variant="flush">
                <ListGroup.Item as="li">
                    <ListItem title="Offers" data={offers}
                        moveFromIds={moveFromIds} />
                </ListGroup.Item>
                <ListGroup.Item as="li">
                    <ListItem title="Holds" data={holds}
                        moveFromIds={moveFromIds} handleRemove={handleRemove} handleAdd={handleAdd} />
                </ListGroup.Item>
                <ListGroup.Item as="li">
                    <ListItem title="Kills" data={kills}
                        moveFromIds={moveFromIds} handleRemove={handleRemove} handleAdd={handleAdd} />
                </ListGroup.Item>
                {isEventOnsale && (
                    <ListGroup.Item as="li">
                        <div className="heading--flex-space-between mb-2">
                            <span className='fw-semi-bold'>Sold</span>
                        </div>
                        <Stack as="ul">
                            <li>
                                <Item el={sold[Object.keys(sold)[0]]} />
                            </li>
                        </Stack>
                    </ListGroup.Item>
                )}
            </ListGroup>
        </div>
    )
}