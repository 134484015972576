import { useState } from "react";
import { createContext, useContext, useRef } from "react";
import { Modal, Form, Button, Alert } from "react-bootstrap";
import "./offersDiscountProvider.scss";
import { createDiscount } from "../../../utilities/api";

const DiscountContext = createContext(undefined);

export const useDiscount = () => {
  const context = useContext(DiscountContext);
  if (!context) {
    throw new Error("Context must be used withing a OfferDiscountProvider");
  }
  return context;
};

export const OffersDiscountProvider = ({ children, eventId }) => {
  const dialogRef = useRef(null);

  const openModal = () => {
    dialogRef.current.showModal();
  };

  const closeModal = () => {
    dialogRef.current.close();
  };

  return (
    <DiscountContext.Provider value={{ openModal, closeModal }}>
      {children}
      <DiscountModal
        dialogRef={dialogRef}
        handleClose={closeModal}
        eventId={eventId}
      />
    </DiscountContext.Provider>
  );
};

function DiscountModal({ dialogRef, handleClose, eventId }) {
  const [discountName, setDiscountName] = useState("");
  const [discountPercentage, setDiscountPercentage] = useState("");
  const [discountType, setDiscountType] = useState("ongoing");
  const [expirationDate, setExpirationDate] = useState("");

  const handleDiscountTypeChange = (e) => {
    const value = e.target.value;
    setDiscountType(value);
  };

  const handleClick = (event) => {
    if (event.target === dialogRef.current) {
      handleClose();
    }
  };

  const createOfferDiscount = async () => {
    const discountData = {
      eventId: eventId,
      name: discountName,
      discountPercentage: parseInt(discountPercentage, 10),
      type: discountType,
      expires:
        discountType === "time-limited"
          ? new Date(expirationDate).toISOString()
          : null,
    };

    try {
      let res = await createDiscount(discountData);
      console.log("create discount res: ", res);
      handleClose();
    } catch (error) {
      console.error("Error creating discount:", error);
    }
  };

  return (
    <dialog className='discount-modal' ref={dialogRef} onClick={handleClick}>
      <Modal.Header closeButton>
        <Modal.Title style={{ width: "100%", marginBottom: "24px" }}>
          Create Discount
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className='mb-3'>
            <Form.Label>Discount Name</Form.Label>
            <Form.Control
              type='text'
              placeholder='Enter discount name'
              value={discountName}
              onChange={(e) => setDiscountName(e.target.value)}
            />
          </Form.Group>

          <Form.Group className='mb-3'>
            <Form.Label>Discount Percentage</Form.Label>
            <Form.Control
              type='number'
              min='0'
              max='100'
              placeholder='Enter percentage'
              value={discountPercentage}
              onChange={(e) => setDiscountPercentage(e.target.value)}
            />
          </Form.Group>

          <Form.Group className='mb-3'>
            <Form.Label>Discount Type</Form.Label>
            <div className='mb-2'>
              <Form.Check
                type='radio'
                label='Ongoing'
                name='discountType'
                value='ongoing'
                checked={discountType === "ongoing"}
                onChange={handleDiscountTypeChange}
                style={{ marginBottom: "4px" }}
              />
              <small
                className='text-muted d-block ml-4'
                style={{ marginLeft: "35px", marginBottom: "12px" }}
              >
                The ongoing discount is usually used for things such as
                discounts for veterans, elderly etc, anything that you want to
                have available for the entire duration of the event.
              </small>
            </div>
            <div>
              <Form.Check
                type='radio'
                label='Time-limited'
                name='discountType'
                value='time-limited'
                checked={discountType === "time-limited"}
                onChange={handleDiscountTypeChange}
                style={{ marginBottom: "4px" }}
              />
              <small
                className='text-muted d-block ml-4'
                style={{ marginLeft: "35px", marginBottom: "12px" }}
              >
                Time-limited discounts are perfect for short-term promotions,
                such as early bird specials or limited-time offers. These
                discounts will be highlighted to users, encouraging them to take
                advantage of the offer before it expires.
              </small>
            </div>
          </Form.Group>

          {discountType === "time-limited" && (
            <Form.Group className='mb-3'>
              <Form.Label>Expiration Date</Form.Label>
              <Form.Control
                type='date'
                value={expirationDate}
                onChange={(e) => setExpirationDate(e.target.value)}
              />
            </Form.Group>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer
        style={{ gap: "8px", display: "flex", flexDirection: "row" }}
      >
        <Button variant='secondary' onClick={handleClose}>
          Cancel
        </Button>
        <Button variant='primary' onClick={createOfferDiscount}>
          Create Discount
        </Button>
      </Modal.Footer>
    </dialog>
  );
}
