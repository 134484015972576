import { useParams } from "react-router-dom"

import { EventSettingsWrapper } from "../../components";

export default function EventSettingsPage() {

    const { uuid } = useParams()

    return (
        <EventSettingsWrapper eventId={uuid} />
    )
}