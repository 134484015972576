import React from 'react';

import Button from 'react-bootstrap/Button'

// attractions -> event settings 
// pricing level description -> offers
// availability periods -> offer 
export default function EditButton({ variant, obj, onClick, isDisabled }) {

    const getColor = () => {
        switch (variant) {
            case 'primary':
                return '#3EA9F7'

            case 'white':
                return '#fcfcfd'

            default:
                return '#777E91'
        }
    }

    return (
        <Button
            variant='default'
            aria-label="Edit"
            onClick={(e) => onClick(e, obj)}
            disabled={isDisabled}
            className='border-0 btn-icon btn-icon-sm btn-icon--square'
        >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
                <path fillRule="evenodd" clipRule="evenodd" d="M14.5357 3.80837C15.7073 2.63679 17.6067 2.63679 18.7783 3.80837L20.1925 5.22258C21.3641 6.39415 21.3641 8.29365 20.1925 9.46522L9.80003 19.8577C9.66043 19.9973 9.48263 20.0925 9.28904 20.1312L3.98573 21.1919C3.28601 21.3318 2.66909 20.7149 2.80904 20.0152L3.8697 14.7119C3.90842 14.5183 4.00357 14.3405 4.14317 14.2009L14.5357 3.80837ZM17.3641 5.22258L18.7783 6.63679C19.1688 7.02732 19.1688 7.66048 18.7783 8.05101L17.3641 9.46523L14.5357 6.6368L15.9499 5.22258C16.3404 4.83206 16.9736 4.83206 17.3641 5.22258ZM13.1215 8.05101L5.77148 15.401L5.06437 18.9365L8.59991 18.2294L15.9499 10.8794L13.1215 8.05101Z" fill={getColor()} />
            </svg>
        </Button>
    );
}
