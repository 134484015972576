import React from 'react'

import Stack from 'react-bootstrap/Stack'

import { AddButton } from '../../AddButton'
import { InventoryItem } from './InventoryItem'
import { ItemName } from "../../../../../../ItemName";

export default function ListItem({ title, data, moveFromIds, handleRemove, handleAdd }) {

    return (
        <>
            <div className="heading--flex-space-between mb-2">
                <span className='fw-semi-bold'>{title}</span>
                {handleAdd && (
                    <AddButton entity={title.toLowerCase().replace('s', '')} onClick={handleAdd} />
                )}
            </div>
            <Stack as="ul">
                <>
                    {Object.values(data).map((el, index) => (
                        <li key={index}>
                            <InventoryItem data={data} index={`${title.toLowerCase().charAt(0)}-${el.id}`} entity={title.toLowerCase().replace('s', '')} el={el} moveFromIds={moveFromIds} hasSubSeatsAssigned={moveFromIds && moveFromIds.toString().indexOf(`${title.toLowerCase().charAt(0)}-${el.id}`) !== -1} canAdd={Boolean(el.categories)} canRemove={Boolean(el.categories)} handleRemove={handleRemove} handleAdd={handleAdd} />
                            {
                                el.categories && Object.keys(el.categories)?.length > 0 && (
                                    <ul>
                                        {Object.values(el.categories).map((cat, idx) => (
                                            <li key={idx}>
                                                <InventoryItem
                                                    index={`${title.toLowerCase().charAt(0)}-${el.id}-${cat.id}`}
                                                    el={cat}
                                                    data={data}
                                                    moveFromIds={moveFromIds}
                                                    isSubItem={true}
                                                    canRemove={true}
                                                    handleRemove={handleRemove} />
                                            </li>
                                        ))}
                                    </ul>
                                )
                            }
                            {
                                el.subOffers && (
                                    <Stack as="ul" gap={2} className='my-1'>
                                        <li>
                                            <ItemName el={{ color: el?.color, name: 'Standard Admission' }} isSubItem={true} weight="thin" />
                                        </li>
                                        {
                                            el.subOffers?.map((offer, idx) => (
                                                <li key={idx}>
                                                    <ItemName el={offer} isSubItem={true} weight="thin" />
                                                </li>
                                            ))}
                                    </Stack>
                                )
                            }
                        </li>
                    ))}
                </>
            </Stack>
        </>
    )
}