import { useRef } from "react";
import { Path, Layer, Group } from "react-konva";

import { InventoryInfo } from "./InventoryInfo";

export default function Sections({
  data,
  setData,
  shouldSelectSeat,
  setIsGASection,
  setSeats,
  activeMapAction,
  activeTab,
  event,
  openOffer,
  inventory,
  darkenColor,
}) {
  const getSectionFill = (section) => {
    if (activeMapAction === 4 && activeTab === "scaling") {
      return "rgb(255, 0, 23)";
    }
    if (section?.selected) {
      return "rgba(20, 20, 22, 1)";
    }
    if (activeTab === "scaling") {
      return section?.PLFill || section?.fill; // 'rgba(177, 181, 195, 1)'
    }
    return section?.fill; // section?.inventoryFill || openOffer?.color
  };

  const handleSectionAction = (e, sectionId, hovering) => {
    if (activeMapAction === 4) return;

    const container = e.target.getStage().container();
    container.style.cursor = hovering ? "pointer" : "default";

    let updatedData = { ...data };
    applyFilterToSection(
      sectionId,
      hovering,
      updatedData.rows,
      updatedData.seats
    );

    setData(updatedData);
  };

  const applyFilterToSection = (sectionId, hovering, rows, seats) => {
    let allAssigned = true;
    const section = data.sections[sectionId];

    // Get targetRows and check if section is allAssigned
    const targetRows = section.rows.map((rowId) => {
      const row = rows[rowId];

      let assignedTarget = row.allAssigned;
      if (activeTab === "inventory") {
        assignedTarget = row.inventoryAllAssigned;
      }

      if (!assignedTarget) allAssigned = false;

      return row;
    });

    // Apply allAssigned
    if (activeTab === "inventory") {
      section.inventoryAllAssigned = allAssigned;
    } else {
      section.allAssigned = allAssigned;
    }

    targetRows.forEach((row) => {
      row.seats.forEach((seatId) => {
        const seat = seats[seatId];
        if (seat && shouldSelectSeat(seat, allAssigned)) {
          seat.hovering = hovering;
        }
      });
    });

    // Apply filter to non-zoomable section
    if (targetRows.length === 0) {
      section.hovering = hovering;
    }
  };

  const handleSectionSelect = (section) => {
    if (activeMapAction === 4) return;

    let updatedData = { ...data };
    let seatIds = [];

    // Handle GA and seated differently
    if (section?.zoomable) {
      // seated
      seatIds = getSeatIdsForZoomableSection(
        section,
        updatedData.rows,
        updatedData.seats
      );
    } else {
      // ga section
      setIsGASection(true);
      seatIds = getSeatIdsForNonZoomableSection(section, updatedData.sections);
    }

    // Not all seats are selected, so select them all
    setSeats(undefined, seatIds);
    setData(updatedData);
  };

  const getSeatIdsForZoomableSection = (section, rows, seats) => {
    let assignedTarget = section.allAssigned;
    if (activeTab === "inventory") {
      assignedTarget = section.inventoryAllAssigned;
    }

    return Object.values(rows)
      .filter((row) => row.sectionId === section.sectionId)
      .flatMap((row) => row.seats)
      .filter((seatId) => {
        if (!seats[seatId]) return false;
        if (shouldSelectSeat(seats[seatId], assignedTarget)) {
          seats[seatId].selected = true;
          return true;
        }
        return false;
      });
  };

  const getSeatIdsForNonZoomableSection = (section, sections) => {
    sections[section.sectionId].selected = true;
    // get all ga seats
    return sections[section.sectionId].spots;
  };

  return (
    <Layer>
      {data?.sections &&
        Object.values(data.sections).map((section) => {
          return section?.zoomable ? (
            <Path
              key={section.sectionId}
              data={section.path}
              id={section.sectionId}
              onClick={() => handleSectionSelect(section)}
              onMouseOver={(e) =>
                handleSectionAction(e, section.sectionId, true)
              }
              onMouseOut={(e) =>
                handleSectionAction(e, section.sectionId, false)
              }
              opacity={0}
              pointerEvents={"none"}
              listening={activeMapAction === 1}
            ></Path>
          ) : (
            <GASection
              key={section?.sectionId}
              section={section}
              getSectionFill={getSectionFill}
              handleSectionAction={handleSectionAction}
              handleSectionSelect={handleSectionSelect}
              activeMapAction={activeMapAction}
              activeTab={activeTab}
              event={event}
              openOffer={openOffer}
              inventory={inventory}
              darkenColor={darkenColor}
            />
          );
        })}
    </Layer>
  );
}

function GASection({
  getSectionFill,
  section,
  handleSectionAction,
  handleSectionSelect,
  activeMapAction,
  activeTab,
  event,
  openOffer,
  inventory,
  darkenColor,
}) {
  const visualRef = useRef();

  return (
    <Group>
      {/* Section */}
      <Path
        fill={
          section?.hovering
            ? darkenColor(getSectionFill(section), 1)
            : getSectionFill(section)
        }
        data={section.path}
        className={`path`}
        onClick={() => handleSectionSelect(section)}
        onMouseOver={(e) => handleSectionAction(e, section.sectionId, true)}
        onMouseOut={(e) => handleSectionAction(e, section.sectionId, false)}
        listening={activeMapAction !== 4}
        id={section.sectionId}
      ></Path>
      {/* Visuals, probably text */}
      <Path
        ref={visualRef}
        className='path_non_visual'
        data={section?.identifier?.path}
      ></Path>
      <InventoryInfo
        visualRef={visualRef}
        section={section}
        activeTab={activeTab}
        event={event}
        openOffer={openOffer}
        inventory={inventory}
      />
    </Group>
  );
}
