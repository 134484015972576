import React from 'react'

// reusable item name label for offers/holds/kills/sold/price level, moveSeats popup, ticket breakdown, pricing tables -> create offer
export default function ItemName({ el, isSubItem = false, size, weight }) {

    const { name, color } = el;

    return (
        <div className={`flex flex-sm ${isSubItem ? 'sub-item-name-container' : ''}`}>
            <div className={`dot ${isSubItem ? 'dot-sm' : ''}`} style={{ backgroundColor: color }}></div>
            <span className={`item-name ${size ? `item-name-${size}` : ''} ${weight ? `item-name-${weight}` : ''}`}>{name}</span>
        </div>
    )
}