import React from 'react';

import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import { RequiredText } from '../../../../RequiredText';
import { InfoIcon } from '../../../../InfoIcon';

export default function Details({ isStandard, canEdit, offer, requiredFieldErrorStatus, handleChange }) {

    return (
        <>
            <div className="card-body-heading card-body-heading--xs card-body-heading--flex">
                <Card.Title as="h5">Details</Card.Title>
                {(requiredFieldErrorStatus?.name) && (<RequiredText />)}
            </div>
            <Form.Group className="form-group" controlId="name">
                <Form.Label>Name</Form.Label>
                <Form.Control
                    name="name"
                    placeholder="Name of offer"
                    value={offer?.name}
                    onChange={handleChange}
                    disabled={isStandard || !canEdit}
                    className={`${requiredFieldErrorStatus?.name ? 'error-border' : ''}`}
                    required
                    autoFocus
                />
            </Form.Group>
            <Form.Group className="form-group" controlId="description">
                <div className="form-label--flex">
                    <Form.Label>Description</Form.Label>
                    <OverlayTrigger
                        placement="right"
                        overlay={<Tooltip>Add information about this offer</Tooltip>}>
                        <Button variant="link">
                            <InfoIcon />
                        </Button>
                    </OverlayTrigger>
                </div>
                <Form.Control
                    as="textarea"
                    rows={5}
                    name="description"
                    placeholder="Add description for this offer"
                    disabled={!canEdit}
                    value={offer?.description}
                    onChange={handleChange}
                />
            </Form.Group>
        </>
    );
}
