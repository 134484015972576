import React from 'react';

import Form from 'react-bootstrap/Form';

export default function ExactLimit({ limits, inputRef, canEdit = true, hasError, handleValid, handleChange }) {

    return (
        <Form.Group className="form-group" controlId="limit">
            <div className='form-label--flex mb-0'>
                <Form.Label className='form-label-sm form-label--lowercase'>Amount</Form.Label>
                <Form.Control
                    ref={inputRef}
                    size="sm"
                    name="limit"
                    pattern="^[0-9]*$"
                    className={`w-10 ${hasError ? 'error-border' : ''}`}
                    disabled={!canEdit}
                    value={limits?.limit}
                    onChange={(e) => handleChange(e.target.validity.valid || e.target.value === '' ? e : limits.limit)}
                    onBlur={handleValid}
                />
            </div>
        </Form.Group>
    );
}
