import React from 'react';

import { EventDetailsWrapper } from '../../components';

export default function CreateEventPage() {
    return (
        <div className="spacer-md" id="main-content">
            <EventDetailsWrapper />
        </div>
    );
}
