import React, { useState, useEffect, useRef } from 'react';

import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

import { RequiredText } from '../../RequiredText';

export default function ResalePricePoints({ hasMinimumResale, setHasMinimumResale, minimumResaleAmount, setMinimumResaleAmount, isEditable, errors, requiredFieldErrorStatus, handleValid, findError }) {

    const inputRef = useRef(null)

    const [error, setError] = useState()

    const [isMinimumChecked, setIsMinimumChecked] = useState(false)

    // focus input field only if minimum resale checkbox is enabled again after disabling
    useEffect(() => {
        if (isMinimumChecked) {
            if (inputRef.current) {
                inputRef.current.focus()
            }
        }
    }, [isMinimumChecked])

    useEffect(() => {
        setError(findError('minimumResale'))
    }, [errors])

    const handleHasMinimumResale = (val) => {
        let amount;
        setHasMinimumResale(val)
        setIsMinimumChecked(val)
        if (val) amount = '30'
        else amount = ''
        setMinimumResaleAmount(amount)
    }

    return (
        <>
            <div className="card-body-heading card-body-heading--flex">
                <Card.Title as="h5">Resale price points</Card.Title>
                {(error || requiredFieldErrorStatus?.resale) && (<RequiredText />)}
            </div>
            <div className='form-check--with-desc'>
                <Form.Check
                    name="minimumResale"
                    id="disable-minimum"
                    type='radio'
                    label="Disable minimum"
                    disabled={isEditable}
                    checked={!hasMinimumResale && hasMinimumResale != null}
                    onChange={(e) => handleHasMinimumResale(!e.target.checked)}
                    className={`${hasMinimumResale === null && requiredFieldErrorStatus?.resale ? 'form-check-error' : ''}`}
                />
                <small className='subtitle'>
                    Tickets can be resold for below the face value at any price
                </small>
            </div>
            <div className="form-check--with-desc">
                <Form.Check
                    name="minimumResale"
                    id="custom-minimum"
                    type='radio'
                    label="Custom minimum"
                    disabled={isEditable}
                    checked={hasMinimumResale}
                    onChange={(e) => handleHasMinimumResale(e.target.checked)}
                    className={`${error || requiredFieldErrorStatus?.resale ? 'form-check-error' : ''}`}
                />
                <small className='subtitle'>
                    Set the resale markup percentage, with a minimum of 30% above face value
                </small>
            </div>
            {hasMinimumResale && (
                <Form.Group className='form-group' controlId='minimumResale'>
                    <Form.Label>Minimum</Form.Label>
                    <InputGroup className={`w-25 ${error || requiredFieldErrorStatus.resale ? 'input-group-error' : ''}`}>
                        <InputGroup.Text id="minimum">%</InputGroup.Text>
                        <Form.Control
                            ref={inputRef}
                            placeholder="Percentage"
                            name="minimumResale"
                            aria-describedby="minimum"
                            pattern="^[0-9.]*$"
                            disabled={isEditable}
                            value={minimumResaleAmount}
                            onChange={(e) => setMinimumResaleAmount(e.target.value === '' || e.target.validity.valid ? e.target.value : minimumResaleAmount)}
                            onBlur={handleValid}
                            className={`w-25 ${error || requiredFieldErrorStatus?.resale ? 'error-border' : ''}`}
                            required
                        />
                    </InputGroup>
                    {error && (
                        <Form.Text className='error'>{error?.message}</Form.Text>
                    )}
                </Form.Group>
            )}
        </>
    );
}
