import React from 'react';
import * as moment from 'moment';

import { formatDateTime } from '../../utilities/helpers';

import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Stack from 'react-bootstrap/Stack';

import { DateInputWrapper } from '../DateInputWrapper';
import { TimeInputWrapper } from '../TimeInputWrapper';

export default function GeneralOnsale({ generalOnsale, setGeneralOnsale, generalOnsaleEnd, eventVisibility, eventStart, error, errorMsg }) {

    return (
        <>
            <div className="modal-body-heading">
                <h1 className='modal-body-heading-title'>Edit General On-sale</h1>
            </div>
            <Form>
                <fieldset className='form-group'>
                    <legend className='sm'>Start date and time</legend>
                    <Row>
                        <Col>
                            <DateInputWrapper
                                id="general-onsale-start"
                                setDate={setGeneralOnsale}
                                selectedDate={generalOnsale}
                                startDate={generalOnsale}
                                minDate={eventVisibility}
                                maxDate={eventStart}
                                size="sm"
                            />
                        </Col>
                        <Col>
                            <TimeInputWrapper
                                id="general-onsale-start-time"
                                setDate={setGeneralOnsale}
                                selectedDate={generalOnsale}
                                size="sm"
                                error={error}
                                errorMsg={errorMsg}
                            />
                        </Col>
                    </Row>
                </fieldset>
            </Form>
            <Stack className='mt-4' gap={1}>
                <small>Sale ends on:</small>
                <span className='normal normal-bold'>
                    <span>{formatDateTime(moment(generalOnsaleEnd), 'dateOnly')}</span>
                    <span className='time'>{formatDateTime(moment(generalOnsaleEnd), 'timeOnly')}</span>
                </span>
                <small className='caption text-muted'>4 Hour(s) after the event start time</small>
            </Stack>
        </>
    );
}
