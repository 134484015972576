import { useEffect, useState } from "react";
import { Rect, Group, Label, Tag, Text } from "react-konva";

export default function InventoryInfo({ visualRef, activeTab, section, event, openOffer, inventory }) {

    const [bbox, setBbox] = useState();
    // const [tooltipVisible, setTooltipVisible] = useState(false);
    // const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
    // const [tooltipText, setTooltipText] = useState('Hello dude');

    const [percentages, setPercentages] = useState({
        open: 1,
        exclusive: 0,
        holds: 0,
        kills: 0,
        sold: 0
    })

    // const handleMouseOver = (e) => {
    //     const { x, y } = e.target.getStage().getRelativePointerPosition();
    //     setTooltipPosition({ x, y });
    //     setTooltipText(`Open: ${(percentages.open * 100).toFixed(0)}% Exclusive: ${(percentages.exclusive * 100).toFixed(0)}% Holds: ${(percentages.holds * 100).toFixed(0)}% Kills: ${(percentages.kills * 100).toFixed(0)}% ${event?.status === 'on_sale' ? `Sold: ${(percentages.sold * 100).toFixed(0)}%` : ''} `);
    //     setTooltipVisible(true);
    // }

    // const handleMouseOut = () => {
    //     setTooltipVisible(false);
    // }

    useEffect(() => {
        const element = visualRef?.current;
        if (element) {
            let newBbox = element.getClientRect({ skipTransform: true });
            setBbox(newBbox);
        }
    }, [section])

    useEffect(() => {
        const capacity = event?.room?.capacity;
        if (!capacity) return;

        // Helper
        const countGASeats = (seatArray) => seatArray.filter((id) => id.startsWith('ga')).length;

        // Find amount of seats in each
        const GAInHoldsCount = Object.values(inventory.holds).reduce((acc, hold) => acc + countGASeats(hold.seats), 0);
        const GAInKillsCount = Object.values(inventory.kills).reduce((acc, kill) => acc + countGASeats(kill.seats), 0);
        const GAInExclusiveCount = Object.values(inventory.exclusiveOffers).reduce((acc, offer) => acc + countGASeats(offer.seats), 0);
        const GAInSoldCount = Object.values(inventory.sold).reduce((acc, ticket) => acc + countGASeats(ticket.seats), 0);
        let GAInOpenCount = capacity - GAInHoldsCount - GAInKillsCount - GAInExclusiveCount - GAInSoldCount;
        GAInOpenCount = GAInOpenCount > 0 ? GAInOpenCount : 0

        // Calculate percentages
        const holdsPercentage = GAInHoldsCount / capacity;
        const killsPercentage = GAInKillsCount / capacity;
        const openPercentage = GAInOpenCount / capacity;
        const exclusivePercentage = GAInExclusiveCount / capacity;
        const soldPercentage = GAInSoldCount / capacity;

        setPercentages({ open: openPercentage, exclusive: exclusivePercentage, holds: holdsPercentage, kills: killsPercentage, sold: soldPercentage })
    }, [inventory])

    // const handleMouseOver = (e) => {
    //     const { x, y } = e.target.getStage().getRelativePointerPosition();
    //     setTooltipPosition({ x, y });
    //     setTooltipText(`Open: ${(percentages.open * 100).toFixed(0)}% Exclusive: ${(percentages.exclusive * 100).toFixed(0)}% Holds: ${(percentages.holds * 100).toFixed(0)}% Kills: ${(percentages.kills * 100).toFixed(0)}% ${event?.status === 'on_sale' ? `Sold: ${(percentages.sold * 100).toFixed(0)}%` : ''} `);
    //     setTooltipVisible(true);
    // }

    // const handleMouseOut = () => {
    //     setTooltipVisible(false);
    // }

    if (!(activeTab === 'inventory' && bbox)) {
        return null;
    }

    const getSpacingMultiplier = (obj) => {
        const values = Object.values(obj);
        const nonZeroValues = values.filter(value => value > 0);
        return nonZeroValues.length - 1;
    };

    const padding = 30;
    const spacing = 10;
    const spacingMultiplier = getSpacingMultiplier(percentages);

    const usableWidth = bbox.width - 2 * padding - (spacingMultiplier * spacing);
    const startX = bbox.x + padding;
    const startY = bbox.y + (bbox.height / 2) - 50 + padding;

    const getXPosition = (categories, currentCategory, percentages, startX, usableWidth, spacing) => {
        let xPosition = startX;
        let previousNonZeroCategoryFound = false; // Flag to track if we've encountered a non-zero category

        for (const category of categories) {
            if (category === currentCategory) {
                if (previousNonZeroCategoryFound) {
                    // Add spacing only if there was at least one non-zero category before the current one
                    xPosition += spacing;
                }
                break; // Stop processing once we reach the current category
            }
            if (percentages[category] > 0) {
                if (previousNonZeroCategoryFound) {
                    // Add spacing for previous non-zero category
                    xPosition += spacing;
                }
                xPosition += usableWidth * percentages[category]; // Add width for non-zero category
                previousNonZeroCategoryFound = true; // Mark that we've found a non-zero category
            }
        }

        return xPosition;
    };
    
    const categoriesOrder = ['open', 'exclusive', 'holds', 'kills', 'sold'];

    const openX = getXPosition(categoriesOrder, 'open', percentages, startX, usableWidth, spacing);
    const exclusiveX = getXPosition(categoriesOrder, 'exclusive', percentages, startX, usableWidth, spacing);
    const holdsX = getXPosition(categoriesOrder, 'holds', percentages, startX, usableWidth, spacing);
    const killsX = getXPosition(categoriesOrder, 'kills', percentages, startX, usableWidth, spacing);
    const soldX = getXPosition(categoriesOrder, 'sold', percentages, startX, usableWidth, spacing);


    return (
        <Group>
            <Rect
                x={bbox.x}
                y={bbox.y + (bbox.height / 2) - 50}
                width={bbox.width}
                height={150}
                fill="white"
                cornerRadius={20}
            />
            {/* Open Rectangle */}
            <Rect
                x={openX}
                y={startY}
                width={usableWidth * percentages.open}
                height={90}
                fill={openOffer?.color}
                cornerRadius={20}
            />
            {/* Exclusive Rectangle */}
            <Rect
                x={exclusiveX}
                y={startY}
                width={usableWidth * percentages.exclusive}
                height={90}
                fill="brown"
                cornerRadius={20}
            />
            {/* Holds Rectangle */}
            <Rect
                x={holdsX}
                y={startY}
                width={usableWidth * percentages.holds}
                height={90}
                fill={inventory.holdsColor}
                cornerRadius={20}
            />
            {/* Kills Rectangle */}
            <Rect
                x={killsX}
                y={startY}
                width={usableWidth * percentages.kills}
                height={90}
                fill={inventory.killsColor}
                cornerRadius={20}
            />
            {/* Sold Rectangle */}
            <Rect
                x={soldX}
                y={startY}
                width={usableWidth * percentages.sold}
                height={90}
                fill={inventory.soldColor}
                cornerRadius={20}
            />
            {/* <Label x={tooltipPosition.x} y={tooltipPosition.y}  opacity={0.75} pointerDirection="down" pointerWidth={10} pointerHeight={10} visible={tooltipVisible}>
                <Tag 
                    fill="black"
                    pointerDirection="down"
                    pointerHeight={10 / 0.09}
                    pointerWidth={10 / 0.09}
                    cornerRadius={20}
                    lineJoin="round"
                    shadowColor="black"
                />
                <Text text={tooltipText} padding={5 / 0.09} fontSize={14 / 0.09} fill="white" />
            </Label> */}
        </Group>
    )
}