import React from 'react';

import Modal from 'react-bootstrap/Modal';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';
import { Spinner } from '../../../LoadingContainer/Spinner';

export default function ConfirmTemplateModal({ show, step, name, isSaving, handleClose, handleSubmit }) {

    const isFirstStep = step === 1

    return (
        <Modal centered animation={false} fullscreen="md-down" show={show} onHide={handleClose} backdrop="static" className='modal--lg'>
            <Modal.Body>
                <div className="modal-body-heading">
                    <h1 className="modal-body-heading-title">{isFirstStep ? 'Proceed with template?' : 'Template applied!'}</h1>
                    {isFirstStep ? (
                        <p>All the configurations you’ve previously made will be overridden by this template. This cannot be undone, however you can still modify on the applied template.</p>)
                        : (
                            <p>Selected template "<span className='fw-bold'>{name}</span>" is now applied to your seat map.</p>
                        )}
                </div>
                <Stack className="btn-group-flex">
                    <Button variant={`${isFirstStep ? "outline-light" : 'primary'} `} size="lg" onClick={handleClose}>
                        {isFirstStep ? 'Cancel' : 'Close'}
                    </Button>
                    {isFirstStep && (
                        <Button size="lg" onClick={handleSubmit}>{isSaving ? (
                            <Spinner />
                        ) :
                            'Continue with template'}
                        </Button>
                    )}
                </Stack>
            </Modal.Body>
        </Modal>
    );
}
