import React from 'react';

import { Limit } from '../Limit';

export default function MultipleOfQuantityLimit({ limits, canEdit = true, handleChange, hasError, handleValid }) {

    return (
        <Limit
            id="multipleOf"
            inputName="multipleOfQuantity"
            switchName="hasMultipleOfQuantity"
            label="Multiple Of"
            limits={limits}
            canEdit={canEdit}
            hasError={hasError}
            handleChange={handleChange}
            handleValid={handleValid}
        />
    );
}
