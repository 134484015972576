import React from 'react';

import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

import { RequiredText } from '../../RequiredText';

export default function Fees({ event, handleChange, isEditable, requiredFieldErrorStatus }) {

    return (
        <>
            <div className="card-body-heading card-body-heading--flex">
                <Card.Title as="h5">Facility fees</Card.Title>
                {requiredFieldErrorStatus.fees && (<RequiredText />)}
            </div>
            <fieldset className='form-group'>
                <legend className='lg'>
                    <p className='small'>Default value</p>
                    <small className='subtitle'>This value will be applied to all offers using the default facility fee option</small>
                </legend>
                <InputGroup className={`w-25 ${requiredFieldErrorStatus.fees ? 'input-group-error' : ''}`}>
                    <InputGroup.Text id="facilityFee">$</InputGroup.Text>
                    <Form.Control
                        placeholder="Amount"
                        id="facilityFee"
                        name="facilityFees"
                        aria-describedby="facilityFee"
                        pattern="^[0-9.]*$"
                        disabled={isEditable}
                        value={event?.facilityFees || ''}
                        onChange={(e) => handleChange(e.target.value === '' || e.target.validity.valid ? e : event.facilityFees)}
                        required
                    />
                </InputGroup>
            </fieldset>
        </>
    );
}
