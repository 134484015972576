import React from 'react';

import { formatNumber } from '../../../../../../../utilities/helpers';

import Form from 'react-bootstrap/Form';
import Stack from 'react-bootstrap/Stack';

export default function Item({ obj, index, numSeats, numSeatsInitialState, hasError, autoFocused = false, handleChange }) {

    return (
        <div className='split-row'>
            <div className="flex flex-sm">
                <div className="dot" style={{ backgroundColor: obj?.color }}></div>
                <span className="item-name">{obj?.name}</span>
            </div>
            <Stack direction='horizontal' gap={1} className='justify-content-end'>
                <Form.Control
                    size="sm"
                    className={`w-25 ${hasError ? 'error-border' : ''}`}
                    name="seats"
                    pattern="^[0-9]*$"
                    value={numSeats}
                    onChange={(e) => e.target.validity.valid || e.target.value === '' ? handleChange(e, index) : numSeats}
                    autoFocus={autoFocused}
                />
                <span>/</span><span className='num-seats'>{formatNumber(numSeatsInitialState)}</span>
            </Stack>
        </div>
    );
}
