import React from 'react';

import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';

import { Spinner } from '../../../LoadingContainer/Spinner';

export default function CreateRoleModal({ show, handleClose, permissions, id, role, setRole, handleValid, isValid, isCheck, checker, handleSelectAll, handleCheck, handleCreate, isCreating, isDisabled }) {

    if (permissions?.length === 0) return (<div />);

    return (
        <Modal className='modal-sm' id="create-role" scrollable centered animation={false} backdrop="static" fullscreen="md-down" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title as="h4">{id ? 'Edit' : 'New'} role</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleCreate}>
                    <Form.Group className="mb-4" controlId="name">
                        <Form.Label>Role name</Form.Label>
                        <Form.Control
                            type="text"
                            name="name"
                            placeholder='Enter name'
                            required
                            value={role?.name || ''}
                            onBlur={handleValid}
                            onChange={(e) => setRole({ ...role, [e.target.name]: e.target.value })}
                            className={!isValid ? 'error-border' : ''}
                            autoFocus
                        />
                        {!isValid && (
                            <Form.Text className='error'>Role name cannot be {role.name}.</Form.Text>
                        )}
                    </Form.Group>
                    <div className="mb-2">
                        <h2 className="fs-md">Permissions</h2>
                        <p className='subtitle mb-4'>Grant your team members specific permissions to delegate tasks</p>
                        <Form.Check
                            label="Select all"
                            type="checkbox"
                            checked={checker([...permissions['settings'], ...permissions['management']])}
                            id="all"
                            onChange={handleSelectAll}
                        />
                    </div>
                    <ul>
                        <li className='list'>
                            <p className='heading'>Settings</p>
                            <ul>
                                <li>
                                    <Form.Check
                                        label="Select all"
                                        type="checkbox"
                                        checked={checker(permissions['settings'])}
                                        id="s-0"
                                        name="settings"
                                        onChange={handleSelectAll}
                                    />
                                </li>
                                {Object.values(permissions?.settings).map(({ id, name }) => (
                                    <li key={id}>
                                        <Form.Check
                                            type="checkbox"
                                            label={name}
                                            id={id}
                                            onChange={handleCheck}
                                            checked={isCheck.includes(id)}
                                        />
                                    </li>
                                ))}
                            </ul>
                        </li>
                        <li className='list'>
                            <p className='heading'>Event management</p>
                            <ul>
                                <li>
                                    <Form.Check
                                        label="Select all"
                                        type="checkbox"
                                        id="m-0"
                                        checked={checker(permissions['management'])}
                                        name="management"
                                        onChange={handleSelectAll}
                                    />
                                </li>
                                {Object.values(permissions?.management).map(({ id, name }) => (
                                    <li key={id}>
                                        <Form.Check
                                            type="checkbox"
                                            label={name}
                                            id={id}
                                            onChange={handleCheck}
                                            checked={isCheck.includes(id)}
                                        />
                                    </li>
                                ))}
                            </ul>
                        </li>
                    </ul>
                </Form>
            </Modal.Body>
            <Stack className="btn-group-flex">
                <Button
                    size="lg"
                    disabled={isDisabled}
                    onClick={handleCreate}
                >
                    {isCreating ? (
                        <Spinner />
                    ) :
                        `${id ? 'Update' : 'Save and create'} role`
                    }
                </Button>
            </Stack>
        </Modal>

    );
}
