import React from 'react';

import Button from 'react-bootstrap/Button';
import { MapConfigurationCard } from '../MapConfigurationCard';

export default function ExistingTemplateCard({ handleClick }) {

    return (
        <>
            <MapConfigurationCard type="existing" title="Use an existing template" subtitle="Based on previous preferences">
                <Button onClick={(e) => handleClick(e, 2)}>Use template</Button>
            </MapConfigurationCard>
        </>
    );
}
