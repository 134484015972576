import React from 'react';

export default function SeatIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="44" height="46" viewBox="0 0 44 46" fill="none">
            <rect x="0.5" y="0.5" width="43" height="44.7455" rx="7.5" fill="#3EA9F7" fillOpacity="0.1" />
            <path d="M13.0545 18.7273C13.0545 13.9073 16.9619 10 21.7818 10H22.2182C27.0381 10 30.9455 13.9073 30.9455 18.7273V26.5818C30.9455 27.5458 30.164 28.3273 29.2 28.3273H14.8C13.836 28.3273 13.0545 27.5458 13.0545 26.5818V18.7273Z" fill="#3E8BF7" />
            <path d="M10 25.0545C10 24.4521 10.4884 23.9636 11.0909 23.9636C11.6934 23.9636 12.1818 24.4521 12.1818 25.0545V33.3455C12.1818 33.9479 11.6934 34.4364 11.0909 34.4364C10.4884 34.4364 10 33.9479 10 33.3455V25.0545Z" fill="#3E8BF7" />
            <path d="M31.8182 25.0545C31.8182 24.4521 32.3066 23.9636 32.9091 23.9636C33.5116 23.9636 34 24.4521 34 25.0545V33.3455C34 33.9479 33.5116 34.4364 32.9091 34.4364C32.3066 34.4364 31.8182 33.9479 31.8182 33.3455V25.0545Z" fill="#3E8BF7" />
            <path d="M13.0545 34.2494C13.0545 32.1837 14.7291 30.5091 16.7948 30.5091H27.2052C29.2709 30.5091 30.9455 32.1837 30.9455 34.2494C30.9455 35.0756 30.2756 35.7455 29.4494 35.7455H14.5506C13.7244 35.7455 13.0545 35.0756 13.0545 34.2494Z" fill="#3E8BF7" />
            <rect x="0.5" y="0.5" width="43" height="44.7455" rx="7.5" stroke="#3EA9F7" />
        </svg>
    );
}
