import React from 'react';

import Stack from 'react-bootstrap/Stack';
import Form from 'react-bootstrap/Form';

export default function Limit({ id, inputName, switchName, label, limits, canEdit, hasError, handleChange, handleValid }) {

    return (
        <Stack direction="horizontal" as="li" className='flex-sm' style={{ flexBasis: '28px' }}>
            {canEdit && (
                <Form.Check
                    type="switch"
                    id={`toggle-${id}-switch`}
                    name={switchName}
                    label={label}
                    checked={limits[switchName]}
                    onChange={(e) => handleChange(e, e.target.checked)}
                />
            )}
            {!canEdit && (
                <Form.Label className='form-label-sm form-label--lowercase'>{label}</Form.Label>
            )}
            {limits[switchName] && (
                <Form.Control
                    size="sm"
                    aria-labelledby='toggle-minimum-switch'
                    pattern="^[0-9]*$"
                    className={`w-10 ${hasError ? 'error-border' : ''}`}
                    disabled={!canEdit}
                    name={inputName}
                    onBlur={handleValid}
                    value={limits[inputName]}
                    onChange={(e) => handleChange(e.target.validity.valid || e.target.value === '' ? e : limits[inputName])}
                    autoFocus
                />
            )}
        </Stack>
    );
}
