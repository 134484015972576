import React, { useEffect, useState } from 'react';
import moment from 'moment'
import { isEqual } from 'lodash'

import { formatDateTime, getTimezoneDate, getFormattedTimezoneDate, getIsTimeAfterEventStartErrorMsg, getIsTimeBeforeEventVisibilityErrorMsg, isTimeAfterEventStart, isTimeBeforeEventVisibility } from "./../../../../../utilities/helpers";

import { assignAvailabilityPeriod } from '../../../../../utilities/api';

import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

import { Period } from './Period';
import { CreatePeriodModal } from './CreatePeriodModal';
import { GeneralOnsaleModal } from '../../../../GeneralOnsaleModal';
import { DeleteModal } from '../../../../DeleteModal';

export default function Availability({ event, offer, setOffer, selected, setSelected, isEditing, canEdit }) {

    const [show, setShow] = useState(false)

    const [showGeneralOnsale, setShowGeneralOnsale] = useState(false)

    const [showDelete, setShowDelete] = useState(false)

    const [showPeriods, setShowPeriods] = useState(false)

    const [periods, setPeriods] = useState([])

    // period id used for editing and deleting 
    const [id, setId] = useState()

    const [period, setPeriod] = useState()

    const [initialState, setInitialState] = useState()

    const [name, setName] = useState('');

    const [hasGeneralOnsalePeriod, setHasGeneralOnsalePeriod] = useState(false)

    const [generalOnsale, setGeneralOnsale] = useState(null);

    const [start, setStart] = useState(null)

    const [end, setEnd] = useState(null)

    const [generalOnsaleError, setGeneralOnsaleError] = useState(false)

    const [startError, setStartError] = useState(false)

    const [endError, setEndError] = useState(false)

    const [errorMsg, setErrorMsg] = useState('')

    const [endErrorMsg, setEndErrorMsg] = useState('')

    const [isSaving, setIsSaving] = useState(false)

    useEffect(() => {
        setInitialState({ name, start, end })
    }, [period])

    useEffect(() => {
        setPeriods(offer?.availabilityPeriods)
    }, [offer])

    useEffect(() => {
        if (isEditing) {
            // set only active period
            setPeriod(periods?.find(period => period.active))
        }
    }, [periods])

    // validation for general onsale date
    // - cannot be after event start time 
    // - cannot be before event visibility
    useEffect(() => {
        setGeneralOnsaleError(isTimeAfterEventStart(generalOnsale, event) || isTimeBeforeEventVisibility(generalOnsale, event))
        setErrorMsg(getErrorMsg())
    }, [generalOnsale, event])

    // validation for start date
    // - cannot be after event start time 
    // - cannot be before event visibility
    // validation for end date
    // - cannot be after event end time
    // - cannot be before/same as start time 
    useEffect(() => {
        setStartError(isTimeAfterEventStart(start, event) || isTimeBeforeEventVisibility(start, event))
        setEndError(isEndAfterEventEnd(end) || moment(end).toDate().getTime() <= moment(start).toDate().getTime())
        setErrorMsg(getErrorMsg())
        setEndErrorMsg(getIsEndBeforeOrSameAsStartErrorMsg() || getIsEndAfterEventEndErrorMsg(end) || '')
    }, [start, end, event])

    useEffect(() => {

    }, [offer])

    // validation for start date/general onsale 
    // - cannot be before event visibility
    // - cannot be after event start time 
    const getErrorMsg = () => {
        if (isTimeBeforeEventVisibility(start, event) || isTimeBeforeEventVisibility(generalOnsale, event)) {
            return getIsTimeBeforeEventVisibilityErrorMsg(event)
        } else if (isTimeAfterEventStart(start, event) || isTimeAfterEventStart(generalOnsale, event)) {
            return getIsTimeAfterEventStartErrorMsg(event)
        }
        else return ''
    }

    // validation for end date
    // - cannot be before/same as start time 
    const getIsEndBeforeOrSameAsStartErrorMsg = () => {
        if (moment(end).toDate().getTime() <= moment(start).toDate().getTime()) {
            return 'Time must be after start time'
        }
    }

    // end date cannot be after event end time (4 hours after event start time)
    const isEndAfterEventEnd = (date) => {
        return moment(date)?.toDate().getTime() > moment(getFormattedTimezoneDate(event?.end, event?.timezone))?.toDate().getTime()
    }

    // validation for end date
    // - cannot be before/same as start time 
    const getIsEndAfterEventEndErrorMsg = () => {
        if (isEndAfterEventEnd(end)) {
            return `Time cannot be after event end time (${formatDateTime(getTimezoneDate(event?.end, event?.timezone), 'timeOnly')})`
        }
    }

    const handleShow = (_, period) => {
        if (period) {
            if (period?.name?.includes("General On-sale")) {
                setShowGeneralOnsale(true)
                setHasGeneralOnsalePeriod(true)
                setGeneralOnsale(new Date(getFormattedTimezoneDate(period?.starts, event?.timezone)))
            } else {
                setShow(true)
                setId(period?.id)
                setName(period?.name)
                setStart(new Date(getFormattedTimezoneDate(period?.starts, event?.timezone)))
                setEnd(new Date(getFormattedTimezoneDate(period?.ends, event?.timezone)))
            }
        } else setShow(true)
    }

    // create new period
    const handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (!name || !start || !end) return
        setIsSaving(true)
        let data = {
            name,
            starts: start,
            ends: end,
            offerUUID: offer?.uuid
        }
        assignAvailabilityPeriod(data)
            .then(res => {
                // add latest period created to offer's existing periods 
                setOffer({ ...offer, availabilityPeriods: [...offer.availabilityPeriods, ...res.data?.availability.slice(-1)] })
                let activePeriod = res?.data?.availability.find(period => period.active)
                setSelected(activePeriod.id)
                setIsSaving(false)

                if (!isSaving) handleClose()
            })
            .catch(err => {
                console.error(err)
                setIsSaving(false)
            })
    }

    // close create new period
    const handleClose = () => {
        setName(initialState.name)
        setStart(initialState.start)
        setEnd(initialState.end)
        setShow(false)
        setId()
    }

    // TODO: edit general onsale period 
    const handleSubmitGeneralOnsale = () => {

    }

    // close general onsale modal 
    const handleCloseGeneralOnsale = () => {
        setGeneralOnsale(null)
        setHasGeneralOnsalePeriod(false)
        setShowGeneralOnsale(false)
    }

    const handleShowDelete = (id) => {
        setId(id)
        setShowDelete(true)
    }

    const handleCloseDelete = () => {
        setId()
        setShowDelete(false)
    }

    // TODO: remove availability period
    const handleDelete = () => {

    }

    const getText = () => {

        if (canEdit) {
            if (isEditing) return 'Edit availability period'
            else return 'Select or create an availability period'
        }
        else return 'Availability period'
    }


    const checkIsDisabled = () => {
        return (!name || !start || !end || startError || endError || isEqual(start, new Date(getFormattedTimezoneDate(initialState?.start, event?.timezone))) || isEqual(end, new Date(getFormattedTimezoneDate(initialState?.end, event?.timezone))))
    }

    return (
        <>
            <div className="card-body-heading--sm card-body-heading--flex card-body-heading--flex-space-between">
                <div className='d-flex-column'>
                    <Card.Title as="h5" className='card-title-sm'>Availability</Card.Title>
                    <Card.Subtitle as="h6" className="subtitle--dark">{getText()}</Card.Subtitle>
                </div>
                {!isEditing && (
                    <Button
                        variant="outline-light"
                        className="btn-plus"
                        onClick={handleShow}
                    >
                        Create period
                    </Button>
                )}
            </div>
            {(isEditing && period) ? (
                <div className="offset-container-sm">
                    <Period period={period} timezone={event?.timezone} selected={period?.id} isEditing={isEditing} canEdit={canEdit} status={offer?.status} handleShow={handleShow} />
                </div>
            ) : (
                <ul className='offset-container-sm'>
                    {periods?.map((period, index) => (
                        <Period key={index} period={period} timezone={event?.timezone} selected={selected} setSelected={setSelected} isEditing={isEditing} canEdit={index === 0 || index > 3} canDelete={index > 3} handleShow={handleShow} handleShowDelete={handleShowDelete} />
                    ))}
                </ul>
            )}
            {hasGeneralOnsalePeriod ? (
                <GeneralOnsaleModal show={showGeneralOnsale} event={event} eventStart={new Date(getFormattedTimezoneDate(event?.start, event?.timezone))} eventVisibility={event?.eventVisibility ? new Date(getFormattedTimezoneDate(event?.eventVisibility, event?.timezone)) : undefined} generalOnsale={generalOnsale} setGeneralOnsale={setGeneralOnsale} generalOnsaleEnd={new Date(getFormattedTimezoneDate(event?.end, event?.timezone))} error={generalOnsaleError} errorMsg={errorMsg} isDisabled={isEqual(generalOnsale, new Date(getFormattedTimezoneDate(event?.generalOnsale, event?.timezone)))} isSaving={isSaving} handleSubmit={handleSubmit} handleClose={handleCloseGeneralOnsale} />
            ) : (
                <CreatePeriodModal show={show} id={id} eventStart={new Date(getFormattedTimezoneDate(event?.start, event?.timezone))} eventEnd={new Date(getFormattedTimezoneDate(event?.end, event?.timezone))} eventVisibility={event?.eventVisibility ? new Date(getFormattedTimezoneDate(event?.eventVisibility, event?.timezone)) : undefined} showPeriods={showPeriods} setShowPeriods={setShowPeriods} periods={periods} name={name} setName={setName} start={start} setStart={setStart} end={end} setEnd={setEnd} startError={startError} endError={endError} errorMsg={errorMsg} endErrorMsg={endErrorMsg} timezone={event?.timezone} isDisabled={checkIsDisabled()} isSaving={isSaving} handleSubmit={handleSubmit} handleClose={handleClose} />
            )}

            <DeleteModal show={showDelete} entity="availability period" handleDelete={handleDelete} handleClose={handleCloseDelete} />
        </>
    );
}