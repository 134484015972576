import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Card from 'react-bootstrap/Card';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';

import { EmptyContainer } from '../../../EmptyContainer'
import { Offer } from './Offer';

import './offers.scss';

export default function Offers({ isEventPublished, offers, timezone, eventInventory, openInventory, handleClick, getOfferPrice }) {

    const navigate = useNavigate();

    const [openOffers, setOpenOffers] = useState()

    const [exclusiveOffers, setExclusiveOffers] = useState()

    useEffect(() => {
        setOpenOffers(offers?.filter(offer => offer.inventoryType.toLowerCase() === "open"))
        setExclusiveOffers(offers?.filter(offer => offer.inventoryType === "exclusive"))
    }, [offers])

    return (
        <>
            {offers?.length > 0 ? (
                <Stack gap={4}>
                    {openOffers?.length > 0 && (
                        <Card body className='card--md card--light'>
                            <div className='card-body-heading card-body-heading--xs'>
                                <Card.Title as="h5" className='text-upper'>Open</Card.Title>
                            </div>
                            <Stack gap={2} as="ul">
                                <>
                                    {openOffers.map((offer, id) =>
                                        <Offer
                                            key={id}
                                            isEventPublished={isEventPublished}
                                            offer={offer}
                                            offers={offers}
                                            timezone={timezone}
                                            openInventory={openInventory}
                                            isStandard={offer?.name === 'Standard Admission'}
                                            handleClick={handleClick}
                                            getOfferPrice={getOfferPrice}
                                        />
                                    )}
                                </>
                            </Stack>
                        </Card>
                    )}
                    {exclusiveOffers?.length > 0 && (
                        <Card body className='card--md card--light'>
                            <div className='card-body-heading card-body-heading--xs'>
                                <Card.Title as="h5" className='text-upper'>Exclusive</Card.Title>
                            </div>
                            <Stack gap={2} as="ul">
                                <>
                                    {exclusiveOffers.map((offer, id) =>
                                        <Offer
                                            key={id}
                                            isEventPublished={isEventPublished}
                                            offer={offer}
                                            offers={offers}
                                            timezone={timezone}
                                            seats={eventInventory?.offers[offer?.id]?.seats}
                                            openInventory={openInventory}
                                            isStandard={offer?.name === 'Standard Admission'}
                                            handleClick={handleClick}
                                            getOfferPrice={getOfferPrice}
                                        />
                                    )}
                                </>
                            </Stack>
                        </Card>
                    )}
                </Stack>
            ) : (
                <Card body>
                    <EmptyContainer style="center lg">
                        <p>You must finish scaling your tickes in order to create an offer.</p>
                        <Button
                            onClick={() => navigate('../seatmap')}
                        >
                            Go to Seat Map
                        </Button>
                    </EmptyContainer>
                </Card>
            )
            }
        </>
    );
}
