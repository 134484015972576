import { useLayoutEffect, useState } from 'react'

import { generateRandomColor } from '../../../../utilities/helpers';

import { ActionsProvider } from './ActionsProvider/ActionsProvider';
import { useInventory } from '../../InventoryProvider/InventoryProvider';
import { Sidebar } from './Sidebar'
import { SeatMap } from './SeatMap'

import './mapConfigurations.scss';

export default function MapConfigurations({ data, setData, background, event, activeTab, priceLevels, setPriceLevels, unassignedSeats, setUnassignedSeats, offers, setOffers, totalSeats, totalGASeats, getSeatIds, assignSeatsTo, removeSeatsFrom, getDefaultObj, getOpenInventory }) {

    const { setHolds, setKills, holds, kills, holdsColor, killsColor, sold } = useInventory()

    // array to hold seat ids when selected on map
    const [selectedSeatIds, setSelectedSeatIds] = useState([])

    // flag is section selected is a GA section 
    const [isGASection, setIsGASection] = useState(false)

    const [showSelectGASeats, setShowSelectGASeats] = useState(false)

    // modify sidebar container when seats are selected
    useLayoutEffect(() => {
        const el = document.getElementById('map')
        if ((activeTab === 'scaling' && selectedSeatIds.length > 0) || (activeTab === 'inventory' && selectedSeatIds.length > 0 && !showSelectGASeats)) {
            el.classList.add('panel-open')
        }

        return () => {
            el.classList.remove('panel-open')
        }
    }, [selectedSeatIds, showSelectGASeats])

    // add new pricing level, hold, or kill object 
    const handleAdd = (type, id) => {

        const generateKey = (obj) => {
            const keys = Object.keys(obj)
            if (keys.length === 0) {
                return keys.length + 1
            } else {
                // Get the highest existing key and add 1 to get the next key - so keys don't get messed up after deleting
                return Math.max(...keys) + 1;
            }
        }

        const createHoldKill = (prevState, id, color, name) => {
            const index = generateKey(prevState)

            // adding sub category
            if (id) {
                const catIndex = generateKey(prevState[id]?.categories)
                return {
                    ...prevState,
                    [id]: {
                        ...prevState[id],
                        categories: {
                            ...prevState[id].categories,
                            [catIndex]: {
                                ...getDefaultObj(catIndex, `Category ${catIndex}`, color)
                            }
                        }
                    }
                }
            } else {
                return {
                    ...prevState,
                    [index]: {
                        ...getDefaultObj(index, `${index} ${name}`, color),
                        categories: {}
                    }
                }
            }
        }

        switch (type) {
            case "level":
                const index = generateKey(priceLevels)
                setPriceLevels(prevState => ({
                    ...prevState,
                    [index]: {
                        ...getDefaultObj(index, `P${index}`, generateRandomColor()),
                        price: 0
                    }
                }))
                break;

            case "hold":
                setHolds(prevState => (
                    createHoldKill(prevState, id, holdsColor, 'Hold')))
                break;

            case 'kill':
                setKills(prevState => (
                    createHoldKill(prevState, id, killsColor, 'Kill')))
                break;

            default:
                break;
        }
    }

    const deselectSeats = (seatIds) => {
        const updatedData = { ...data };
        getSeatIds(seatIds).forEach((seatId) => {
            // Get
            let seatOrSection = updatedData.seats[seatId] || updatedData.sections[seatId];
            if (!seatOrSection) return;

            // Deselect
            let updatedSeatOrSection = { ...seatOrSection, selected: false };

            // Place
            if (updatedData.seats[seatId]) {
                updatedData.seats[seatId] = updatedSeatOrSection;
            } else {
                updatedData.sections[seatId] = updatedSeatOrSection;
            }
        })
        setData(updatedData);
    }

    const selectUnassigned = () => {
        const updatedData = { ...data };
        const unassignedIds = [];
        // get all seats from unassigned seats 
        unassignedIds.push(...Object.values(unassignedSeats)[0].seats)

        // select all seats
        Object.values(updatedData.seats).forEach((seat) => {
            if (!seat.PLId && !seat.selected) {
                seat.selected = true;
            }
        });
        // Account for GA
        Object.values(updatedData.sections).forEach((section) => {
            if (!section?.zoomable && !section?.selected) {
                if (!section.PLId) {
                    section.selected = true;
                }
            }
        })
        setData(updatedData);
        setSeats(null, unassignedIds);
    }

    // save seats (seatIds) to display in selected panel 
    const setSeats = (seatId, seatIdArray) => {
        // select one seat 
        if (seatId) {
            if (!selectedSeatIds.includes(seatId)) setSelectedSeatIds([...selectedSeatIds, seatId]);
        }
        // select multiple (row or section)
        else if (seatIdArray) {
            // select seats that are not already selected 
            seatIdArray = seatIdArray.filter((id) => !selectedSeatIds.includes(id));
            // if on inventory tab - don't add ga seats to selectedSeatIds 
            if (activeTab === 'inventory') {
                seatIdArray = seatIdArray.filter(id => !totalGASeats.includes(id))
            }
            // Show in sidebar
            setSelectedSeatIds([...selectedSeatIds, ...seatIdArray])
        }
    }

    return (
        <>
            <Sidebar
                event={event}
                tab={activeTab}
                unassignedSeats={unassignedSeats}
                setUnassignedSeats={setUnassignedSeats}
                offers={offers}
                setOffers={setOffers}
                selectedSeats={selectedSeatIds}
                setSelectedSeats={setSelectedSeatIds}
                priceLevels={priceLevels}
                setPriceLevels={setPriceLevels}
                holds={holds}
                setHolds={setHolds}
                kills={kills}
                setKills={setKills}
                sold={sold}
                totalSeats={totalSeats}
                totalGASeats={totalGASeats}
                isGASection={isGASection}
                setIsGASection={setIsGASection}
                showSelectGASeats={showSelectGASeats}
                setShowSelectGASeats={setShowSelectGASeats}
                isEventOnsale={event?.status === 'on_sale'}
                assignSeatsTo={assignSeatsTo}
                removeSeatsFrom={removeSeatsFrom}
                deselectSeats={deselectSeats}
                selectUnassigned={selectUnassigned}
                getOpenInventory={getOpenInventory}
                handleAdd={handleAdd}
            />
            <ActionsProvider
                data={data}
                setData={setData}
                activeTab={activeTab}
            >
                <SeatMap
                    data={data}
                    setData={setData}
                    activeTab={activeTab}
                    setSeats={setSeats}
                    openOffer={Object.values(offers)[0]}
                    setIsGASection={setIsGASection}
                    event={event}
                    background={background}
                />
            </ActionsProvider>
        </>
    )
}