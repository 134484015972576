import React from 'react';

import { Limit } from '../Limit';

export default function MaxQuantityLimit({ limits, canEdit = true, hasError, handleChange, handleValid }) {

    return (
        <Limit
            id="maximum"
            inputName="maxQuantity"
            switchName="hasMaxQuantity"
            label="Maximum"
            limits={limits}
            canEdit={canEdit}
            hasError={hasError}
            handleChange={handleChange}
            handleValid={handleValid}
        />
    );
}
