import React from 'react';

import Button from 'react-bootstrap/Button';

import { MapConfigurationCard } from '../MapConfigurationCard';

export default function NewMapCard({ handleClick }) {

    return (
        <>
            <MapConfigurationCard type="new" title="Create a new map" subtitle="Configure the map from scratch">
                <Button onClick={(e) => handleClick(e, 1)}>Create map</Button>
            </MapConfigurationCard>
        </>
    );
}
