import React, { useEffect, useState } from 'react'

import { formatNumber } from '../../../../../../utilities/helpers'

import Button from 'react-bootstrap/Button'

import { AddButton } from '../AddButton'
import { PriceLevel } from './PriceLevel';
import { AddPriceModal } from './AddPriceModal'

export default function ScalingMenu({ unassignedSeats, selectUnassigned, levels, setLevels, offers, handleAdd, moveFromIds, sumRevenue, sumNewRevenues, handleRemove }) {

    const [level, setLevel] = useState()

    // used when editing 
    const [basePriceInitialState, setBasePriceInitialState] = useState()

    const [basePriceDiff, setBasePriceDiff] = useState(0)

    const [show, setShow] = useState(false)

    const [hasHover, setHasHover] = useState(false)

    useEffect(() => {
        // if current price level has base price, set difference 
        if (Object.values(levels).find(l => l.id === level?.id)?.price) setBasePriceDiff(level?.price - basePriceInitialState)
    }, [basePriceInitialState, levels, level?.price])

    const handleShow = (_, level) => {
        setShow(true)
        setLevel(level)
        setBasePriceInitialState(level.price)
    }

    const handleClose = () => {
        setShow(false)
        setLevel()
        setBasePriceInitialState()
        setBasePriceDiff(0)
    }

    // change level base price
    const handleChange = (e) => {
        if (e.target) setLevel({ ...level, price: e.target.value })
    }

    // update levels array
    const handleSubmit = (e) => {
        e.preventDefault()
        if (isDisabled()) return
        setLevels(prevState => ({
            ...prevState,
            [level.id]: {
                ...prevState[level.id],
                ...level
            }
        }))
        handleClose()
    }

    const handleCancel = () => handleClose()

    const isDisabled = () => {
        return basePriceInitialState === level?.price
    }

    return (
        <>
            <div className="sidebar-header heading--flex-space-between">
                <h1 className='heading'>Price levels ({Object.keys(levels)?.length})</h1>
                <AddButton onClick={handleAdd} entity={"level"} isDisabled={Object.keys(levels)?.length >= 9} />
            </div>
            <Button
                variant="link"
                className='btn-unassigned split-row list-item--hover'
                disabled={unassignedSeats?.seats.length === 0}
                onMouseEnter={() => setHasHover(true)}
                onMouseLeave={() => setHasHover(false)}
                onClick={() => selectUnassigned()}
            >
                <div className="flex flex-sm unassigned">
                    <span>Unassigned</span>
                </div>

                <span style={{ display: `${hasHover ? 'none' : 'block'}` }} className='num-seats-md'>{formatNumber(unassignedSeats?.seats.length)}</span>
                <span style={{ display: `${hasHover ? 'block' : 'none'}` }} className='text-link'>Select all</span>
            </Button>
            <div className='d-flex-column'>
                {Object.keys(levels)?.length > 0 ? (
                    <ul className='list-container scaling-container'>
                        {Object.values(levels)?.map((level, index) => (
                            <li key={index}>
                                <PriceLevel level={level} levels={levels} handleShow={handleShow} moveFromIds={moveFromIds}
                                    sumRevenue={sumRevenue}
                                    handleRemove={handleRemove}
                                />
                            </li>
                        ))}
                    </ul>
                ) : (
                    <Button
                        onClick={() => handleAdd('level')}
                        className="p-2 text-link btn--square btn-plus btn-plus-lg icon-button--primary btn-add"
                    >
                        Add price levels
                    </Button>
                )}
            </div>

            <AddPriceModal show={show} level={level} isEdit={Boolean(basePriceInitialState)} difference={basePriceDiff} isDisabled={isDisabled()} offers={offers} handleChange={handleChange} sumRevenue={sumRevenue} sumNewRevenues={sumNewRevenues} handleClose={handleClose} handleSubmit={handleSubmit} handleCancel={handleCancel} />
        </>
    )
}