import React from 'react';
import moment from 'moment';

import { formatDateTime } from '../../../../utilities/helpers';

import { EditDeleteDropdown } from '../../../EditDeleteDropdown';

export default function Campaign({ campaign, handleShow, handleClick, show }) {

    let date = campaign?.scheduled ? moment(campaign?.scheduledTime) : moment(campaign?.createdAt)

    return (
        <div className='flex-row' role="rowgroup">
            <div className='list-table-col text-truncate' role="cell">
                <span>{campaign?.replyTo}</span>
            </div>
            <div className="list-table-col" role="cell">
                <span>{campaign?.contacts?.length}</span>
            </div>
            <div className="list-table-col" role="cell">
                <span>{formatDateTime(date)}</span>
            </div>
            {show && (
                <span className="btn-more-col" role="cell">
                    <EditDeleteDropdown handleShow={() => handleShow(campaign.id)} onClick={(e) => handleClick(e, campaign.id, campaign)} />
                </span>
            )}
        </div>
    );
}
