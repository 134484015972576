import React from 'react';

import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';

import { Item } from './Item'
import { Spinner } from '../../LoadingContainer/Spinner';

export default function SelectMapModal({ show, choice, maps, templates, initialSelected, selected, setSelected, isTemplate, isSaving, handleClose, handleCancel, handleSubmit }) {

    const isExisting = choice === 2;
    const arr = isExisting ? templates : maps;

    return (
        <Modal show={show} animation={false} onHide={handleClose} scrollable centered backdrop='static' className="modal-xl" contentClassName='h-sm'>
            <div className='header'>
                <Modal.Title as="h4">Seat Map {isExisting ? 'template' : 'configuration'}</Modal.Title>
                <p className='heading'>Your {isExisting ? 'saved templates' : 'seat maps'}</p>
                <small className='subtitle subtitle--dark'>Before you can start scaling and manage your inventory, you first need to select your seat map.</small>
            </div>
            <Modal.Body>
                {arr?.length > 0 ? (
                    <Form>
                        <Stack as="ul" gap={2}>
                            {arr?.map(map => (
                                <Item key={map.id} map={map} isExisting={isExisting} isTemplate={isTemplate}
                                    selectedTemplate={templates.find(template => template.id == selected)?.id}
                                    selected={selected}
                                    setSelected={setSelected} />
                            ))}
                        </Stack>
                    </Form>
                ) : (
                    <Stack className='justify-content-center'>
                        <h1 className='text-center fs-md'>No {isExisting ? 'templates saved' : 'configurations'}</h1>
                    </Stack>
                )}
            </Modal.Body>
            <Stack direction="horizontal" className='btn-group-flex'
            >
                <Button
                    variant="outline-light"
                    size="lg"
                    onClick={handleCancel}
                >
                    Cancel
                </Button>
                <Button
                    size="lg"
                    onClick={() => handleSubmit(isExisting)}
                    className={`btn-width-xl ${!isSaving ? 'btn-next' : ''} `}
                    disabled={!selected || initialSelected == selected || isSaving}
                >{isSaving ? (
                    <Spinner />
                ) :
                    'Save and continue'}
                </Button>
            </Stack>
        </Modal>
    );
}
