import React, { useState } from 'react';

import Card from 'react-bootstrap/Card';

import { Level } from './Level';
import { AddDescriptionModal } from './AddDescriptionModal';
import { DeleteModal } from '../../../../DeleteModal';

export default function PricingLevelDescription({ priceLevels, setPriceLevels, isDisabled }) {

    const [showAdd, setShowAdd] = useState(false)

    const [showDelete, setShowDelete] = useState(false)

    const [level, setLevel] = useState()

    const [description, setDescription] = useState('')

    // add description modal 
    const handleSubmit = (e) => {
        e.preventDefault()
        e.stopPropagation()
        setPriceLevels(prevState => ({
            ...prevState,
            [level?.id]: {
                ...prevState[level?.id],
                description
            }
        }))
        handleCloseAdd()
    }

    const handleShowAdd = (_, level) => {
        setShowAdd(true)
        setLevel(level)
        setDescription(level?.description)
    }

    const handleCloseAdd = () => {
        setShowAdd(false)
        setLevel()
        setDescription('')
    }

    const handleShowDelete = (level) => {
        setShowDelete(true)
        setLevel(level)
    }

    const handleCloseDelete = () => {
        setShowDelete(false)
        setLevel()
    }

    const removeDescription = () => {
        setPriceLevels(prevState => ({
            ...prevState,
            [level?.id]: {
                ...prevState[level?.id],
                description: ''
            }
        }))
        handleCloseDelete()
    }

    return (
        <>
            <div className="card-body-heading card-body-heading--sm">
                <Card.Title as="h5" className="card-title-sm">Pricing level description</Card.Title>
                <Card.Subtitle as="h6" className="subtitle subtitle--dark">Add descriptions for each pricing level</Card.Subtitle>
            </div>
            <div className="list-table five-col" role="table">
                <div className="flex-row list-table-header" role="rowgroup">
                    <div className='list-table-col list-table-col-header' role="columnheader">
                        <span>Price levels</span>
                    </div>
                    <div className='list-table-col text-left list-table-col-header xxl' role="columnheader">
                        <span>Description</span>
                    </div>
                    <div className="list-table-col list-table-col-header" role="columnheader">
                        <span>Actions</span>
                    </div>
                </div>
                {priceLevels && Object.values(priceLevels).map(level => (
                    <Level key={level.id} level={level} isDisabled={isDisabled} handleShowAdd={handleShowAdd} handleShowDelete={handleShowDelete} />
                ))}
            </div>
            <AddDescriptionModal show={showAdd} isEditing={Boolean(level?.description)} description={description} setDescription={setDescription} isDisabled={level?.description == description} handleClose={handleCloseAdd} handleSubmit={handleSubmit} />

            <DeleteModal show={showDelete} entity="description" showText={false} handleClose={handleCloseDelete} handleDelete={removeDescription} />
        </>
    );
}
