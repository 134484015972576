import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import isEqual from "lodash/isEqual";

import LoadingContext from "../../../context/Loading/Loading";
import AuthService from "../../../utilities/services/auth.service";
import UserContext from "../../../context/User/User";
import EventDetailsContext from "../../../context/EventDetails/EventDetails";

import {
  getEvent,
  getEventDiscount,
  removeTicketGroup,
} from "../../../utilities/api";
import { checkPermission } from "../../../utilities/helpers";

import Button from "react-bootstrap/Button";

import { CreateOfferWrapper } from "./CreateOfferWrapper";
import { Offers } from "./Offers";
import { NoPermissionsContainer } from "../../NoPermissionsContainer";
import { PageLoadingContainer } from "../../PageLoadingContainer";
import { useDiscount } from "../OffersDiscountProvider/OffersDiscountProvider";
import { Discounts } from "./Discounts";

export default function OffersWrapper({ eventId, id }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { openModal } = useDiscount();

  const { isLoading, showLoading, hideLoading } = useContext(LoadingContext);

  const { orgPermissions } = useContext(UserContext);

  const {
    updateCanPublish,
    standardAdmissionOfferHasInventory,
    updateIsEventOnsale,
  } = useContext(EventDetailsContext);

  const { getPermissions } = AuthService;

  const [hasPermission, setHasPermission] = useState(true);

  const [event, setEvent] = useState();

  const [openInventory, setOpenInventory] = useState();

  // all offers
  const [offers, setOffers] = useState();
  // all discounts
  const [discounts, setDiscounts] = useState(null);

  // current offer
  const [offer, setOffer] = useState();

  // flag to check whether changes to offer has been made or new offer has been created to check whether to fetch data again
  // to tell if back button was clicked
  const [isChangesMade, setIsChangesMade] = useState(false);

  const [isRemoving, setIsRemoving] = useState(false);

  useEffect(() => {
    if (orgPermissions?.length > 0)
      setHasPermission(checkPermission(orgPermissions, getPermissions(), 3));
  }, [orgPermissions]);

  // only runs on initial load
  useEffect(() => {
    getData();
  }, []);

  // on location change
  useEffect(() => {
    // viewing all offers
    if (
      !location.pathname.includes("edit") &&
      !location.pathname.includes("create")
    ) {
      setOffer();
      if (isChangesMade) {
        getData();
        setIsChangesMade(false);
      }
    }
  }, [location, isChangesMade]);

  useEffect(() => {
    if (eventId) {
      getEventOfferDiscounts();
    }
  }, [eventId]);

  const getEventOfferDiscounts = async () => {
    let res = await getEventDiscount(eventId);
    console.log("getEventDiscounts res: ", res.data);
    setDiscounts(res.data.discounts);
  };

  // get offer on refresh if editing
  useEffect(() => {
    if (location.pathname.includes("edit") && !offer) {
      setOffer(offers?.find((offer) => offer.id === Number(id)));
    }
  }, [location, offers]);

  const getData = () => {
    showLoading();
    getEvent(eventId)
      .then((res) => {
        setEvent(res.data);
        setOffers(res.data?.offers);
        if (res?.data?.offers?.length > 0)
          setOpenInventory(Object.values(res?.data?.inventory?.offers)[0]);
        // set flags in context
        updateCanPublish();
        updateIsEventOnsale(res?.data);
        hideLoading();
      })
      .catch((err) => {
        console.error(err);
        hideLoading();
      });
  };

  // handle different views
  const handleClick = (_, id, offer) => {
    if (!id) {
      navigate("create");
      setOffer();
    } else {
      setOffer(offer);
      navigate(`edit?id=${id}`);
    }
  };

  // update offer price depending on pricing option
  const getDiscountedPrice = (price, pricingOpt, discount) => {
    switch (pricingOpt) {
      // take away amount from price
      case "decrease_by_price":
        return price - parseFloat(discount);

      // get percentage of price and take it away from price
      case "decrease_by_percent":
        return Math.abs((parseFloat(discount) / 100) * price - price);

      default:
        break;
    }
  };

  // get offer price - compare price level to standard Admission level in case standard Admission levels have changed
  // offers and standard admission basePrice is accessed by price when first created and basePrice after editing
  const getOfferPrice = (
    standardAdmissionOfferLevel,
    offerLevels,
    idx,
    pricingOption,
    discount
  ) => {
    // get current offer price if same basePrice as standard admission price or is a custom offer price
    if (
      isEqual(
        offerLevels[idx]?.basePrice || offerLevels[idx]?.price,
        standardAdmissionOfferLevel?.basePrice ||
          standardAdmissionOfferLevel?.price
      ) ||
      pricingOption === "custom_price"
    ) {
      return (
        offerLevels[idx]?.offerPrice?.toString() ||
        offerLevels[idx]?.price?.toString()
      );
    } else {
      // if different, get standard admission basePrice with discount, if any
      const defaultPrice =
        standardAdmissionOfferLevel?.basePrice?.toString() ||
        standardAdmissionOfferLevel?.price?.toString();

      if (discount) {
        return getDiscountedPrice(defaultPrice, pricingOption, discount);
      } else {
        return defaultPrice;
      }
    }
  };

  const removeOffers = (id) => {
    return new Promise((resolve, reject) => {
      setIsRemoving(true);
      removeTicketGroup(id)
        .then((res) => {
          setIsRemoving(false);
          resolve();
        })
        .catch((err) => {
          setIsRemoving(false);
          console.error(err);
          reject();
        });
    });
  };

  return (
    <>
      {isLoading ? (
        <PageLoadingContainer />
      ) : (
        <div className='position-relative'>
          <section
            className={`max-width-wrapper event-form ${
              !hasPermission ? "overlay" : ""
            }`}
          >
            {!location.pathname.includes("/create") &&
            !location.pathname.includes("/edit") ? (
              <section>
                <header className='section-header-sm section-heading--flex section-heading section-heading--secondary'>
                  <h1>Offers</h1>
                  {offers?.length > 0 && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "8px",
                      }}
                    >
                      <Button
                        size='lg'
                        disabled={
                          event?.status === "complete" ||
                          !standardAdmissionOfferHasInventory
                        }
                        onClick={handleClick}
                        className='ms-auto'
                      >
                        Create offer
                      </Button>
                      <Button
                        size='lg'
                        onClick={openModal}
                        className='ms-auto'
                        variant='secondary'
                      >
                        Create Discount
                      </Button>
                    </div>
                  )}
                </header>
                <Offers
                  isEventPublished={event?.status === "on_sale"}
                  timezone={event?.timezone}
                  offers={offers}
                  eventInventory={event?.inventory}
                  openInventory={openInventory}
                  handleClick={handleClick}
                  getOfferPrice={getOfferPrice}
                />
                <Discounts discounts={discounts} />
              </section>
            ) : (
              <CreateOfferWrapper
                eventId={eventId}
                event={event}
                id={id}
                offers={offers}
                currentOffer={offer}
                eventInventory={event?.inventory}
                openInventory={openInventory}
                standardAdmissionOfferHasInventory={
                  standardAdmissionOfferHasInventory
                }
                setIsChangesMade={setIsChangesMade}
                getDiscountedPrice={getDiscountedPrice}
                getOfferPrice={getOfferPrice}
                removeOffers={removeOffers}
                isRemoving={isRemoving}
              />
            )}
          </section>

          {!hasPermission && <NoPermissionsContainer />}
        </div>
      )}
    </>
  );
}
