import React from 'react';

import { formatCurrency } from '../../../../../../../utilities/helpers';

import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Card from 'react-bootstrap/Card';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';

import { RevenueCard } from '../../RevenueCard';

export default function AddPriceModal({ show, level, isEdit, difference, isDisabled, offers, handleChange, sumRevenue, sumNewRevenues, handleClose, handleSubmit, handleCancel }) {

    const getOffers = (offers) => {
        if (offers) {
            // all offers including exclusive offers 
            const allOffers = offers?.length;
            // offers that pull from open inventory 
            const openOffers = offers[0]?.subOffers?.length

            return (allOffers + openOffers)
        }
    }

    return (
        <Modal show={show} animation={false} onHide={handleClose} centered backdrop='static'>
            <Modal.Body>
                <div className="modal-body-heading">
                    <h1 className='modal-body-heading-title'>{isEdit ? 'Edit' : 'Add'} {level?.name} value</h1>
                </div>
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId='price'>
                        <Form.Label>Base value</Form.Label>
                        <InputGroup>
                            <InputGroup.Text id="price-val">$</InputGroup.Text>
                            <Form.Control
                                placeholder="Base value"
                                name="price"
                                aria-describedby="price-val"
                                pattern="^[0-9.]*$"
                                value={level?.price || ''}
                                onChange={(e) => handleChange(e.target.validity.valid || e.target.value === '' ? e : level?.price)}
                                autoFocus
                                required
                            />
                        </InputGroup>
                    </Form.Group>
                </Form>
                {isEdit && (
                    <Stack gap={3} className='mt-3'>
                        <RevenueCard level={level} impact={difference} sumRevenue={sumRevenue} sumNewRevenues={sumNewRevenues} />
                        <Card body className="card--xs card-with-border">
                            <Stack gap={2}>
                                <Card.Title as="h5" className='card-title-xs'>Impacted offers ({getOffers(offers)})</Card.Title>
                                <div className="split-row">
                                    <span>Standard admission</span>
                                    <div className={`icon-text-box ${difference ? (difference >= 0 ? 'increase' : 'decrease') : ''}`}>
                                        <span className='fw-medium'>{formatCurrency(level?.price)}</span>
                                    </div>
                                </div>
                            </Stack>
                        </Card>
                    </Stack>
                )}
                <Stack direction="horizontal" className='btn-group-flex'
                >
                    <Button
                        variant="outline-light"
                        size="lg"
                        onClick={handleCancel}
                    >
                        Cancel
                    </Button>
                    <Button
                        size="lg"
                        onClick={handleSubmit}
                        disabled={!level?.price || isDisabled}
                    >
                        Apply
                    </Button>
                </Stack>
            </Modal.Body>
        </Modal>
    );
}
