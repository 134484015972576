import React from 'react';

import Modal from 'react-bootstrap/Modal';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';

import { GeneralOnsale } from "../GeneralOnsale";
import { Spinner } from '../LoadingContainer/Spinner';

export default function GeneralOnsaleModal({ show, event, generalOnsale, setGeneralOnsale, generalOnsaleEnd, eventVisibility, eventStart, isDisabled, error, errorMsg, isSaving, handleSubmit, handleClose }) {

    return (
        <Modal centered animation={false} show={show} onHide={handleClose} backdrop="static" className='modal-lg'>
            <Modal.Body>
                <GeneralOnsale event={event} generalOnsale={generalOnsale} setGeneralOnsale={setGeneralOnsale} generalOnsaleEnd={generalOnsaleEnd} eventStart={eventStart} eventVisibility={eventVisibility} error={error} errorMsg={errorMsg} />
                <Stack direction="horizontal" className='btn-group-flex'
                >
                    <Button variant="outline-light"
                        size="lg"
                        onClick={handleClose}>Cancel</Button>
                    <Button
                        disabled={isDisabled || error}
                        size="lg"
                        className='btn-width-xs'
                        onClick={handleSubmit}

                    >{isSaving ? (
                        <Spinner />
                    ) : 'Apply'
                        }
                    </Button>
                </Stack>
            </Modal.Body>
        </Modal>
    );
}
