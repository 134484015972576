import React, { useState, useEffect, useRef } from 'react';

import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Card from 'react-bootstrap/Card';
import Stack from 'react-bootstrap/Stack';

import { RequiredText } from '../../../../RequiredText';
import { NoLimitMsg } from './NoLimitMsg';
import { ExactLimit } from './ExactLimit';
import { MinQuantityLimit } from './MinQuantityLimit';
import { MaxQuantityLimit } from './MaxQuantityLimit';
import { MultipleOfQuantityLimit } from './MultipleOfQuantityLimit';

export default function TicketLimits({ globalTicketLimit, limits, setLimits, initialState, canEdit, errors, requiredFieldErrorStatus, setHasExactLimit, handleChange, handleValid, findError }) {

    const inputRef = useRef(null)

    const [key, setKey] = useState()

    const [limitErrors, setLimitErrors] = useState([])

    const [isFirstRender, setIsFirstRender] = useState(true)

    // set tab depending on initial state
    useEffect(() => {
        let key;
        if (initialState?.hasMinQuantity || initialState?.hasMaxQuantity || initialState?.hasMultipleOfQuantity) {
            key = 'custom'
        } else if (initialState?.limit) {
            key = 'exact'
        } else {
            key = 'none'
        }
        setKey(key)

    }, [initialState])

    // reset state when tab and state change
    useEffect(() => {
        let hasExactLimit;

        if (key === 'none' || key === 'custom') {
            hasExactLimit = false;

        } else if (key === 'exact') {
            hasExactLimit = true;
        }

        const updatedLimits = resetLimits(key, limits?.hasMinQuantity, limits?.hasMaxQuantity, limits?.hasMultipleOfQuantity, initialState, isFirstRender)

        setLimits((prevLimits) => ({ ...prevLimits, ...updatedLimits }))
        setHasExactLimit(hasExactLimit)

        // focus input field whenever key changes - will trigger before limit state is set
        if (!limits?.limit) {
            if (inputRef.current) {
                inputRef.current.focus()
            }
        }
    }, [key, initialState, limits?.hasMinQuantity, limits?.hasMaxQuantity, limits?.hasMultipleOfQuantity, limits?.limit])

    useEffect(() => {
        const limitErrors = findError(['minQuantity', 'maxQuantity', 'multipleOfQuantity', 'limit'])
        setLimitErrors(limitErrors?.filter(error => error !== undefined))
    }, [errors])

    // reset inputs when tabs change 
    // - turn toggles off and empty input fields when key changes
    // - empty input fields when switches are turned off
    const resetLimits = (key, hasMinQuantity, hasMaxQuantity, hasMultipleOfQuantity, initialState, isFirstRender) => {

        let updatedLimits = {}
        // get initial state values only on first render (setting initial state)
        if (isFirstRender && initialState) {
            // if state is equal - means first time in, get values from initial state
            setIsFirstRender(false)
            updatedLimits = initialState
        } else {
            // not first time (state has changed)
            // reset input fields when toggle is switched off
            if (!hasMinQuantity || !hasMaxQuantity || !hasMultipleOfQuantity) {

                if (!hasMinQuantity) {
                    updatedLimits = { ...updatedLimits, minQuantity: '' }
                }
                if (!hasMaxQuantity) {
                    updatedLimits = { ...updatedLimits, maxQuantity: '' }
                }
                if (!hasMultipleOfQuantity) {
                    updatedLimits = { ...updatedLimits, multipleOfQuantity: '' }
                }
            }

            // reset state when tab is changed 
            if (key === 'none') {
                updatedLimits = { ...updatedLimits, hasMinQuantity: false, minQuantity: '', hasMaxQuantity: false, maxQuantity: '', hasMultipleOfQuantity: false, multipleOfQuantity: '', limit: '' }
            }
            else if (key === 'custom') {
                updatedLimits = { ...updatedLimits, limit: '' }
            } else if (key === 'exact') {
                updatedLimits = { ...updatedLimits, hasMinQuantity: false, minQuantity: '', hasMaxQuantity: false, maxQuantity: '', hasMultipleOfQuantity: false, multipleOfQuantity: '' }
            }

        }
        return updatedLimits
    }

    const getText = () => {

        if (canEdit) {
            return 'Choose the type of limit applied to this offer'
        }
        else {
            if (limits?.hasMaxQuantity || limits?.hasMinQuantity || limits?.hasMultipleOfQuantity || limits?.limit) return 'Custom limit'
            else return 'None'
        }
    }

    return (
        <>
            <div className="card-body-heading--sm">
                <div className="flex">
                    <Card.Title as="h5" className='card-title-sm'>Ticket Limits</Card.Title>
                    {(limitErrors?.length > 0 || requiredFieldErrorStatus?.limit) && (<RequiredText />)}
                </div>
                <Card.Subtitle as="h6" className="subtitle--dark">{getText()}</Card.Subtitle>
            </div>
            {canEdit ? (
                <Tabs
                    id="ticket-limits"
                    variant="pills"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="w-75"
                    justify
                >
                    <Tab eventKey="none" title="None">
                        <Card body className="card--sm card-with-border">
                            <NoLimitMsg globalTicketLimit={globalTicketLimit} />
                        </Card>
                    </Tab>
                    <Tab eventKey="custom" title="Custom">
                        <Card body className="card--sm card-with-border">
                            <div className="card-body-heading card-body-heading--sm">
                                <Card.Title as="h5" className='card-title-xs card-title-thin'>Set one or multiple limit restrictions</Card.Title>
                            </div>
                            {limitErrors?.length > 0 && (
                                <Stack gap={1} className='mb-3'>
                                    <>
                                        {limitErrors?.map((error, idx) => (
                                            <small key={idx} className="error">{error?.message}</small>
                                        ))}
                                    </>
                                </Stack>
                            )}
                            <Stack as="ul" gap={4}>
                                <MinQuantityLimit limits={limits}
                                    hasError={limitErrors?.some(error => error?.field === 'minQuantity') || (Boolean(requiredFieldErrorStatus?.limit) && !limits?.minQuantity)}
                                    handleChange={handleChange} handleValid={handleValid} />
                                <MaxQuantityLimit limits={limits}
                                    hasError={limitErrors?.some(error => error?.field === 'maxQuantity') || (Boolean(requiredFieldErrorStatus?.limit) && !limits?.maxQuantity)} handleChange={handleChange} handleValid={handleValid} />
                                <MultipleOfQuantityLimit limits={limits} hasError={limitErrors?.some(error => error?.field === 'multipleOfQuantity') || (Boolean(requiredFieldErrorStatus?.limit) && !limits?.multipleOfQuantity)} handleChange={handleChange} handleValid={handleValid} />
                            </Stack>
                        </Card>
                    </Tab>
                    <Tab eventKey="exact" title="Exact amount">
                        <Card body className="card--sm card-with-border">
                            <div className="card-body-heading card-body-heading--sm">
                                <Card.Title as="h5" className='card-title-xs card-title-thin'>Set an exact ticket limit</Card.Title>
                            </div>
                            {limitErrors?.length > 0 && (
                                <Stack gap={1} className='mb-3'>
                                    <>
                                        {limitErrors?.map((error, idx) => (
                                            <small key={idx} className="error">{error?.message}</small>
                                        ))}
                                    </>
                                </Stack>
                            )}
                            <ExactLimit limits={limits} inputRef={inputRef} hasError={limitErrors?.some(error => error?.field === 'limit') || (Boolean(requiredFieldErrorStatus?.limit) && !limits?.limit)} handleChange={handleChange} handleValid={handleValid} />
                        </Card>
                    </Tab>
                </Tabs>
            ) : (
                <Card body className="card--sm card-with-border">
                    <Stack gap={4}>
                        {limits?.hasMinQuantity && (
                            <MinQuantityLimit limits={limits} canEdit={canEdit} />
                        )}
                        {limits?.hasMaxQuantity && (
                            <MaxQuantityLimit limits={limits} canEdit={canEdit} />
                        )}
                        {limits?.hasMultipleOfQuantity && (
                            <MultipleOfQuantityLimit limits={limits} canEdit={canEdit} />
                        )}
                    </Stack>
                    {limits?.limit && (
                        <ExactLimit limits={limits} canEdit={canEdit} />
                    )}
                    {!limits?.hasMaxQuantity && !limits?.hasMinQuantity && !limits?.hasMultipleOfQuantity && !limits?.limit && (
                        <NoLimitMsg globalTicketLimit={globalTicketLimit} />
                    )}
                </Card>
            )}
        </>
    );
}