import React from 'react'
import { Rect } from 'react-konva';

export default function Seat({ selectSeat, seat, fill, activeMapAction, hovering, darkenColor, seatMouseToggle }) {

    const handleMouseEnter = (e) => {
        const container = e.target.getStage().container();
        container.style.cursor = "pointer";
        seatMouseToggle(seat.seatId, true);
    }

    const handleMouseLeave = (e) => {
        const container = e.target.getStage().container();
        container.style.cursor = "default";
        seatMouseToggle(seat.seatId, false);
    }

    const getColor = () => fill ? fill : '#E6E8EC'; // Put this into the fill itself

    return (
        <Rect
            x={seat.cx}
            y={seat.cy}
            id={seat.seatId}
            cornerRadius={20}
            className='seat'
            width={seat.w}
            height={seat.h}
            fill={hovering ? darkenColor(getColor(), 1) : getColor()}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={() => selectSeat(seat.seatId)}
            listening={[1, 2, 4].includes(activeMapAction) ? false : true}
        ></Rect>
    )
}