import React from 'react'

import Button from 'react-bootstrap/Button'

export default function AddButton({ onClick, entity, isDisabled }) {

    return (
        <Button
            variant='default'
            onClick={() => onClick(entity)}
            disabled={isDisabled}
            className='btn-icon btn-icon-md btn-icon--square bg-light border-0'
        >
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                <path fillRule="evenodd" clipRule="evenodd" d="M7.72718 0.939187C7.72718 0.537525 7.40157 0.211914 6.99991 0.211914C6.59825 0.211914 6.27264 0.537525 6.27264 0.939187V6.27264H0.939187C0.537525 6.27264 0.211914 6.59825 0.211914 6.99991C0.211914 7.40157 0.537525 7.72718 0.939187 7.72718H6.27264V13.0604C6.27264 13.4621 6.59825 13.7877 6.99991 13.7877C7.40157 13.7877 7.72718 13.4621 7.72718 13.0604V7.72718H13.0604C13.4621 7.72718 13.7877 7.40157 13.7877 6.99991C13.7877 6.59825 13.4621 6.27264 13.0604 6.27264H7.72718V0.939187Z" fill="#141416" />
            </svg>
        </Button>
    );
}
