import React from "react";

import Card from "react-bootstrap/Card";
import Stack from "react-bootstrap/Stack";
import Badge from "react-bootstrap/Badge";

export default function Discounts({ discounts }) {
  return (
    <Card body style={{ marginTop: "16px" }} className='card--md card--light'>
      <div className='card-body-heading card-body-heading--xs'>
        <Card.Title as='h5' className='text-upper mb-3'>
          Discounts
        </Card.Title>
      </div>
      <Stack gap={3} as='ul' className='list-unstyled'>
        {discounts?.map((discount, id) => (
          <li key={id} className=' p-3' style={{ backgroundColor: '#fcfcfd', border: '1px solid #e6e8ec', borderRadius: '8px'}}>
            <div className='d-flex justify-content-between align-items-start mb-2'>
              <h6 className='mb-0 text-primary'>{discount.name}</h6>
              <Badge
                bg={discount.type === "ongoing" ? "success" : "warning"}
                className='text-uppercase'
              >
                {discount.type}
              </Badge>
            </div>
            <p className='mb-1'>
              <strong>Discount:</strong> {discount.discountPercentage}%
            </p>
            {discount.type === "time-limited" && discount.expires && (
              <p className='mb-0 text-muted'>
                <small>
                  <strong>Expires:</strong>{" "}
                  {new Date(discount.expires).toLocaleDateString()}
                </small>
              </p>
            )}
          </li>
        ))}
      </Stack>
      {(!discounts || discounts.length === 0) && (
        <p className='text-muted mb-0'>
          No discounts available for this event.
        </p>
      )}
    </Card>
  );
}
