import { useParams } from "react-router-dom";

import { EventDetailsWrapper } from "../../components";

export default function EventDetailsPage() {

    const { uuid } = useParams()

    return (
        <>
            <EventDetailsWrapper eventId={uuid} />
        </>
    )
}