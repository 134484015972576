import React from 'react';

import Card from 'react-bootstrap/Card';

export default function NoLimitMsg({ globalTicketLimit }) {

    return (
        <Card.Title as="h5" className='card-title-thin card-title-sm info-text info-text-md'>No specific limit has been defined for this offer. {Boolean(globalTicketLimit) && `The global ticket limit of ${globalTicketLimit} will be used`}</Card.Title>
    );
}
