import React from 'react'

import { DroppableItem } from '../../../DroppableItem';
import { Item } from '../../../Item';

export default function InventoryItem({ index, entity, el, isSubItem = false, styles, data, moveFromIds, hasSubSeatsAssigned, canAdd, canRemove, handleRemove, handleAdd }) {
    return (
        <DroppableItem index={index} data={data} styles={`${styles || ''} ${isSubItem ? 'sub-item' : ''}`} moveFromIds={moveFromIds} hasSubSeatsAssigned={hasSubSeatsAssigned} hasHover={(canAdd || canRemove)}>
            <Item index={index} entity={entity} data={data} el={el} isSubItem={isSubItem} canRemove={canRemove} canAdd={canAdd} handleRemove={handleRemove} handleAdd={handleAdd} />
        </DroppableItem>
    )
}