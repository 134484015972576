import React from 'react';

import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';

export default function AddReportModal({ id, show, handleClose, name, handleChange, handleSave, isDisabled }) {

    return (
        <Modal show={show} onHide={handleClose} backdrop={'static'} centered animation={false} fullscreen="md-down" className='modal--with-margin'>
            <Modal.Header closeButton>
                <Modal.Title>{id ? 'Edit' : 'Save custom'} report</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group controlId="name">
                    <Form.Label>Report name</Form.Label>
                    <Form.Control
                        type="text"
                        name="name"
                        value={name}
                        required
                        onChange={handleChange}
                    />
                </Form.Group>
                <Stack className='btn-group-flex'>
                    <Button size="lg" onClick={handleSave} disabled={isDisabled}>
                        {id ? 'Update report' : 'Save'}
                    </Button>
                </Stack>
            </Modal.Body>
        </Modal>

    );
}
