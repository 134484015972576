import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import moment from "moment"
import { isEqual } from 'lodash'

import EventDetailsContext from '../../context/EventDetails/EventDetails';

import { publishEvent } from '../../utilities/api';

import { formatDateTime, getIsTimeAfterGeneralOnsaleErrorMsg, getTimezoneDate, isTimeAfterGeneralOnsale } from '../../utilities/helpers';

import Stack from 'react-bootstrap/Stack';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';

import { PublishModal } from './PublishModal';
import { EventVisibilityModal } from './EventVisibilityModal';

import './eventBanner.scss'

export default function EventBanner({ eventId }) {

    const navigate = useNavigate()

    const { event, eventStart, eventVisibility, generalOnsale, canPublish, isEventOnsale, storeEventVisibility, updateEvent } = useContext(EventDetailsContext)

    const statuses = {
        1: {
            type: 'draft',
            label: 'Draft'
        },
        2: {
            type: 'scheduled',
            label: 'Scheduled'
        },
        3: {
            type: 'published',
            label: 'Published'
        },
        4: {
            type: 'onsale',
            label: 'On Sale'
        },
        5: {
            type: 'ended',
            label: 'Ended'
        },
        6: {
            type: 'soldout',
            label: 'Sold Out'
        },
        7: {
            type: 'cancel',
            label: 'Cancelled'
        }
    }

    const [initialState, setInitialState] = useState()

    const [showDropdownOpen, setShowDropdownOpen] = useState(false)

    const [show, setShow] = useState(false)

    const [showSchedule, setShowSchedule] = useState(false)

    const [status, setStatus] = useState();

    const [step, setStep] = useState(1)

    const [isScheduled, setIsScheduled] = useState(false)

    const [eventVisibilityLocal, setEventVisibilityLocal] = useState(null)

    const [timeError, setTimeError] = useState(false)

    const [timeErrorMsg, setTimeErrorMsg] = useState('')

    const [isSaving, setIsSaving] = useState(false)

    const [alert, setAlert] = useState({
        show: false,
        variant: '',
        message: ''
    })

    useEffect(() => {
        const eventVisibilityDate = new Date(moment(eventVisibility).format())

        if (eventVisibility) setEventVisibilityLocal(eventVisibilityDate)

        // date is after today's date 
        setIsScheduled(isAfter())

        // if event has ended, status: ended

        // ended 
        if (event?.status === 'complete') {
            setStatus(5)
        }

        // if event is published; four statuses: published, onsale, sold out, cancelled 
        // event is published
        else if (event?.status === 'on_sale') {
            // TODO: hook up cancelled status - published but offer dates have not passed ?

            // sold out  
            if (event?.soldOut) {
                setStatus(6)
            }
            else if (isEventOnsale) {
                // on sale
                setStatus(4)
            } else {
                // published
                setStatus(3)
            }
            // if event is not published
            // event is not published; three statuses: draft, scheduled, will be published  
        } else {
            // draft
            if (!eventVisibility) {
                setStatus(1)
            }

            // scheduled
            // event visibility is set to future date 
            // or create event flow not complete 
            else if (isAfter() || !canPublish) {
                setStatus(2)
            }

            // published
            // is before today's date - already visible
            else {
                setStatus(3)
                // if event visibility changes or if canPublish changes, publish event 
                handlePublish(eventVisibilityDate)
            }
        }

        setInitialState({
            eventVisibility: eventVisibility ? eventVisibilityDate : null,
            isScheduled: isAfter()
        })

    }, [eventVisibility, canPublish, isEventOnsale, event])

    // reset scheduled date fields 
    useEffect(() => {
        if (!isScheduled) setEventVisibilityLocal(initialState?.eventVisibility)
    }, [isScheduled, initialState?.eventVisibility])

    // validation for event visibility
    // - cannot be after general onsale start date
    useEffect(() => {
        setTimeError(isTimeAfterGeneralOnsale(eventVisibilityLocal, event))
        setTimeErrorMsg(getErrorMsg())
    }, [eventVisibilityLocal])

    // validation for event visibility: 
    // - cannot be after general onsale start date 
    const getErrorMsg = () => {
        if (isTimeAfterGeneralOnsale(eventVisibilityLocal, event)) {
            return getIsTimeAfterGeneralOnsaleErrorMsg(event)
        }
        else return ''
    }

    const isAfter = () => {
        // is after today's date - not visible yet 
        return moment(eventVisibility).isAfter(moment(new Date()))
    }

    const handleVisibility = (isScheduled) => {
        setIsScheduled(isScheduled)
    }

    const handleNext = () => {
        if (step === 3) handlePublish()
        else setStep((prevStep => parseInt(prevStep) + 1))
    }

    const handleShow = (action) => {
        setShowDropdownOpen(false)
        switch (action) {
            case 'publish':
                setShow(true)
                break;

            case 'schedule':
                setShowSchedule(true)

            default:
                break;
        }
    }

    const handleEdit = () => {
        navigate(`/myevent/${eventId}/seatmap`)
        setShow(false)
    }

    // publish modal
    const handleClose = () => {
        setShow(false)
        setEventVisibilityLocal(initialState?.eventVisibility)
        setIsScheduled(initialState?.isScheduled)
        setStep(1)
        // reset alert
        if (alert.show) {
            setAlert({
                show: false,
                variant: '',
                message: ''
            })
        }
    }

    // schedule event visiblity modal 
    const handleCloseSchedule = () => {
        setShowSchedule(false)
        setEventVisibilityLocal(initialState?.eventVisibility)
    }

    // publish event 
    const handlePublish = (visibility = eventVisibilityLocal) => {
        publish(visibility).then((res) => {
            updateEvent(res?.data)
            storeEventVisibility(new Date(moment(res?.data?.eventVisibility).format()))
        })
    }

    const publish = (visibility) => {
        return new Promise((resolve, reject) => {
            setIsSaving(true)
            let data = {};
            // 2 - scheduled 
            // 1 - on_sale 
            data['publishType'] = isScheduled ? 2 : 1;
            // set to 'publish as soon as possible', set visibility to current date 
            if (!isScheduled) visibility = new Date(moment())
            const visibilityDate = moment(visibility).format();
            data['publishDate'] = visibilityDate
            // Remove tickets so payload isn't too large
            delete event?.tickets;
            data['event'] = {
                ...event,
                eventVisibility: visibilityDate
            };

            publishEvent({ data })
                .then((res) => {
                    setIsSaving(false)
                    resolve(res)

                    if (!isSaving) {
                        handleClose()
                    }
                })
                .catch((err) => {
                    setIsSaving(false)
                    console.error(err)
                    setAlert({
                        show: true,
                        variant: 'danger',
                        message: 'Unable to save info. Please try again.'
                    })
                })
        })
    }

    // schedule event visibility
    const handleSubmit = () => {
        setIsSaving(true)
        let data = {}
        data["eventVisibility"] = moment(eventVisibilityLocal).format()
        data["uuid"] = eventId;

        // TODO: edit event visibility

        setIsSaving(false)

        if (!isSaving) {
            handleCloseSchedule()
        }
    }

    const checkDisabled = () => {
        return !eventVisibilityLocal || isEqual(new Date(eventVisibility), eventVisibilityLocal)
    }

    return (
        <>
            <div className='banner banner--sticky' id="banner">
                <div className='banner-wrapper'>
                    <Stack gap={1} className="event-details">
                        <h1 className='normal m-0 event-title'>{event?.name}</h1>
                        <span className='subtitle subtitle--dark m-0'>
                            <span>{formatDateTime(getTimezoneDate(eventStart, event?.timezone), 'dateOnly')}</span>
                            <span className='time'>{formatDateTime(getTimezoneDate(eventStart, event?.timezone), 'timeOnly')}</span>
                        </span>
                    </Stack>
                    <Dropdown show={showDropdownOpen} id='event-status-dropdown' onToggle={() => setShowDropdownOpen(!showDropdownOpen)}>
                        <Dropdown.Toggle variant="default" id="status-dropdown"
                            className={`badge badge-lg badge--${statuses[status]?.type}`}
                        >
                            {statuses[status]?.label}
                        </Dropdown.Toggle>
                        {setShowDropdownOpen && (
                            <Dropdown.Menu className='w-xl dropdown-menu-lg'>
                                <span className='normal text-muted'>{isAfter() ? 'Scheduled on' : 'Event visibility'}:</span>
                                <h1 className='normal'>
                                    {eventVisibilityLocal ? (
                                        <>
                                            <span>{formatDateTime(getTimezoneDate(eventVisibilityLocal, event?.timezone), 'dateOnly')}</span>
                                            <span className='time'>{formatDateTime(getTimezoneDate(eventVisibilityLocal, event?.timezone), 'timeOnly')}</span>
                                        </>
                                    ) : (
                                        "As soon as published"
                                    )}
                                </h1>
                                <span className="subtitle d-block mt-3">Timezone: {event?.timezone?.text}</span>
                                {/* not visible yet (published) and event has not ended */}
                                {(status !== 3 && status !== 5) && (
                                    <>
                                        {!canPublish && (
                                            <p className='mt-3 info-text info-text-md  info-text-danger'><span className="caption text-danger">Event {eventVisibility ? 'will' : 'cannot'} be published {eventVisibility ? 'at scheduled time after' : 'without'} completing event details, event setting, and seat map</span></p>
                                        )}
                                        {/* event doesn't have event visibility or is after today's date (draft or scheduled at later date) */}
                                        {(!eventVisibility || isScheduled) && (
                                            <Stack direction="horizontal" className='btn-group-flex btn-group-flex-justify btn-group-flex-sm seperator seperator-sm'>
                                                <Button
                                                    variant="outline-light"
                                                    onClick={() => handleShow('schedule')}
                                                >
                                                    {eventVisibility ? 'Reschedule' : 'Schedule time'}</Button>

                                                <Button
                                                    disabled={!canPublish}
                                                    onClick={() => handleShow('publish')}
                                                >Publish now</Button>
                                            </Stack>
                                        )}
                                    </>
                                )}
                            </Dropdown.Menu>
                        )}
                    </Dropdown>
                </div>
            </div>

            <PublishModal show={show} event={event} step={step} isScheduled={isScheduled} eventStart={eventStart} eventVisibility={eventVisibilityLocal} setEventVisibility={setEventVisibilityLocal} error={timeError} errorMsg={timeErrorMsg} isDisabled={checkDisabled()} isSaving={isSaving} alert={alert} handleVisibility={handleVisibility} handleEdit={handleEdit} handleNext={handleNext} handleClose={handleClose} />

            <EventVisibilityModal show={showSchedule} event={event} eventVisibility={eventVisibilityLocal} setEventVisibility={setEventVisibilityLocal} generalOnsale={generalOnsale} eventStart={new Date(getTimezoneDate(eventStart, event?.timezone))} error={timeError} errorMsg={timeErrorMsg} isDisabled={checkDisabled()} isSaving={isSaving} handleSubmit={handleSubmit} handleClose={handleCloseSchedule} />
        </>
    );
}
