import React from 'react';

import Alert from 'react-bootstrap/Alert';

export default function Success({ field = "password" }) {

    return (
        <Alert variant='success' className="d-flex gap-2 mt-3 align-items-center">
            <div className="body">
                <p>Your {field} is updated successfully!</p>
            </div>
        </Alert>
    );
}
