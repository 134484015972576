import React from 'react';

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { Attraction } from '../Attraction';

export default function SortableAttraction({ attraction, idx, isDraggingDisabled, isDisabled, handleShowCreate, handleShowDelete }) {

    const {
        attributes,
        listeners,
        setNodeRef,
        setActivatorNodeRef,
        transform,
        isDragging
    } = useSortable({
        id: attraction.id,
        disabled: isDraggingDisabled
    });

    const style = {
        opacity: isDragging || isDisabled ? 0.5 : 1,
        transform: CSS.Transform.toString(transform),
        cursor: isDisabled ? 'default' : 'pointer'
    };

    return (
        <Attraction ref={setNodeRef} activeRef={setActivatorNodeRef} style={style} {...attributes} listeners={listeners} attraction={attraction} idx={idx} isDraggingDisabled={isDraggingDisabled} isDisabled={isDisabled} handleShowCreate={handleShowCreate} handleShowDelete={handleShowDelete} />
    );
}
