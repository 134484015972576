import React, { useEffect, useState, useRef } from 'react';

import AuthService from '../../utilities/services/auth.service';

import { updateUserEmail, updateUsersName, resetPassword } from '../../utilities/api';
import { emailPatternMatch, isMatching } from '../../utilities/helpers';

import Card from 'react-bootstrap/Card';

import { Security } from "./Security";

export default function SecurityWrapper() {

    const user = AuthService.getUser()?.user;

    // ref on new password to check pattern matching 
    const passwordEl = useRef();

    const [initialState, setInitialState] = useState()

    const [info, setInfo] = useState({
        firstName: user.firstName,
        lastName: user.lastName,
        curEmail: user.email,
        email: '',
        curPassword: '',
        password: ''
    })

    const [error, setError] = useState({})

    const [success, setSuccess] = useState([])

    const [isSavingName, setIsSavingName] = useState(false)

    const [isSavingEmail, setIsSavingEmail] = useState(false)

    const [isSavingPassword, setIsSavingPassword] = useState(false)

    const [isUpdateNameDisabled, setIsUpdateNameDisabled] = useState(false)

    const [isUpdateEmailDisabled, setIsUpdateEmailDisabled] = useState(false)

    const [isUpdatePasswordDisabled, setIsUpdatePasswordDisabled] = useState(false)

    useEffect(() => {
        setInitialState(info)
    }, [])

    useEffect(() => {
        setIsUpdateNameDisabled((initialState?.firstName === info.firstName && initialState?.lastName === info.lastName) || (!info.firstName || !info.lastName))
        setIsUpdateEmailDisabled((initialState?.email === info.email) || (!info.email))
        setIsUpdatePasswordDisabled((initialState?.curPassword === info.curPassword && initialState?.password === info.password) || (!info.curPassword || !info.password))
    }, [initialState, initialState, info.firstName, info.lastName, info.email, info.curPassword, info.password])

    useEffect(() => {
        setError({})

    }, [info.curEmail, info.email, info.curPassword, info.password])

    const handleInfo = (e) => {
        e.target && setInfo({ ...info, [e.target.name]: e.target.value })
    }

    const handleUpdate = (form) => {
        if (form === 'email') {
            setIsSavingEmail(true)
            updateUserEmail({ email: info.email })
                .then((res) => {
                    AuthService.setUser(res.data)
                    setIsSavingEmail(false)
                    setSuccess([...success, form])
                })
                .catch((err) => {
                    console.error(err)
                    setError({ field: 'new email', type: 'alreadyExist' })
                    setIsSavingEmail(false)
                })
        }

        if (form === 'name') {
            setIsSavingName(true)
            updateUsersName({ firstName: info.firstName, lastName: info.lastName })
                .then((res) => {
                    AuthService.setUser(res.data)
                    setIsSavingName(false)
                    setSuccess([...success, form])
                })
                .catch((err) => {
                    console.error(err)
                    setIsSavingName(false)
                })
        }

        if (form === 'password') {
            setIsSavingPassword(true)
            resetPassword({ password: info.curPassword, newPassword: info.password })
                .then((res) => {
                    setIsSavingPassword(false)
                    setSuccess([...success, form])
                })
                .catch((err) => {
                    setError({ field: 'current password', type: 'notExist' })
                    setIsSavingPassword(false)
                })
        }
        setInitialState(info)
    }

    // validate if curEmail is correct, newEmail is correct, newPassword is correct and pattern match
    const handleInput = (e) => {
        const { name } = e.target;

        const email = info.curEmail;

        switch (name) {
            case 'curEmail':
                if (info.curEmail !== '' && info.curEmail !== email) {
                    setError({ field: 'current email', type: 'notExist' })
                }
                break;
            case 'email':
                if (info.email) {
                    if (!emailPatternMatch(info.email)) {
                        setError({ field: 'new email', type: 'invalidEmail' })
                    }

                    if (isMatching(info.curEmail, info.email)) {
                        setError({ field: 'new email', type: 'sameMatch' })
                    }
                }
                break;
            case 'password':
                if (isMatching(info.curPassword, info.password)) {
                    setError({ field: 'new password', type: 'sameMatch' })
                }
                else if (info.password !== '' && !passwordEl.current.validity.valid) {
                    setError({ field: 'new password', type: 'invalidPassword' })
                }
                break;
            default:
                return;
        }
    }

    return (
        <section className='wrapper'>
            <header className="section-header">
                <div className="section-heading section-heading--secondary">
                    <h1>Security</h1>
                </div>
                <p className='section-header-desc'>To update your name, email address or password associated with this account, please fill in the following fields</p>
            </header>
            <Card body className='card--sm'>
                <Security info={info} handleInfo={handleInfo} handleUpdate={handleUpdate} handleInput={handleInput} passwordRef={passwordEl} error={error} success={success} isNameDisabled={isUpdateNameDisabled} isSavingName={isSavingName} isEmailDisabled={isUpdateEmailDisabled} isSavingEmail={isSavingEmail} isPasswordDisabled={isUpdatePasswordDisabled} isSavingPassword={isSavingPassword} />
            </Card>
        </section>
    );
}
