import React, { useEffect, useState } from 'react';

import { capitalizeString, formatNumber } from '../../../../utilities/helpers';

import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';

import { DateInputWrapper } from '../../../DateInputWrapper';
import { TimeInputWrapper } from '../../../TimeInputWrapper';
import { PreviewModal } from './PreviewModal';
import { MultiSelectControl } from './MultiSelectControl';

// TODO: check if time is past event start time
export default function ContactForm({ id, event, tickets, attendees, replyTo, setReplyTo, testEmail, setTestEmail, subject, setSubject, sendTo, setSendTo, setTitle, title, setMessage, message, handleChoice, choice, setDate, date, toOpt, setToOption, toOption, sendTest, disableTest, setDisableTest, checkToValue }) {

    const [show, setShow] = useState(false);

    const [ticketTypes, setTicketTypes] = useState()

    const [specificAttendees, setSpecificAttendees] = useState()

    const [value, setValue] = useState()

    useEffect(() => {
        setTicketTypes(getTicketTypes(tickets))
        setSpecificAttendees(getSpecificAttendees(attendees))
    }, [tickets, attendees])

    useEffect(() => {
        if (disableTest) {
            let seconds = 15;
            const interval = setInterval(() => {
                seconds -= 1;
                document.getElementById('email-success').innerText = `Message delivered!!! You can send another test message in ${seconds} seconds`;
                document.getElementById('email-success').style.marginLeft = '15px';
                document.getElementById('email-success').style.marginTop = '10px';
                document.getElementById('email-success').style.color = '#45b36b';
                if (seconds == 0) {
                    clearInterval(interval)
                    document.getElementById('email-success').innerText = ''
                    setDisableTest(false)
                }
            }, 1000);
        }
    }, [disableTest])

    // set select field values if editing only on initial load
    // set value for react select based on sendTo option 
    useEffect(() => {
        if (id) {
            switch (toOption) {
                case 'by_specific':
                    setValue(specificAttendees?.filter(attendee => sendTo.includes(attendee.value)))
                    break;

                case 'by_ticket_type':
                    setValue(ticketTypes?.filter(ticket => sendTo.includes(ticket.value)))
                    break;
            }

        }
    }, [specificAttendees, ticketTypes])

    const handleShow = () => setShow(true)

    const handleClose = () => setShow(false)

    // get all the attendees that have same ticket name to not get duplicates
    const ticketLookup = (name) => {
        return attendees?.filter(attendee => attendee?.ticket?.name === name).length
    }

    // sort by alphabetical order
    const sortBy = (arr) => {
        return arr?.sort((a, b) => a?.users_permissions_user.firstName.toLowerCase().localeCompare(b?.users_permissions_user.firstName.toLowerCase()))
    };

    const getTicketTypes = (tickets) => {
        return tickets?.map(ticket => {
            return {
                value: ticket?.name?.toLowerCase(),
                label: ticket?.name,
                chipLabel: ticket?.name,
                custom: `${formatNumber(ticketLookup(ticket?.name))} Attendees`
            }
        })
    }

    const getSpecificAttendees = (attendees) => {
        return sortBy(attendees)?.map(attendee => {
            return {
                value: attendee?.users_permissions_user?.uuid,
                label: `${capitalizeString(attendee?.users_permissions_user?.firstName)} ${capitalizeString(attendee?.users_permissions_user?.lastName)}`,
                chipLabel: `${capitalizeString(attendee?.users_permissions_user?.firstName)} ${capitalizeString(attendee?.users_permissions_user?.lastName)}`,
                custom: attendee?.ticket?.name
            }
        })
    }

    const handleToOption = e => {
        // reset select and sendTo
        setValue(null)
        setSendTo([])
        setToOption(e.target.value)
    }

    const getPlaceholderText = () => {
        switch (toOption) {
            case 'by_specific':
                return 'Search attendee name'

            case 'by_ticket_type':
                return 'Search ticket type'

            default:
                return;
        }
    }

    const getOptions = () => {
        switch (toOption) {
            case 'by_specific':
                return specificAttendees

            case 'by_ticket_type':
                return ticketTypes

            default:
                return;
        }
    }

    const handleSelected = (selected) => {
        const selectedArr = selected.map(s => s.value)
        setSendTo(selectedArr)
        setValue(selected)
    }

    return (
        <>
            <Form>
                <Form.Group className="form-group" controlId='reply-To'>
                    <Form.Label>Reply-To Email</Form.Label>
                    <Form.Control type="text" name="receiving-email" value={replyTo} onChange={(e) => setReplyTo(e.target.value)} />
                </Form.Group>
                <Form.Group className="form-group" controlId='to'>
                    <Form.Label>To</Form.Label>
                    <Stack gap={2}>
                        <Form.Select onChange={handleToOption} value={toOption}>
                            {toOpt.map((option, index) => (
                                <option key={index} value={option.value}>{option.label}</option>
                            ))}
                        </Form.Select>
                        {toOption !== 'all' && (
                            <MultiSelectControl
                                options={getOptions()}
                                onChange={handleSelected}
                                placeholder={getPlaceholderText()}
                                sendTo={sendTo}
                                value={value}
                            />
                        )}
                    </Stack>
                </Form.Group>
                <Form.Group className="form-group" controlId='subject'>
                    <Form.Label>Subject</Form.Label>
                    <Form.Control placeholder="Subject of the email" type="text" name="subject" value={subject} onChange={(e) => setSubject(e.target.value)} />
                </Form.Group>
                <Form.Group className="form-group" controlId='title'>
                    <Form.Label>Title</Form.Label>
                    <Form.Control type="text" name="title" value={title} placeholder="Title of the email" onChange={(e) => setTitle(e.target.value)} />
                </Form.Group>
                <Form.Group className="form-group" controlId='message'>
                    <Form.Label>Message</Form.Label>
                    <Form.Control
                        as="textarea" rows={5}
                        name="message"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                    />
                    <Stack direction='horizontal'>
                        <Button variant='default' className='ms-auto px-0 pb-0 btn-preview' onClick={handleShow}>Preview your email </Button>
                    </Stack>
                </Form.Group>
                <Row className='form-group'>
                    <Col>
                        <Form.Group controlId='start-date'>
                            <Form.Label>Send test email</Form.Label>
                            <Form.Control type="text" name="test-email" value={testEmail} onChange={(e) => setTestEmail(e.target.value)} />
                        </Form.Group>
                    </Col>
                    <Col md={3} className="d-flex justify-content-center align-items-end">
                        <Button size="lg" disabled={!replyTo || !subject || !title || !message || !testEmail || disableTest || checkToValue()} onClick={sendTest}>Send test</Button>
                    </Col>
                    <span id="email-success" className='form-label'></span>
                </Row>
                <Form.Group className='form-group'>
                    <Form.Label>Send email</Form.Label>
                    <Stack direction="horizontal" >
                        <div key={`inline-radio`} >
                            <Form.Check
                                inline
                                label="Now"
                                name="radGroup"
                                type="radio"
                                id='1'
                                defaultChecked={choice == 1}
                                className='fw-medium'
                                onChange={handleChoice}
                            />
                            <Form.Check
                                inline
                                label="Schedule for later"
                                name="radGroup"
                                type="radio"
                                id='2'
                                defaultChecked={choice == 2}
                                className='fw-medium'
                                onChange={handleChoice}
                            />
                        </div>
                    </Stack>
                    {choice === 2 && (
                        <Row className='mt-3'>
                            <Col>
                                <DateInputWrapper label="Date" id="email-start" setDate={setDate} selectedDate={date} startDate={new Date()} maxDate={new Date(event?.start)} />
                            </Col>
                            <Col>
                                <TimeInputWrapper label="Time" id="email-time" setDate={setDate} selectedDate={date} />
                            </Col>
                        </Row>
                    )}
                </Form.Group>
            </Form>

            <PreviewModal show={show} handleClose={handleClose} title={title} event={event} message={message} />
        </>
    );
}