import { Layer, Path, Group } from 'react-konva';

import { Seat } from "./Seat";

export default function Seats({
    data,
    setData,
    setSeats,
    activeTab,
    shouldSelectSeat,
    activeMapAction,
    openOffer,
    darkenColor
}) {

    const handleRowAction = (rowId, hovering) => {
        if (activeMapAction !== 2) return;

        let updatedData = { ...data };
        applyFilterToRow(rowId, hovering, updatedData.rows, updatedData.seats);

        setData(updatedData);
    };

    const selectSeat = (id) => {
        if (data.seats[id]) {
            setData((prev) => {
                let updatedSeats = { ...prev.seats };
                updatedSeats[id] = { ...updatedSeats[id], selected: true };
                setSeats(id)
                return { ...prev, seats: updatedSeats };
            });
        }
    };

    const applyFilterToRow = (rowId, hovering, rows, seats) => {
        const targetRow = rows[rowId];
        if (!targetRow) return;

        let assignedTarget = targetRow.allAssigned;
        if (activeTab === 'inventory') {
            assignedTarget = targetRow.inventoryAllAssigned
        }

        targetRow.seats.forEach(seatId => {
            const seat = seats[seatId];
            if (seat && shouldSelectSeat(seat, assignedTarget)) {
                seat.hovering = hovering;
            }
        });
    };

    const seatMouseToggle = (seatId, hovering) => {
        setData((prev) => {
            let updatedSeats = { ...prev.seats };
            updatedSeats[seatId] = { ...updatedSeats[seatId], hovering: hovering };
            return { ...prev, seats: updatedSeats };
        });
    }

    // If hostspot show hotspot fill, otherwise show selected color or based on active tab
    const getFillType = (seat) => {
        if (activeMapAction === 4 && activeTab === 'scaling') {
            return seat?.hotspotFill;
        }
        if (seat.selected) return 'rgba(20, 20, 22, 1)';
        if (activeTab === 'scaling') {
            return seat?.PLFill;
        }
        // #3ea9f7
        return seat?.inventoryFill || openOffer?.color;
    }

    const getHovering = (seat) => {
        if (seat?.hovering) {
            return true;
        }
        return false;
    }

    const handleRowSelect = (rowId) => {
        if (activeMapAction !== 2) return;

        let updatedData = { ...data };
        let selectedSeatIds = getSeatIdsForRow(rowId, updatedData.rows, updatedData.seats);

        setSeats(undefined, selectedSeatIds);
        setData(updatedData);
    }

    const getSeatIdsForRow = (rowId, rows, seats) => {
        const targetRow = rows[rowId];
        if (!targetRow) return []; // Return empty array if the row is not found

        let assignedTarget = targetRow.allAssigned;
        if (activeTab === 'inventory') {
            assignedTarget = targetRow.inventoryAllAssigned;
        }

        return targetRow.seats
            .filter(seatId => {
                if (!seats[seatId]) return false;
                if (shouldSelectSeat(seats[seatId], assignedTarget)) {
                    seats[seatId].selected = true;
                    return true;
                }
                return false;
            });
    };

    return (
        <Layer className='seats'>
            {data?.rows && Object.values(data.rows).map((row) => (
                <Group key={row.rowId}>
                    {row.seats.map((seatId) => {
                        const seat = data.seats[seatId];
                        return (
                            <Seat
                                key={seat.seatId}
                                seat={seat}
                                selectSeat={selectSeat}
                                fill={getFillType(seat)}
                                hovering={getHovering(seat)}
                                activeMapAction={activeMapAction}
                                darkenColor={darkenColor}
                                seatMouseToggle={seatMouseToggle}
                            />
                        )
                    })}
                    {/* This is for selecting by row */}
                    {row?.path && (
                        <Path
                            data={row.path}
                            listening={activeMapAction === 2}
                            onClick={() => handleRowSelect(row.rowId)}
                            onMouseOver={() => handleRowAction(row.rowId, true)}
                            onMouseOut={() => handleRowAction(row.rowId, false)}
                            opacity={0}
                        />
                    )}
                </Group>
            ))}
        </Layer>
    )
}