import React from 'react';

import { capitalizeString, formatCurrency } from '../../../../utilities/helpers';

import Form from 'react-bootstrap/Form';
import Stack from 'react-bootstrap/Stack';
import Image from 'react-bootstrap/Image';

import badge from "../../../../assets/map-badge.svg";

export default function Item({ map, isExisting, isTemplate, selectedTemplate, selected, setSelected }) {

    const { id, type } = map;

    // isTemplate
    // is on template screen select the selectedTemplate else don't select radio buttons on create new map screen 
    // if not template 
    // is on create new map screen select the selected else don't select radio buttons on template screen
    const getSelected = () => {
        if (isTemplate) {
            if (isExisting) {
                return selectedTemplate == id
            } else return false
        }

    }

    return (
        <li className={`map-item list-item list-item-lg list-item--hover-primary ${selected == id ? 'selected' : ''} `}>
            <Form.Check type='radio' className="form-check-display m-0" id={`map-type-display-checkbox-${id}`}>
                <Form.Check.Input
                    type='radio'
                    name="map-type"
                    value={id}
                    checked={selected == id}
                    onChange={(e) => setSelected(e.target.value)}
                    className='flex-shrink-0'
                />
                <Stack direction='horizontal' gap={3} className='flex-grow-1 order-first'>
                    <Form.Check.Label id={`map-type-display-checkbox-${id}`} className="m-0 d-flex gap-3 w-100 h-100 align-items-center">
                        <Image
                            src={badge}
                            alt={map.configuration}
                            width="87"
                            height="44"
                            className='py-2'
                        />
                        <Stack className='justify-content-center'>
                            <span className="text-upper">{map.name || type}</span>
                            {isExisting && (
                                <Stack gap={1} as="ul">
                                    <li>{capitalizeString(map.configuration)}</li>
                                    <li>
                                        <span className='text-muted me-1'>Base Price:</span> <span className='fw-semi-bold'>{formatCurrency(map?.price?.high)}<span className="to">{formatCurrency(map?.price?.low)}</span></span>
                                    </li>
                                </Stack>
                            )}
                        </Stack>
                    </Form.Check.Label>
                </Stack>
            </Form.Check>
        </ li>
    );
}
