import React, { createContext, useContext, useRef, useState } from 'react';

import { ZoomLevel } from './ZoomLevel';
import { ToolBar } from './ToolBar';

import '../SeatMap/seatMap.scss'

// Create the context
export const ActionsContext = createContext(undefined);

export const useActions = () => {
    const context = useContext(ActionsContext);
    if (!context) {
        throw new Error('Context must be used within a ActionsProvider');
    }
    return context;
}

// Create the provider component
export const ActionsProvider = ({ children, data, setData, activeTab }) => {
    const stageRef = useRef(null);
    const cursorRef = useRef(null);
    const mapRef = useRef(null);
    const originalScaleRef = useRef(1);
    const [activeMapAction, setActiveMapAction] = useState(3);
    const [scale, setScale] = useState(1);

    const toolbarSelect = (action) => {
        if (activeMapAction === action) {
            setActiveMapAction(0);
            return;
        }

        // Dont like double ifs, refactor
        if (action === 4) {
            cursorRef.current.style.top = "50%"
            cursorRef.current.style.left = "50%"
            cursorRef.current.style.transform = "translate(-50%, -50%)"
        }

        setActiveMapAction(action);
    }

    function calculateScalePercentage(scaleToUse) {
        const ratio = scaleToUse / originalScaleRef.current;
        const percentage = ratio * 100;
        return percentage.toFixed(0);
    }

    return (
        <ActionsContext.Provider value={{ stageRef, scale, setScale, activeMapAction, calculateScalePercentage, originalScaleRef }}>
            <div id="map" ref={mapRef} className={`${activeMapAction === 4 && 'cursor-pointer'}`}>
                {children}
                <div className='controls-container'>
                    <ZoomLevel setScale={setScale} scale={scale} calculateScalePercentage={calculateScalePercentage} stageRef={stageRef} />
                </div>
                <ToolBar activeMapAction={activeMapAction} toolbarSelect={toolbarSelect} />
            </div>
        </ActionsContext.Provider>
    );
};