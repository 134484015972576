import React from 'react'
import { useDroppable } from '@dnd-kit/core';

export default function Droppable({ index, isDisabled, data, styles, hasHover, children }) {

    const { isOver, setNodeRef } = useDroppable({
        id: index,
        data: {
            data
        },
        disabled: isDisabled
    });

    const style = {
        outline: isOver ? '2px solid #3ea9f7' : undefined,
        outlineOffset: '-2px',
        backgroundColor: isOver ? '#fff' : undefined
    };

    return (
        <div className={`droppable ${hasHover ? 'list-item--hover' : ''} ${styles}`} ref={setNodeRef} style={style}>
            {children}
        </div>
    )
}