import { OffersDiscountProvider } from "./OffersDiscountProvider/OffersDiscountProvider";
import OffersWrapper from "./OffersWrapper/OffersWrapper";


export default function OffersWrapperWrapper({ eventId, id}) {


    return (
        <OffersDiscountProvider eventId={eventId}>
            <OffersWrapper eventId={eventId} id={id} />
        </OffersDiscountProvider>
    )
}