import React from 'react';

import { EditDeleteDropdown } from '../../../EditDeleteDropdown';

export default function Report({ report, handleShow, handleClick }) {

    let emails = report.contacts?.map(contact => contact?.emailAddress)

    emails = emails?.toString().split(',').join(', ')

    return (
        <div className='flex-row' role="rowgroup">
            <div className='list-table-col lg' role="cell">
                {emails}
            </div>
            <span className="btn-more-col" role="cell">
                <EditDeleteDropdown handleShow={() => handleShow(report.id)} onClick={(e) => handleClick(e, report.id, report)} />
            </span>
        </div>
    );
}
