import React from 'react';

import Modal from 'react-bootstrap/Modal';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';

import { EventVisibility } from '../../EventVisibility';
import { Spinner } from '../../LoadingContainer/Spinner';

export default function EventVisibilityModal({ show, event, eventVisibility, setEventVisibility, generalOnsale, eventStart, isDisabled, error, errorMsg, isSaving, handleSubmit, handleClose }) {

    return (
        <Modal centered animation={false} show={show} onHide={handleClose} backdrop="static" className='modal-lg'>
            <Modal.Body>
                <EventVisibility event={event} eventVisibility={eventVisibility} setEventVisibility={setEventVisibility} generalOnsale={generalOnsale} eventStart={eventStart} error={error} errorMsg={errorMsg} />
                <Stack direction="horizontal" className='btn-group-flex'
                >
                    <Button variant="outline-light"
                        size="lg"
                        onClick={handleClose}>Cancel</Button>
                    <Button
                        disabled={isDisabled || error}
                        size="lg"
                        className='btn-width-xs'
                        onClick={handleSubmit}

                    >{isSaving ? (
                        <Spinner />
                    ) : 'Apply'
                        }
                    </Button>
                </Stack>
            </Modal.Body>
        </Modal>
    );
}
