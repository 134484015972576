import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from "react-router-dom";

import LoadingContext from '../../context/Loading/Loading';

import { getPromoStats } from '../../utilities/api';

import { PageLoadingContainer } from '../PageLoadingContainer';

import { TrackingLinks } from './TrackingLinks';
import { TrackingLinkWrapper } from './TrackingLinkWrapper';

export default function TrackingLinksWrapper({ eventId }) {

    const { isLoading, showLoading, hideLoading } = useContext(LoadingContext)

    const navigate = useNavigate()
    const location = useLocation();

    const [promos, setPromos] = useState([])

    const [isRemoving, setIsRemoving] = useState(false)

    // flag to check whether changes to tracking link has been made or new campaign has been created to check whether to fetch data again
    // to tell if cancel button was clicked 
    const [isChangesMade, setIsChangesMade] = useState(false)

    // only runs on initial load 
    useEffect(() => {
        getTrackingLinks()
    }, [])

    // on location change
    useEffect(() => {
        // viewing all tracking links  
        if (!location.pathname.includes('add')) {
            if (isChangesMade) {
                getTrackingLinks()
                setIsChangesMade(false)
            }
        }
    }, [location, isChangesMade])

    const getTrackingLinks = () => {
        showLoading();
        getPromoStats(eventId)
            .then((res) => {
                setPromos(res.data);
                hideLoading()
            })
            .catch((err) => {
                console.error(err)
                hideLoading()
            })
    }

    // handle different views 
    const handleClick = () => navigate('add')

    const removeTrackingLink = () => {
        return new Promise((resolve, reject) => {
            setIsRemoving(true)
            setIsRemoving(false)
            resolve();
        })
    }

    return (
        <>
            {isLoading ? (
                <PageLoadingContainer />
            ) : (
                <section className='max-width-wrapper'>
                    {(!location.pathname.includes('/add')) ? (
                        <section>
                            <header className='section-header'>
                                <div className="section-header" >
                                    <div className="section-heading">
                                        <h1>Tracking links</h1>
                                    </div>
                                    <p className='section-header-desc'>Use custom links to track the success of your promotional emails, promoter partners, and more</p>
                                </div>
                            </header>
                            <TrackingLinks promos={promos} removeLink={removeTrackingLink} isRemoving={isRemoving} handleClick={handleClick} />
                        </section>
                    ) : (
                        <TrackingLinkWrapper eventId={eventId} setIsChangesMade={setIsChangesMade} />
                    )}
                </section>
            )}
        </>
    );
}


