import React from 'react';

import { WarningCard } from '../../WarningCard';

export default function InfoCard() {

    return (
        <WarningCard text="Reports will be sent out to selected recipients daily at 8am EST" iconStyle="fill" size="lg" />
    );
}
