import React from 'react';

import { formatNumber } from '../../../../../../utilities/helpers';

import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';

import { Item } from './Item';

export default function SelectGASeatsModal({ show, openOffer, assigned, seatsPer, seatsPerInitialState, count, totalSeats, availableSeats, hasError, handleCount, handleChange, handleSubmit, handleCancel, handleClose }) {

    const showEl = openOffer?.seats?.filter(seat => seat.startsWith('ga'))?.length > 0

    const getCount = () => {
        // if ga seats are assigned to other holds/kills and value has changed, get the difference and add it to the new count 
        if (seatsPer?.length > 0 && seatsPer?.some((seats, idx) => seats != seatsPerInitialState[idx])) {
            const differencePer = seatsPer.map((seats, idx) => parseInt(seats) - seatsPerInitialState[idx])
            return parseInt(count || 0) + differencePer.reduce((acc, curr) => curr > 0 ? acc + curr : acc, 0)
        } else return count || 0
    }

    return (
        <Modal show={show} animation={false} onHide={handleClose} centered backdrop='static' className='modal-sm'>
            <Modal.Body>
                <div className="modal-body-heading modal-body-heading-sm">
                    <h1 className='modal-body-heading-title'>Seats from GA section</h1>
                </div>
                <Form onSubmit={handleSubmit}>
                    {hasError && (
                        <Form.Text className='error mb-3'>Total number of GA seats cannot exceed total amount: {formatNumber(totalSeats?.length)}</Form.Text>
                    )}
                    <div className="list">
                        <Stack gap={5} as="ul">
                            {assigned?.length > 0 && (
                                <li>
                                    <p className='small fw-medium mb-3'>Seats assigned to:</p>
                                    <Stack as="ul" gap={2}>
                                        {assigned.map((obj, idx) => (
                                            <li key={idx}>
                                                <Item
                                                    obj={obj}
                                                    index={idx}
                                                    numSeats={seatsPer[idx]}
                                                    numSeatsInitialState={seatsPerInitialState[idx]}
                                                    hasError={hasError && seatsPer[idx] != seatsPerInitialState[idx]}
                                                    handleChange={handleChange}
                                                />
                                            </li>
                                        ))}
                                    </Stack>
                                </li>
                            )}
                            {showEl && (
                                <li>
                                    <p className='mb-3'>Select number of seats to move from:</p>
                                    <Item
                                        obj={openOffer}
                                        numSeats={count}
                                        numSeatsInitialState={availableSeats?.length}
                                        hasError={hasError && count !== 0}
                                        handleChange={handleCount}
                                        autoFocused={true}
                                    />
                                </li>
                            )}
                        </Stack>
                    </div>
                    {showEl && (
                        <div className='seperator seperator-sm split-row split-row-lg'>
                            <span className='normal fw-bold'>Total</span>
                            <span className='num-seats-lg'>{formatNumber(getCount())} / {formatNumber(totalSeats?.length)}</span>
                        </div>
                    )}
                    <Stack direction="horizontal" className='btn-group-flex'
                    >
                        <Button
                            variant="outline-light"
                            size="lg"
                            onClick={handleCancel}
                        >
                            Cancel
                        </Button>
                        <Button
                            size="lg"
                            type="submit"
                            disabled={hasError || (showEl ? count == 0 : seatsPer?.toString() == seatsPerInitialState?.toString())}
                        >
                            Apply
                        </Button>
                    </Stack>
                </Form>
            </Modal.Body>
        </Modal>
    );
}
