import React from 'react';

import Modal from 'react-bootstrap/Modal';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';

export default function GoToSeatMapModal({ show, id, handleClose, handleClick }) {

    return (
        <Modal className="modal--lg" centered animation={false} fullscreen="md-down" show={show} onHide={handleClose} backdrop="static">
            <Modal.Body>
                <div className="modal-body-heading">
                    <h1 className="modal-body-heading-title">Offer created!</h1>
                    <p>Do you want to go to the seat map to assign seats to the offer?</p>
                </div>
                <Stack className="btn-group-flex">
                    <Button size="lg" onClick={() => handleClick(id)}>
                        Take me to seat map
                    </Button>
                    <Button size="lg" variant="outline-light" onClick={handleClose}>
                        Not now
                    </Button>
                </Stack>
            </Modal.Body>
        </Modal>
    );
}
