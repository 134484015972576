import React from 'react'

import { Droppable } from '../Droppable'

// reusable item for holds/kills/pricing level
export default function DroppableItem({ index, data, isSubItem, styles, moveFromIds, hasSubSeatsAssigned, hasNoPrice, hasHover = true, children }) {

    const isDisabled = () => {
        if (moveFromIds?.length > 0) {
            // index matches or includes move from id 
            return moveFromIds.includes(index);
        } else return false
    }

    return (
        <Droppable index={index} data={data} isDisabled={isDisabled() || hasSubSeatsAssigned || hasNoPrice} styles={`${styles || ''} ${isSubItem ? 'sub-item' : ''}`} hasHover={hasHover}>
            {children}
        </Droppable>
    )
}