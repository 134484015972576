import React from 'react';

import { formatAddress } from '../../../utilities/helpers';

import Form from 'react-bootstrap/Form';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Button from 'react-bootstrap/Button';

import { InfoIcon } from '../../InfoIcon';
import { RequiredText } from '../../RequiredText';

export default function BasicInfo({ isEditable, handleChange, handleVenue, event, venues, requiredFieldErrorStatus }) {

    return (
        <>
            <Form.Group className="form-group" controlId="venue">
                <div className="form-label--flex">
                    <Form.Label>Venue</Form.Label>
                    {requiredFieldErrorStatus?.venue && (<RequiredText />)}
                </div>
                <Form.Select
                    name="venue"
                    disabled={isEditable || event?.status === 'on_sale'}
                    value={event.room?.uuid}
                    onChange={handleVenue}
                    className={requiredFieldErrorStatus?.venue ? 'error-border' : ''}
                    autoFocus
                >
                    <option disabled hidden value="">Select venue</option>
                    {venues && venues.map((option, index) => (
                        <optgroup label={`${option?.name} - ${formatAddress(option)}`} key={index}>
                            {option.rooms?.map((room, i) => (
                                <option value={room.uuid} data-venue={option} key={i}>{room.name}</option>
                            ))}
                        </optgroup>
                    ))}
                </Form.Select>
            </Form.Group>
            <Form.Group className="form-group" controlId="name">
                <div className="form-label--flex">
                    <Form.Label>Event Name</Form.Label>
                    <OverlayTrigger
                        placement="right"
                        overlay={<Tooltip>Maximum 100 characters. No HTML or emoji allowed</Tooltip>}>
                        <Button variant="link">
                            <InfoIcon />
                        </Button>
                    </OverlayTrigger>
                    {requiredFieldErrorStatus?.name && (<RequiredText />)}
                </div>
                <Form.Control
                    type="text"
                    name="name"
                    placeholder="Enter event name"
                    disabled={isEditable}
                    maxLength="100"
                    value={event ? event?.name : ''}
                    onChange={handleChange}
                    required
                    className={requiredFieldErrorStatus?.name ? 'error-border' : ''}
                />
            </Form.Group>
        </>
    );
}
