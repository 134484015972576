import React from 'react';

import Modal from 'react-bootstrap/Modal';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';

export default function ConfirmSaveTemplateModal({ show, name, handleClose }) {

    return (
        <Modal centered animation={false} fullscreen="md-down" show={show} onHide={handleClose} backdrop="static" className='modal--lg'>
            <Modal.Body>
                <div className="modal-body-heading">
                    <h1 className="modal-body-heading-title">{name ? 'Template saved!' : 'Changes saved!'}</h1>
                    {name ? (
                        <p>Your changes have been saved to "<span className='fw-bold'>{name}</span>" template.</p>
                    ) : (
                        <p>Your changes have been saved.</p>
                    )}
                </div>
                <Stack className="btn-group-flex">
                    <Button size="lg" onClick={handleClose}>
                        Close
                    </Button>
                </Stack>
            </Modal.Body>
        </Modal>
    );
}
