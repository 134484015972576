import React from 'react';

import Card from 'react-bootstrap/Card';

export default function NoCodeMsg() {

    return (
        <Card.Title as="h5" className='card-title-sm card-title-thin info-text info-text-md'>No access code will be required by the fans to purchase this offer</Card.Title>
    );
}
