import React, { useState, useEffect } from 'react';

import {
    DndContext,
    DragOverlay,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';

import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';

import { RequiredText } from '../../RequiredText';
import { SortableAttraction } from './SortableAttraction';
import { Attraction } from './Attraction';
import { AddAttractionModal } from './AddAttractionModal';
import { DeleteModal } from '../../DeleteModal';

import './addAttraction.scss';

export default function AddAttraction({ attractions, setAttractions, isDisabled, requiredFieldErrorStatus }) {

    const [activeAttraction, setActiveAttraction] = useState(null)

    const [showCreate, setShowCreate] = useState(false)

    const [showDelete, setShowDelete] = useState(false)

    const [addManually, setAddManually] = useState(false)

    // local state in case attraction is discarded 
    // for editing
    const [attractionLocal, setAttractionLocal] = useState()

    // local state in case attraction image is discarded - need to be seperate state because there is a lot of manipulation on it 
    // for editing
    const [imageLocal, setImageLocal] = useState()

    const [coordinates, setCoordinates] = useState()

    // for editing - save attraction id for flag 
    // for deleting
    const [id, setId] = useState()

    // for editing
    const [initialEditState, setInitialEditState] = useState()

    const [isBtnDisabled, setIsBtnDisabled] = useState(false)

    const [isRemoving, setIsRemoving] = useState(false)

    useEffect(() => {
        // if editing set state
        if (id && attractionLocal) {
            setImageLocal(attractionLocal?.artwork.url)
            setInitialEditState(attractionLocal)
            setCoordinates({ width: attractionLocal.artwork.width, height: attractionLocal.artwork.height })
        }
    }, [id])

    // keep checking properties within initial state object when it changes 
    useEffect(() => {
        setIsBtnDisabled(initialEditState?.name === attractionLocal?.name && initialEditState?.artwork.url === imageLocal || !imageLocal || !attractionLocal?.name)
    }, [initialEditState, imageLocal, attractionLocal?.name])

    const handleShowCreate = (_, attraction) => {
        setShowCreate(true)
        if (attraction) {
            setId(attraction?.id)
            setAttractionLocal(attraction)
            setImageLocal(attraction.artwork.url)
        }
    }

    const handleCloseCreate = () => {
        setAttractionLocal()
        setImageLocal()
        setCoordinates()
        setId()
        setAddManually(false)
        setShowCreate(false)
        setInitialEditState()
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (id) {
            const updatedAttractions = attractions.map(attraction => {
                if (attraction.id === id) {
                    return {
                        ...attraction,
                        name: attractionLocal.name,
                        artwork: {
                            ...attraction.artwork,
                            url: imageLocal,
                            width: coordinates.width,
                            height: coordinates.height
                        }
                    }
                }

                return attraction
            });

            setAttractions(updatedAttractions)
        } else {
            setAttractions([
                ...attractions,
                {
                    id: attractions.length + 1,
                    name: attractionLocal.name,
                    artwork: {
                        url: imageLocal,
                        width: coordinates.width,
                        height: coordinates.height
                    }
                }
            ])
        }
        handleCloseCreate()
    }

    const handleShowDelete = (id) => {
        setShowDelete(true);
        setId(id)
    }

    const handleCloseDelete = () => {
        setShowDelete(false);
        setId()
    }

    // TODO: remove attraction
    const removeAttraction = () => {
        setIsRemoving(true)
        handleCloseDelete()
        setIsRemoving(false)
    }

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const handleDragStart = (e) => {
        setActiveAttraction(attractions.find(attraction => attraction.id == e.active.id))
    }

    const handleDragEnd = (e) => {
        const { active, over } = e;

        if (active.id !== over?.id) {
            setAttractions((attractions) => {
                const oldIndex = attractions.findIndex(attraction => attraction.id === active.id);
                const newIndex = attractions.findIndex(attraction => attraction.id === over.id);
                return arrayMove(attractions, oldIndex, newIndex);
            });
        }

        setActiveAttraction(null)
    }

    return (
        <>
            <div className="card-body-heading card-body-heading--flex">
                <Card.Title as="h5">Add Attraction</Card.Title>
                {requiredFieldErrorStatus?.attractions && (<RequiredText />)}
            </div>
            <Stack direction='horizontal' as="ul" gap={4} className='flex-wrap align-items-stretch'>
                <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragStart={handleDragStart}
                    onDragEnd={handleDragEnd}
                >
                    <SortableContext
                        items={attractions}>

                        {attractions.map((attraction, index, arr) => (
                            <SortableAttraction key={index} attraction={attraction} idx={index} isDraggingDisabled={arr.length < 2} isDisabled={isDisabled} handleShowCreate={handleShowCreate} handleShowDelete={handleShowDelete} />
                        ))}
                    </SortableContext>
                    <DragOverlay style={{ transformOrigin: '0 0 ' }} wrapperElement='ul'>
                        {activeAttraction ? (
                            <Attraction attraction={activeAttraction} />
                        ) : null}
                    </DragOverlay>
                </DndContext>
                {attractions?.length < 9 && (
                    <Button
                        variant="outline-light"
                        className={`btn--square btn-card btn-card--add ${requiredFieldErrorStatus?.attractions ? 'error' : ''}`}
                        onClick={handleShowCreate}
                        disabled={isDisabled}
                    >
                        <div className='btn-container'>
                            <span>Add Attraction</span>
                        </div>
                    </Button>
                )}

            </Stack>

            <AddAttractionModal show={showCreate} handleClose={handleCloseCreate} addManually={addManually} setAddManually={setAddManually} id={id} attraction={attractionLocal} setAttraction={setAttractionLocal} image={imageLocal} setImage={setImageLocal} coordinates={coordinates} setCoordinates={setCoordinates} handleSubmit={handleSubmit} isDisabled={isBtnDisabled} />

            <DeleteModal entity="attraction" show={showDelete} handleClose={handleCloseDelete} id={id} isRemoving={isRemoving} handleDelete={removeAttraction} />
        </>
    );
}
