import React from 'react';

import Form from 'react-bootstrap/Form';

export default function DoorsOpen({ doorsOpenOffset, handleChange }) {

    const doorsOpenOpts = [
        {
            label: '15 minutes prior',
            value: '15 minute'
        },
        {
            label: '30 minutes prior',
            value: '30 minute'
        },
        {
            label: '45 minutes prior',
            value: '45 minute'
        },
        {
            label: '1 hour prior',
            value: '1 hour'
        },
        {
            label: '1 hour & 15 minutes prior',
            value: '1.25 hour'
        },
        {
            label: '1 hour & 30 minutes prior',
            value: '1.5 hour'
        },
        {
            label: '1 hour & 45 minutes prior',
            value: '1.75 hour'
        },
        {
            label: '2 hours prior',
            value: '2 hour'
        },
    ]

    return (
        <>
            <div className="modal-body-heading">
                <h1 className='modal-body-heading-title'>Edit doors time</h1>
            </div>
            <Form>
                <fieldset>
                    <legend className='sm'>
                        <p id="doors-time">Doors time</p>
                        <small className='subtitle'>Set an offset from Event Date/Time</small>
                    </legend>
                    <Form.Select
                        name="unit"
                        value={`${doorsOpenOffset.time} ${doorsOpenOffset.unit}`}
                        onChange={handleChange}
                        aria-labelledby='doors-time'
                        autoFocus
                    >
                        {doorsOpenOpts.map((opt, index) => (
                            <option key={index} value={opt.value}>{opt.label}</option>
                        ))}
                    </Form.Select>
                </fieldset>
            </Form>
        </>
    );
}
