import React from 'react';

import { formatNumber } from '../../../../../../utilities/helpers';

import Card from 'react-bootstrap/Card';
import Stack from 'react-bootstrap/Stack';

export default function InventoryCard({ type, name, inventory }) {

    const getText = () => {
        const numSeats = inventory?.seats?.length
        return `${numSeats > 0 ? `${formatNumber(numSeats)} ${numSeats === 1 ? 'seat' : 'seats'}` : 'No seats assigned'}`
    }
    return (
        <>
            <Card body className="card--sm card-with-border">
                <div className="card-body-heading card-body-heading--sm">
                    <Card.Title as="h5" className='card-title-xs card-title-thin'>{type === 'open' ? 'All offers using Open will share the same set of seats' : 'This offer will exclusively sell the seats assigned to this inventory set'}</Card.Title>
                </div>
                <Stack direction='horizontal' className='heading--flex-space-between'>
                    <Stack direction="horizontal" className='flex-sm'>
                        <div className='dot align-self-start' style={{
                            backgroundColor: inventory?.color
                        }}>
                        </div>
                        <Stack>
                            <span className="item-name">{`${name || '(Name needs to be assigned)'}`}</span>
                            <span className='subtitle'>{getText()}</span>
                        </Stack>
                    </Stack>
                </Stack>
            </Card>
        </>
    );
}
