import { useParams } from 'react-router-dom';

import { DashboardWrapper } from "../../components";

export default function DashboardPage() {

    let { uuid } = useParams();

    return (
        <DashboardWrapper eventId={uuid} />
    )
}