import React from 'react'

import { useDraggable } from '@dnd-kit/core'

import { formatNumber } from '../../../../../../../utilities/helpers'

import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'

import { SeatIcon } from '../SeatIcon'

// draggable component 
export default function DraggableSeats({ seats, handleRemove }) {

    const { isDragging, attributes, listeners, setNodeRef } = useDraggable({
        id: '0'
    });

    const dragCursor = isDragging ? 'grabbing' : 'grab'

    const style = {
        cursor: dragCursor
    }

    return (
        <li ref={setNodeRef} style={style} {...listeners} {...attributes}>
            <Card body className='card--sm card-with-border' >
                <div className="card-body-heading--flex-space-between">
                    <div className="heading--flex gap-2">
                        <SeatIcon />
                        <Card.Title as="h5" className='fw-normal card-title-xs'><span className="text-upper">{formatNumber(seats.length)}</span> {seats.length > 1 ? 'Seats' : 'Seat'}
                        </Card.Title>
                    </div>
                    <Button variant='link' className='btn-link--thin caption text-danger' onClick={handleRemove}>Clear</Button>
                </div>
            </Card>
        </li>
    )
}