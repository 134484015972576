import React, { useEffect, useLayoutEffect, useState } from 'react'

import { DndContext, useSensor, useSensors, MouseSensor } from '@dnd-kit/core';

import Button from 'react-bootstrap/Button'
import Collapse from 'react-bootstrap/Collapse'

import { ScalingMenu } from './ScalingMenu'
import { FinancialInfo } from './FinancialInfo';
import { InventoryMenu } from './InventoryMenu'
import { Panel } from './Panel'
import { SelectGASeatsModal } from './SelectGASeatsModal';
import { MoveSeatsModal } from './MoveSeatsModal';
import { DeleteModal } from './DeleteModal';

import './sidebar.scss'

export default function Sidebar({ event, tab, unassignedSeats, setUnassignedSeats, offers, setOffers, selectedSeats, setSelectedSeats, priceLevels, setPriceLevels, holds, setHolds, kills, setKills, sold, totalSeats, totalGASeats, isGASection, setIsGASection, showSelectGASeats, setShowSelectGASeats, isEventOnsale, assignSeatsTo, removeSeatsFrom, deselectSeats, selectUnassigned, getOpenInventory, handleAdd }) {

    const [open, setOpen] = useState(false)

    // id that is dragged to - accesses the object in dragTo
    const [dragToId, setDragToId] = useState(null);

    // add seats to: price levels, holds, kills, open
    // can only be one object - object of objects
    const [dragTo, setDragTo] = useState({});

    // seats to move - not using except for flag for drag overlay
    const [activeDragId, setActiveDragId] = useState(null);

    // ids that is moved from - accesses the object in moveFrom  multiple
    const [moveFromIds, setMoveFromIds] = useState([]);

    // remove seats from: price levels, holds, kills, open - multiple
    // array of objects
    const [moveFrom, setMoveFrom] = useState([]);

    // id to remove
    const [removeId, setRemoveId] = useState()

    // data to remove from - can only be one object 
    const [removeFrom, setRemoveFrom] = useState({})

    const [show, setShow] = useState(false)

    // GA seat count in GA popup
    const [gaSeatCount, setGASeatCount] = useState(0)

    // ids of holds/kills/offers that have GA seats assigned to them 
    const [holdsKillsOffersWithGASeatIds, setHoldsKillsOffersWithGASeatIds] = useState([])

    // object array of holds/kills/offers that have GA seats assigned to them 
    // can be multiple objects - array of objects 
    const [holdsKillsOffersWithGASeats, setHoldsKillsOffersWithGASeats] = useState([])

    // array of the number of ga seats for each hold/kill/offer that have ga seats 
    const [gaSeatsPerHoldKillOffer, setGASeatsPerHoldKillOffer] = useState([])

    const [gaSeatsPerHoldKillOfferInitialState, setGASeatsPerHoldKillOfferInitialState] = useState()

    const [hasGAError, setHasGAError] = useState(false)

    const [showDelete, setShowDelete] = useState(false)

    useLayoutEffect(() => {
        // if inventory 
        if (tab === 'inventory') {
            // if ga section is selected   
            if (isGASection) {
                // get hold/kills/sub items/offers with ga seats assigned 
                let foundKeys = [];
                const allFoundKeys = findSeatsAssigned(totalGASeats)
                foundKeys = allFoundKeys
                const holdsKillsOffersWithGASeatIds = foundKeys;
                setHoldsKillsOffersWithGASeatIds(holdsKillsOffersWithGASeatIds)
                const holdsKillsOffersWithGASeats = getFromObjs(holdsKillsOffersWithGASeatIds)
                setHoldsKillsOffersWithGASeats(holdsKillsOffersWithGASeats)

                // if parent keys are found, filter out their sub category ga seats to find how many ga seats they have 
                const getParentGASeats = (id, obj) => {
                    const parentObj = getObj(id, obj)

                    if (parentObj?.categories) {
                        const allSubSeats = Object.values(parentObj.categories)
                            .flatMap(cat => cat?.seats?.filter(seat => seat.startsWith('ga')) || []);

                        // get all parent ga seats not in sub seats 
                        const gaOnlySeats = parentObj?.seats?.filter(seat => seat.startsWith('ga'))
                        return gaOnlySeats?.filter(seat => !allSubSeats?.includes(seat))?.length
                    }
                }

                // set number of ga seats assigned to each hold/kill
                // if parent key - get ga seats belonging to parent 
                // else sub category - get ga seats belonging to sub category 
                const gaSeatsPerHoldKillOffer = holdsKillsOffersWithGASeatIds?.map((id, idx) => (isHoldOrKill(id) && isSubOrParent(id, 2)) ? getParentGASeats(id, holdsKillsOffersWithGASeats[idx]) : getObj(id, holdsKillsOffersWithGASeats[idx])?.seats?.filter(seat => seat.startsWith('ga'))?.length)

                setGASeatsPerHoldKillOffer(gaSeatsPerHoldKillOffer)
                setGASeatsPerHoldKillOfferInitialState(gaSeatsPerHoldKillOffer)

                // show select GA seats popup 
                setShowSelectGASeats(true)
            }
        }
    }, [isGASection])

    // reset error when values change
    useEffect(() => {
        if (hasGAError) setHasGAError(false)
    }, [gaSeatsPerHoldKillOffer, gaSeatCount])

    useEffect(() => {
        const seatsToUnselect = selectedSeats;
        // Clear seat.selected on map
        deselectSeats(seatsToUnselect);
        // Clear selectedSeats
        setSelectedSeats([]);
    }, [tab])

    const handleChange = (e, index) => {
        const val = e.target.value;
        setGASeatsPerHoldKillOffer(prevState => {
            const updatedArray = [...prevState];
            updatedArray[index] = val;
            return updatedArray;
        });
    }

    const handleCount = (e) => {
        setGASeatCount(e.target.value)
    }

    const sensors = useSensors(
        useSensor(MouseSensor, {
            activationConstraint: {
                distance: 8,
            },
        })
    )

    const handleDragStart = (e) => {
        const { id } = e.active;

        setActiveDragId(id)

        // if on inventory tab and ga section is selected the move from id will always be the open offer 
        // else find all keys 
        // is used to display the move froms in the move popup
        const moveFromIds = (isGASection && tab === 'inventory') ? [`o-${Object.keys(offers)[0]}`] : findSeatsAssigned()
        setMoveFromIds(moveFromIds)
        setMoveFrom(getFromObjs(moveFromIds))
    }

    const handleDragEnd = (e) => {
        const { over } = e;

        if (over) {
            if (over?.id !== null) {
                handleShow()
                // set id that is dragged to - can be 0
                setDragToId(over.id)
            }
            // set dragTo after dropping seats from the moveTo popup AFTER dropping
            if (over.data.current.data) {
                setDragTo(over.data.current.data)
            }

            setActiveDragId(null)
            setIsGASection(false)
        }
    }

    // checks if id is hold/kill
    const isHoldOrKill = (id) => {
        if (isStringId(id)) {
            const char = id.toString().split('-')[0]
            return char == 'h' || char == 'k'
        }
    }

    // whether hold/kill and dragTo and move from are from SAME hold/kill
    const isSameHoldOrKill = (id, moveFromId) => {
        if (isHoldOrKill(id) && isHoldOrKill(moveFromId)) {
            return getParentPart(id) === getParentPart(moveFromId)
        } else {
            return false
        }
    }

    // if id is string with hyphens 
    const isStringId = (id) => {
        return id && id !== -1 && id.toString().indexOf('-') !== -1
    }

    // check if hold or kill id is parent or subcategory - id is defaulted to sub category
    const isSubOrParent = (id, length = 3) => {
        return id.split('-').length === length
    }

    // get parent part of id - hold/kill
    const getParentPart = (id) => {
        return id.toString().slice(0, 3)
    }

    const getId = (id, pos = 1) => {
        // if id is a string: holds, kills, open
        if (isStringId(id)) {
            // get a portion of id - default is parent index
            const el = id?.split('-')[pos]
            // if el is a character or digit or undefined - not hold/kill
            if (isNaN(el)) {
                return el;
            } else {
                return parseInt(el);
            }
        } else return id
    }

    // find object to add to price level, holds, kills, unassigned, open seats
    const getObj = (id, data) => {
        if (data && id && id !== -1) {
            // id is hold/kill
            if (isHoldOrKill(id)) {
                // get parent object 
                let parentObj = data[getId(id)]
                if (isSubOrParent(id)) {
                    // this means that id is an array inside array - hold/kill
                    // return category obj 
                    return parentObj.categories[getId(id, 2)]
                    // not nested array
                } else {
                    return data[getId(id)]

                }
            } else {
                // return price level/open/unassigned 
                return data[getId(id)]
            }
        }
    }

    const handleShow = () => setShow(true)

    const handleShowDelete = () => setShowDelete(true)

    // move seats popup
    const handleClose = () => {
        setShow(false)
        setDragToId(null)
        setDragTo({})
        setMoveFromIds([])
        setMoveFrom([])
        setShowDelete(false)
        setRemoveId();
        setRemoveFrom({})
    }

    // select seats from ga section popup 
    const handleSubmitSelectGASeats = (e) => {
        e.preventDefault();
        // if values exceed total ga seats, don't submit  
        const total = gaSeatsPerHoldKillOffer.reduce((acc, curr) => acc + parseInt(curr), 0)
        if (total + parseInt(gaSeatCount) > totalGASeats.length) {
            setHasGAError(true)
            return;
        }

        const addRemoveGASeats = (seats, gaSeatsToAdd, numGASeatsToRemove) => {
            const newSeats = [...seats]; // Create a new array to avoid modifying the original array

            if (gaSeatsToAdd.length > 0) {
                // Add GA seats to the newSeats
                for (let i = 0; i < gaSeatsToAdd.length; i++) {
                    newSeats.push(gaSeatsToAdd[i]);
                }
            }

            if (numGASeatsToRemove) {
                // Remove the specified number of GA seats from the newSeats - remove ga seats that come first 
                for (let i = 0; i < numGASeatsToRemove; i++) {
                    const indexToRemove = newSeats.findIndex(seat => seat.startsWith('ga'));
                    if (indexToRemove !== -1) {
                        newSeats.splice(indexToRemove, 1); // Remove the ga seat
                    }
                }
            }
            return newSeats;
        }

        // don't add duplicate ga seats 
        const getGASeatsToAdd = (openOfferSeats, count, addToHoldKillOffer = false, prevAlreadyAssignedGASeatsCount) => {
            let gaSeatsToAdd = [];
            const gaOnlySeats = openOfferSeats.filter(seat => seat.startsWith('ga'))
            // adding to hold/kill/offer 
            if (addToHoldKillOffer) {
                // get the next ga seat in open that has not already been added - not to add the same ga seat to multiple objects - these have to be different
                gaSeatsToAdd.push(...gaOnlySeats.slice(prevAlreadyAssignedGASeatsCount, prevAlreadyAssignedGASeatsCount + count))
                // adding to open 
            } else {
                // add new ga seats that are not in open seats - important when adding seats back to open seats to not get duplicates 
                const availableGASeats = totalGASeats.filter(seat => !gaOnlySeats.includes(seat))
                gaSeatsToAdd.push(...availableGASeats.slice(0, count))

            }
            return gaSeatsToAdd
        }

        let alreadyAssignedGASeatsCount = 0;
        // track how many ga seats added to holds/kills/offers to not add duplicate ga seats when adding  
        let prevAlreadyAssignedGASeatsCount = alreadyAssignedGASeatsCount

        handleCloseSelectGASeats()
        if (holdsKillsOffersWithGASeats.length > 0) {
            // get data about holds/kills/offers with changed ga seats 
            const getHoldsKillsOffersDataWithChangedGASeats = (data) => {
                return gaSeatsPerHoldKillOffer.map((currSeats, idx) => {
                    if (currSeats == gaSeatsPerHoldKillOfferInitialState[idx]) {
                        return
                    }
                    return data[idx]
                }).filter(id => id !== undefined)
            }

            // only get the ids that have changed ga seats 
            const holdsKillsOffersWithChangedGASeatIds = getHoldsKillsOffersDataWithChangedGASeats(holdsKillsOffersWithGASeatIds)

            // get the objects that have changed ga seats 
            const holdsKillsOffersWithChangedGASeats = gaSeatsPerHoldKillOffer.map((currSeats, idx) => {
                if (currSeats == gaSeatsPerHoldKillOfferInitialState[idx]) {
                    return
                }
                return getObj(holdsKillsOffersWithGASeatIds[idx], holdsKillsOffersWithGASeats[idx])
            }).filter(id => id !== undefined)

            // for each hold/kill/offer update with new seats including ga seats 
            if (holdsKillsOffersWithChangedGASeats.length > 0) {
                const openOffer = Object.values(offers)[0]

                // update open seats - get the number of seats to add and remove all at once 

                // if hold/kill/offer num ga seats has decreased from initial state, add that amount of ga seats back to open 
                let addSeats = gaSeatsPerHoldKillOffer.some((num, idx) => num < gaSeatsPerHoldKillOfferInitialState[idx])
                // if any hold/kill/offer num ga seats has increased from initial state, remove that amount of ga seats from open
                let removeSeats = gaSeatsPerHoldKillOffer.some((num, idx) => num > gaSeatsPerHoldKillOfferInitialState[idx])
                // get leftover ga seats in each hold/kill/offer (seats to add or remove)
                const leftoverInEach = gaSeatsPerHoldKillOffer.map((num, idx) => num - gaSeatsPerHoldKillOfferInitialState[idx])
                // get total of leftover ga seats that are greater than 0 - removing seats  
                const initialNumSeatsToRemove = leftoverInEach.reduce((acc, curr) => curr > 0 ? acc + curr : acc, 0)
                // get total of leftover ga seats that are less than 0 - adding seats 
                const initialNumSeatsToAdd = leftoverInEach.reduce((acc, curr) => curr < 0 ? acc + Math.abs(curr) : acc, 0)
                let numSeatsToAdd = initialNumSeatsToAdd;
                let numSeatsToRemove = initialNumSeatsToRemove;

                // if adding or removing seats, update openOffer 
                if (addSeats || removeSeats) {
                    const newOpenSeats = addRemoveGASeats(openOffer?.seats, addSeats ? getGASeatsToAdd(openOffer?.seats, numSeatsToAdd) : [], numSeatsToRemove);
                    setOffers(prevState => updateObj(prevState, undefined, openOffer?.id, openOffer, undefined, newOpenSeats))
                }

                // update holds/kills 

                const updatedSeats = holdsKillsOffersWithChangedGASeats.map((obj, idx) => {
                    let newCount = getHoldsKillsOffersDataWithChangedGASeats(gaSeatsPerHoldKillOffer)[idx];
                    let prevCount = getHoldsKillsOffersDataWithChangedGASeats(gaSeatsPerHoldKillOfferInitialState)[idx];
                    let newSeats;

                    // if seats have increased, add seats 
                    const addSeats = newCount > prevCount
                    // get difference  
                    const seatCount = newCount - prevCount;
                    // if ga seats were increased on other holds/kills, don't select those seats from all the ga selected seats
                    alreadyAssignedGASeatsCount += (seatCount > 0 ? seatCount : 0);
                    newSeats = addRemoveGASeats(obj.seats, addSeats ? getGASeatsToAdd(openOffer?.seats, seatCount, true, prevAlreadyAssignedGASeatsCount) : [], !addSeats ? Math.abs(seatCount) : undefined)

                    // get the previous value of alreadyAssignedGASeatsCount 
                    if (addSeats) {
                        let alreadyAssignedGASeatsCountCopy = alreadyAssignedGASeatsCount;
                        prevAlreadyAssignedGASeatsCount = alreadyAssignedGASeatsCountCopy
                    }

                    return newSeats;
                })

                // update real state - holds/kills/offers
                const updateHoldsKillsOffers = (state, id, updatedSeats, gaSeatsPer, gaSeatsPerInitialState) => {
                    // add seats if sub seats have increased 
                    const addParentSeats = (isHoldOrKill(id) && isSubOrParent(id) && gaSeatsPer > gaSeatsPerInitialState)

                    // remove seats if sub seats have decreased 
                    const removeParentSeats = (isHoldOrKill(id) && isSubOrParent(id) && gaSeatsPer < gaSeatsPerInitialState)

                    // get new parent seats 
                    const getNewParentSeats = (id, state, updatedSeats, isAdding) => {
                        const parentObj = getObj(getParentPart(id), state)
                        if (isAdding) {
                            // if adding seats to parent, add sub seats that are not already in parent - there may be sub seats already in parent seats - add sub seats at end of parent 
                            const subSeats = updatedSeats?.filter(seat => !parentObj?.seats?.includes(seat))
                            return [...parentObj?.seats, ...subSeats]

                        } else {
                            // if removing seats from parent, remove all sub seats from parent seats and add sub category's updated seats - add sub seats at end of parent 
                            const subCategoryObj = getObj(id, state)
                            const parentSeatsWithoutSubSeats = parentObj?.seats?.filter(seat => !subCategoryObj?.seats?.includes(seat))
                            return [...parentSeatsWithoutSubSeats, ...updatedSeats]
                        }

                    }
                    return updateObj(state, undefined, id, state, undefined, updatedSeats, (isHoldOrKill(id) && isSubOrParent(id)), addParentSeats, removeParentSeats, (addParentSeats || removeParentSeats) ? getNewParentSeats(id, state, updatedSeats, addParentSeats) : undefined)
                }

                // get the ga seats per hold or kill that have changed from its initial state 
                const gaSeatsPerChangedHoldKillOffer = gaSeatsPerHoldKillOffer?.map((num, idx) => {
                    if (gaSeatsPerHoldKillOfferInitialState[idx] !== num) {
                        return { index: idx, num: num };
                    }
                    // If no change, return null or undefined
                    return null;
                }).filter(item => item !== null && item !== undefined);

                holdsKillsOffersWithChangedGASeatIds.map((id, idx) => {
                    const char = getId(id, 0)

                    // get the new and initial ga seats to find whether to add or remove from parent holds/kills
                    // get the number of ga seats from the changed hold/kill/offer 
                    const gaSeats = Object.values(gaSeatsPerChangedHoldKillOffer)[idx]?.num
                    // get the initial state number of ga seats from the changed hold/kill/offer 
                    const initialGaSeats = gaSeatsPerHoldKillOfferInitialState[Object.values(gaSeatsPerChangedHoldKillOffer)[idx]?.index]

                    switch (char) {
                        case 'h':
                            setHolds(prevState => (updateHoldsKillsOffers(prevState, id, updatedSeats[idx], gaSeats, initialGaSeats)))
                            break;

                        case 'k':
                            setKills(prevState => (updateHoldsKillsOffers(prevState, id, updatedSeats[idx], gaSeats, initialGaSeats)))
                            break;

                        case 'o':
                            setOffers(prevState => (updateHoldsKillsOffers(prevState, id, updatedSeats[idx])))
                            break;

                        default:
                            break;
                    }
                });
            }
        }
        // end starts at 0 index when slicing
        const getEnd = () => {
            let end;
            if (alreadyAssignedGASeatsCount > 0) {
                // already assigned is greater, assign end to already assigned and add seat count
                if (alreadyAssignedGASeatsCount > gaSeatCount) {
                    end = alreadyAssignedGASeatsCount + parseInt(gaSeatCount)
                    // values are equal or seat count is greater than already assigned, add already assigned num to end 
                } else {
                    end = parseInt(gaSeatCount) + alreadyAssignedGASeatsCount
                }
            } else {
                end = parseInt(gaSeatCount)
            }
            return end;
        }
        // get ga seats - take from open offer
        const onlyGASeats = Object.values(offers)[0]?.seats?.filter(seat => seat.startsWith('ga'))
        // get ga seats to add 
        // start at num of seats that are added to other hold/kills to not select those ones which will be removed from openOffer 
        const selectedGASeats = onlyGASeats.slice(alreadyAssignedGASeatsCount, getEnd())
        // add selected ga seats to selected non ga seats 
        setSelectedSeats([...selectedSeats, ...selectedGASeats])
    }

    const handleCloseSelectGASeats = () => {
        setShowSelectGASeats(false)
        setGASeatCount(0)
        setHoldsKillsOffersWithGASeatIds([])
        setHoldsKillsOffersWithGASeats([])
        setGASeatsPerHoldKillOffer([])
        setGASeatsPerHoldKillOfferInitialState()
    }

    const handleCancelSelectGASeats = () => {
        handleRemoveSeats()
        handleCloseSelectGASeats()
        setIsGASection(false)
    }

    // removing or adding seats 
    const getSeats = () => {
        // adding seats 
        if (!removeId) {
            return selectedSeats
        } else {
            // removing seats - get removed seats 
            return getObj(removeId, removeFrom).seats
        }
    }

    const filterSeats = (obj) => {
        return obj?.seats.filter(seat => !getSeats().includes(seat))
    }

    const getUpdatedObj = (obj, seats, newSeats) => {
        // Retrieve the dragToObj from the dragTo array
        const copyObj = obj;
        // Create a new array by spreading the existing seats array from dragToObj
        // and concatenating it with added seats
        let updatedSeats;
        if (seats) {
            updatedSeats = [...copyObj.seats, ...seats];
        } else {
            updatedSeats = newSeats
        }
        // Create a new object by spreading the properties from dragToObj
        // and updating the seats property with the updatedSeats array
        const updatedObj = { ...copyObj, seats: updatedSeats };
        return updatedObj;
    }

    const updateObj = (state, dragToId, moveFromId, moveFromObj, seats, newSeats, isSubItem = false, addParentSeats = false, removeParentSeats = false, newParentSeats) => {
        const id = dragToId || moveFromId
        const index = getId(id)
        const obj = dragToId ? getObj(dragToId, dragTo) : getObj(moveFromId, moveFromObj)
        if (!isSubItem) {
            return {
                ...state,
                [index]: {
                    ...state[index],
                    ...getUpdatedObj(obj, seats, newSeats)
                }
            }
        }
        else {
            // sub hold/kill
            const subIndex = getId(id, 2)

            // Shared method to update the state
            const updateHoldKillState = () => {
                // Common operations for updating state - updating sub item 
                const updatedState = {
                    ...state,
                    [index]: {
                        ...state[index],
                        categories: {
                            ...state[index].categories,
                            [subIndex]: {
                                ...state[index].categories[subIndex],
                                ...getUpdatedObj(obj, seats, newSeats)
                            }
                        }
                    }
                };

                // update parent hold/kill as well
                if (removeParentSeats || addParentSeats) {
                    updatedState[index] = {
                        ...updatedState[index],
                        ...getUpdatedObj(updatedState[index], undefined, newParentSeats)
                    };
                }

                return updatedState;
            }
            return updateHoldKillState()
        }
    }

    // move selected seats to price level, holds or kills  
    const handleSubmit = () => {
        const updateState = (dragToId, moveFromId, moveFromObj, seats, newSeats, addSeatsToParent, removeParentSeats = false, newParentSeats) => {
            // look at id and see what state to update 
            const id = dragToId || moveFromId;
            const obj = dragToId ? dragTo : moveFromObj
            let assignTo;
            if (isStringId(id)) {
                const char = getId(id, 0)

                const updateHoldsKills = (state) => {
                    return updateObj(state, dragToId, moveFromId, moveFromObj, seats, newSeats, (isHoldOrKill(id) && isSubOrParent(id)), addSeatsToParent, removeParentSeats, newParentSeats)
                }

                switch (char) {
                    case 'h':
                        assignTo = 'hold'
                        // set hold object with seats 
                        setHolds(prevState => (updateHoldsKills(prevState)))
                        break;

                    case 'k':
                        assignTo = 'kill'

                        // set price level object with seats 
                        setKills(prevState => (updateHoldsKills(prevState)))
                        break;

                    case 'o':
                        assignTo = 'offer'
                        // set seats or remove seats from open seats object 
                        // removing hold/kill - add seats 
                        // dragging seats to - add seats 
                        // not removing - remove seats 
                        // moving seats from - remove seats
                        setOffers(prevState => (updateObj(prevState, dragToId, moveFromId, moveFromObj, removeId || dragToId ? getSeats() : undefined, !removeId && moveFromId ? filterSeats(getObj(id, obj)) : undefined)))
                        break;

                    default:
                        return;
                }
            } else {
                // if not removing, assign seats 
                if (!removeId) {
                    assignTo = 'pl'

                    // set price level object with seats 
                    setPriceLevels(prevState => (updateObj(prevState, dragToId, moveFromId, moveFromObj, seats, newSeats)))
                }
            }
            // Assign seats to seatmap - always change seats on dragTo obj
            if (dragToId) {
                assignSeatsTo(getObj(dragToId, dragTo), getSeats(), assignTo)
            };
        }

        // adding seats

        const checkAddToParent = (dragToId) => {
            // if moving - holds/kills
            if (moveFromIds.length > 0) {
                // assign seats to parent: if hold/kill is sub item and hold/kill parent and sub category are NOT a part of the same hold/kill
                // Check if any of the moveFromIds satisfy the condition
                return moveFromIds.some(id => isHoldOrKill(dragToId) && isSubOrParent(dragToId) && !isSameHoldOrKill(dragToId, id));
            } else return false
        };

        const addSeatsToParent = checkAddToParent(dragToId)

        const getNewParentSeats = () => {
            const parentObj = getObj(getParentPart(dragToId), dragTo)
            // filter seats from selectedSeats that are not already in parent 
            const filteredSeats = getSeats().filter(seat => !parentObj?.seats.includes(seat))
            // add filteredSeats to existing seats 
            return [...parentObj.seats, ...filteredSeats]
        }
        updateState(dragToId, undefined, undefined, getSeats(), undefined, addSeatsToParent, undefined, addSeatsToParent ? getNewParentSeats() : undefined)

        // is not moving or deleting
        if (moveFromIds.length === 0 || removeId) {

            const unassignedObj = Object.values(unassignedSeats)[0]
            if (tab === "scaling") {
                const updatedUnassignedSeats = getUpdatedObj(unassignedObj, removeId ? getSeats() : undefined, !removeId ? filterSeats(unassignedObj) : undefined)
                setUnassignedSeats(prevState => ({
                    ...prevState,
                    [unassignedObj?.id]: {
                        ...prevState[unassignedObj?.id],
                        ...updatedUnassignedSeats
                    }
                }))

                const holdsSeats = Object.values(holds)?.flatMap(hold => hold.seats)
                const killsSeats = Object.values(kills)?.flatMap(kill => kill.seats)
                const exclusiveOffersSeats = Object.values(offers).slice(1)?.flatMap(offer => offer.seats)
                const openOfferSeats = getOpenInventory(totalSeats, updatedUnassignedSeats?.seats, [...holdsSeats, ...killsSeats, ...exclusiveOffersSeats], event)
                // filter open offer seats to not include unassigned seats 
                const openOfferObj = Object.values(offers)[0]
                setOffers(prevState => ({
                    ...prevState,
                    [openOfferObj?.id]: {
                        ...prevState[openOfferObj?.id],
                        ...getUpdatedObj(openOfferObj, undefined, openOfferSeats)
                    }
                }))
            }
        }
        // moving seats - moveFromIds or not deleting 
        else {
            let removeSeats = moveFromIds.length > 0;
            let removeParentSeats = false;

            // loop through all the moveFromIds
            moveFromIds.map((id, idx, arr) => {
                // filter new seats 
                const newSeats = filterSeats(getObj(id, moveFrom[idx]))
                // filter parent hold/kill seats 
                const newParentSeats = filterSeats(getObj(getParentPart(id), moveFrom[idx]))

                if (isHoldOrKill(id)) {
                    // only remove seats from object if ids are both subcategories 
                    // or if ids are not the same hold/kill
                    removeSeats = (isHoldOrKill(dragToId) && isSubOrParent(dragToId) && isSubOrParent(id)) || !isSameHoldOrKill(dragToId, id);

                    // whether prev moveFromId and current one are NOT from the SAME hold/kill (sub category or parent) to avoid removing same parent seats again 
                    const checkIsAlreadyRemoved = () => {
                        // if id before and current id are NOT from same hold or kill - haven't already removed parent seats
                        if (arr[idx - 1]) {
                            const prevIndex = arr[idx - 1];
                            return !isSameHoldOrKill(prevIndex, id)
                        } else {
                            return true
                        }

                    }

                    // remove parent seats if subcategory and ids are NOT the same hold/kills
                    removeParentSeats = checkIsAlreadyRemoved() && isSubOrParent(id) && !isSameHoldOrKill(dragToId, id)
                }

                if (removeSeats) {
                    // remove selected seats from object 
                    updateState(undefined, id, moveFrom[idx], undefined, newSeats, undefined, removeParentSeats, removeParentSeats ? newParentSeats : undefined)
                }
            })
        }

        if (removeId) {
            handleDelete(removeFrom, removeId)
        }
        setIsGASection(false)
        handleClose();
        handleRemoveSeats()
    }

    const handleDelete = (data, id) => {
        let index = getId(id);
        let parentIndex;
        ;
        let obj = data;
        if (isHoldOrKill(id)) {
            parentIndex = index;
            // if sub item
            if (isSubOrParent(id)) {
                index = getId(id, 2)
                obj = data[parentIndex].categories
            }
        }
        // Create a new object with the property to remove excluded
        const updatedState = Object.fromEntries(
            Object.entries(obj).filter(([key]) => key != index)
        );

        let removeFrom;
        if (isHoldOrKill(id)) {
            const char = getId(id, 0)

            const updateHoldsKills = (id, data, state, updatedState) => {
                if (isSubOrParent(id)) {
                    const parentId = getParentPart(id)

                    return {
                        ...state,
                        [parentIndex]: {
                            ...state[parentIndex],
                            ...getUpdatedObj(getObj(parentId, data), undefined, filterSeats(getObj(parentId, data))),
                            categories: updatedState
                        }
                    }
                } else {
                    return updatedState
                }
            }
            switch (char) {
                case 'h':
                    setHolds(prevState => (updateHoldsKills(id, data, prevState, updatedState)))
                    removeFrom = 'hold'
                    break;

                case 'k':
                    setKills(prevState => (updateHoldsKills(id, data, prevState, updatedState)))
                    removeFrom = 'kill'
                    break;
                default:
                    break;
            }
        } else {
            // set price level object with seats 
            setPriceLevels(updatedState)

            // remove seats from seatmap
            removeFrom = 'pl'
        }
        // remove seats on seatmap
        removeSeatsFrom(getSeats(), removeFrom)
        handleClose()
    }

    // remove selected seats from array
    const handleRemoveSeats = () => {
        deselectSeats(selectedSeats);
        setSelectedSeats([]);
        if (isGASection) setIsGASection(false)
    }

    // Find the key (id) 
    const findAssignedKeys = (data, seats) => {
        const foundKeys = Object.keys(data).filter((key) =>
            data[key].seats.some((seat) => seats.includes(seat))
        );

        return foundKeys
    }

    // sets the moveFromIds for the moveTo popup and the inventory that are assigned ga seats for the ga popup 
    // control which droppable seats can be dragged to
    // seatsToCheck - can be selectedSeats or totalGASeats 
    const findSeatsAssigned = (seatsToCheck = selectedSeats) => {
        // get all seats of all hold/kill sub categories 
        const getAllCategorySeats = (parentKeys, arr) => {
            // get all seats of each moving parent subitems and combine each seat array into one 
            return parentKeys.flatMap(parentKey => {
                return Object.values(arr[parentKey].categories).reduce((acc, cat) => acc.concat([...cat.seats]), []);
            })
        }

        // find sub category keys of holds/kills
        const getCategoryKeys = (cursor, parentKey, char) => {
            let foundKeys = [];

            // if has categories
            if (cursor && Object.keys(cursor.categories).length > 0) {
                // find which category 
                let foundCategoryKeys = findAssignedKeys(cursor.categories, seatsToCheck)
                // if found in category 
                if (foundCategoryKeys.length > 0) {
                    foundCategoryKeys.forEach(key => {
                        foundKeys.push(`${char}-${parentKey}-${key}`);
                    });
                }
            }
            return foundKeys
        }

        // return index that seats are already assigned to 
        if (tab === "scaling") {
            // seats are in pricing level
            return findAssignedKeys(priceLevels, seatsToCheck)
        }
        // holds/kills/offers
        else {
            let foundKeys = [];

            // get keys
            // seats are in holds 
            const foundParentHoldKeys = findAssignedKeys(holds, seatsToCheck)
            let foundSubHoldKeys = [] // all sub keys for all holds
            let allParentSeats = []
            let cursor;
            // if seats are found in holds, check its sub categories
            if (foundParentHoldKeys.length > 0) {

                foundParentHoldKeys.map(parentKey => {
                    cursor = holds[parentKey]
                    // get all parent seats 
                    allParentSeats = [...allParentSeats, ...cursor.seats]
                    foundSubHoldKeys = getCategoryKeys(cursor, parentKey, 'h')
                    // if found add sub category id or parent id
                    if (foundSubHoldKeys.length > 0) {
                        foundKeys = [...foundKeys, ...foundSubHoldKeys]
                    } else {
                        foundKeys = [...foundKeys, `h-${parentKey}`]
                    }
                })
            }

            // seats are in kills 
            const foundParentKillKeys = findAssignedKeys(kills, seatsToCheck)
            let foundSubKillKeys = [];
            // if seats are found in kills, check its sub categories
            if (foundParentKillKeys.length > 0) {
                foundParentKillKeys.map(parentKey => {
                    cursor = kills[parentKey]
                    allParentSeats = [...allParentSeats, ...cursor.seats]
                    foundSubKillKeys = getCategoryKeys(cursor, parentKey, 'k')
                    // if found add sub category id or parent id
                    if (foundSubKillKeys.length > 0) {
                        foundKeys = [...foundKeys, ...foundSubKillKeys]
                    } else {
                        foundKeys = [...foundKeys, `k-${parentKey}`]
                    }
                })
            }

            // check whether should select parent if sub items are found
            if (foundKeys.length > 0) {
                let allHoldsCategorySeats = [];
                let allKillsCategorySeats = [];
                // get sub keys from foundKeys array since foundSubHoldKeys/foundSubKillKeys change with every parent hold/kill found - some parents may not have sub categories 
                const foundSubHoldKeys = foundKeys?.filter(key => getId(key, 0) === 'h' && isSubOrParent(key))
                const foundSubKillKeys = foundKeys?.filter(key => getId(key, 0) === 'k' && isSubOrParent(key))
                if (foundSubHoldKeys.length > 0 || foundSubKillKeys.length > 0) {
                    // if sub holds 
                    if (foundSubHoldKeys.length > 0) {
                        // get subitems
                        allHoldsCategorySeats = getAllCategorySeats(foundParentHoldKeys, holds)
                    }

                    // if sub kills
                    if (foundSubKillKeys.length > 0) {
                        // get subitems
                        allKillsCategorySeats = getAllCategorySeats(foundParentKillKeys, kills)
                    }

                    // has seats assigned to only parent if not all seats are found in all subcategories hold/kill
                    const totalSubSeats = [...allHoldsCategorySeats, ...allKillsCategorySeats]
                    // if ga section, filter found parent holds/kills - only ga seats to not count non ga seats 
                    // if not, filter selectedSeats or the hold/kill to remove 
                    const seatsToFilter = isGASection ? allParentSeats?.filter(seat => seat.startsWith('ga')) : getSeats()
                    const leftoverSeats = seatsToFilter.filter(seat => !totalSubSeats.includes(seat))
                    const hasParentSeats = (parentKey, arr) => {
                        return leftoverSeats.some(seat => arr[parentKey].seats.includes(seat))
                    }
                    // if there are leftover seats - parent seats 
                    // have to get all moving parent items and see which one/if any the seats belong to 
                    if (leftoverSeats.length > 0) {
                        if (allHoldsCategorySeats.length > 0) {
                            // check if leftover seats belong to parent holds
                            foundParentHoldKeys.map(parentKey => {
                                if (hasParentSeats(parentKey, holds)) {
                                    // if key already exists, don't add it to array 
                                    if (!foundKeys?.includes(`h-${parentKey}`)) foundKeys = [`h-${parentKey}`, ...foundKeys]
                                }
                            })
                        }
                        if (allKillsCategorySeats.length > 0) {
                            // check if leftover seats belong to parent kills
                            foundParentKillKeys.map(parentKey => {
                                if (hasParentSeats(parentKey, kills)) {
                                    // if key already exists, don't add it to array 
                                    if (!foundKeys?.includes(`k-${parentKey}`)) foundKeys = [`k-${parentKey}`, ...foundKeys]
                                }
                            })
                        }
                    }
                }
            }

            // check if seats are in offers 
            if (tab === 'inventory') {
                // Get an array of offers excluding the first offer
                const offersExcludingFirst = Object.values(offers).slice(1);
                let updatedOffers = {}
                offersExcludingFirst.map(offer => {
                    updatedOffers = { ...updatedOffers, [offer.id]: offer }
                })

                // search all offers except first offer (open) if not isGASection to populate the seats assigned in ga popup 
                let data = offers;
                if (isGASection) {
                    data = updatedOffers
                }
                let foundOfferKeys;
                foundOfferKeys = findAssignedKeys(data, seatsToCheck)

                if (foundOfferKeys.length > 0) {
                    foundOfferKeys.map(key => {
                        foundKeys = [...foundKeys, `o-${key}`]
                    })
                }
            }

            return foundKeys
        }
    }

    // gets multiple from object - can be price level, holds, or kills
    const getFromObjs = (ids) => {
        if (ids.length === 0) return []
        // has ids 
        return ids.reduce((result, id) => {
            if (isHoldOrKill(id)) {
                const char = getId(id, 0)

                switch (char) {
                    case 'h':
                        result = [...result, holds];
                        break;

                    case 'k':
                        result = [...result, kills]
                        break

                    default:
                        break;
                }
            } else {
                if (tab === 'scaling') {
                    result = [...result, priceLevels]
                }
                // open offer
                else if (tab === 'inventory') {
                    result = [...result, offers]
                }
            }

            return result;
        }, [])
    }

    // remove from hold/kill/price level
    const handleRemove = (id, removeFrom) => {
        // id to remove seats from
        setRemoveId(id) // used as flag for removing as well as for delete popup
        setRemoveFrom(removeFrom) // for delete popup and removing 

        // if obj already has seats assigned
        if (getObj(id, removeFrom).seats.length > 0) {
            setMoveFromIds([id]) // for moveSeats popup
            setMoveFrom([removeFrom]) // for moveSeats popup

            // hold/kill - add seats to Open
            // price level - filter out seats from removeTo add seats back to unassigned seats
            // dragging state becomes the obj to add seats to: unassigned/open offer
            if (tab === 'scaling') {
                setDragToId(1)
                setDragTo(unassignedSeats)
            }
            else if (tab === 'inventory') {
                const firstKey = Object.keys(offers)[0]
                setDragToId(`o-${firstKey}`)
                setDragTo(offers)
            }
            handleShow()
        }
        else {
            handleShowDelete()
        }
    }

    // get revenue of all price levels minus holds seats and kills seats
    const sumRevenues = (levels) => {
        const totalRevenue = Object.values(levels).reduce((acc, level) => acc + sumRevenue(level), 0)
        const allHoldsSeats = Object.values(holds).reduce((acc, hold) => acc + hold.seats.length, 0)
        const allKillsSeats = Object.values(kills).reduce((acc, kill) => acc + kill.seats.length, 0)
        return totalRevenue - allHoldsSeats - allKillsSeats
    }

    // get revenue of each price level 
    const sumRevenue = (level) => {
        return parseFloat(level?.price) * level?.seats.length
    }

    // all price levels price * seats except for the current one - in the edit price and move popup
    const sumNewRevenues = (level, fromObjs, seatsToMove) => {

        let newPriceLevels = { ...priceLevels }

        // if fromObj, take away level seats from its seats array 
        if (fromObjs?.length > 0) {
            {
                fromObjs.map(obj => {
                    const newFromObj = { ...obj, seats: obj.seats.filter(seat => !seatsToMove.includes(seat)) }
                    newPriceLevels[obj.id] = newFromObj
                })
            }
        }
        // get all price levels that have a price except for current one 
        newPriceLevels = Object.values(newPriceLevels).filter(l => l.id !== level?.id)
        // add sum
        return sumRevenues(newPriceLevels)
    }

    // sellable capacity -> all price level seats minus holds and kills seats 
    const sumCapacity = () => {
        const allPriceLevelsSeats = Object.values(priceLevels).reduce((acc, level) => acc + level.seats.length, 0)
        const allHoldsSeats = Object.values(holds).reduce((acc, hold) => acc + hold.seats.length, 0)
        const allKillsSeats = Object.values(kills).reduce((acc, kill) => acc + kill.seats.length, 0)
        return allPriceLevelsSeats - allHoldsSeats - allKillsSeats
    }

    return (
        <>
            <DndContext
                sensors={sensors}
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
            >
                <aside className='sidebar sidebar-lg'>
                    <div className="sidebar-wrapper sidebar-wrapper-sm">
                        {tab === 'scaling' ? (
                            <ScalingMenu unassignedSeats={unassignedSeats && Object.values(unassignedSeats)[0]} selectUnassigned={selectUnassigned} levels={priceLevels} setLevels={setPriceLevels} offers={Object.values(offers)} handleAdd={handleAdd} handleRemove={handleRemove} moveFromIds={moveFromIds} sumRevenue={sumRevenue} sumNewRevenues={sumNewRevenues} />
                        ) : (
                            <InventoryMenu isEventOnsale={isEventOnsale}
                                holds={holds} kills={kills} offers={offers} sold={sold}
                                moveFromIds={moveFromIds} handleRemove={handleRemove} handleAdd={handleAdd} />
                        )}
                        {priceLevels && Object.values(priceLevels)?.some(level => level.seats.length > 0) && (
                            <div className="financial-info-container">
                                <div className="financial-info">
                                    <Button
                                        variant="default"
                                        className='btn-toggle w-100 px-0'
                                        onClick={() => setOpen(!open)}
                                        aria-controls="financial-info-collapse"
                                        aria-expanded={open}
                                    >
                                        Financial information
                                    </Button>
                                    <Collapse in={open}>
                                        <div>
                                            <FinancialInfo priceLevels={priceLevels}
                                                holds={holds}
                                                kills={kills}
                                                sumRevenues={sumRevenues}
                                                sumCapacity={sumCapacity}
                                            />
                                        </div>
                                    </Collapse>
                                </div>
                            </div>
                        )}
                    </div>
                </aside>
                {selectedSeats.length > 0 && (
                    <Panel selectedSeats={selectedSeats} active={activeDragId} handleRemove={handleRemoveSeats} />
                )}
            </DndContext>

            <SelectGASeatsModal show={showSelectGASeats} openOffer={Object.values(offers)[0]} assigned={holdsKillsOffersWithGASeatIds?.map((id, idx) => getObj(id, holdsKillsOffersWithGASeats[idx]))} seatsPer={gaSeatsPerHoldKillOffer} seatsPerInitialState={gaSeatsPerHoldKillOfferInitialState} count={gaSeatCount} totalSeats={totalGASeats} availableSeats={Object.values(offers)[0]?.seats.filter(seat => totalGASeats?.includes(seat))} hasError={hasGAError} handleCount={handleCount} handleChange={handleChange} handleSubmit={handleSubmitSelectGASeats} handleCancel={handleCancelSelectGASeats} handleClose={handleCloseSelectGASeats} />

            <MoveSeatsModal show={show} id={removeId} seatsToMove={!removeId ? selectedSeats : moveFromIds && getObj(moveFromIds[0], moveFrom[0])?.seats} fromObjs={moveFromIds?.map((id, idx) => getObj(id, moveFrom[idx]))} toObj={getObj(dragToId, dragTo)} isScaling={tab === "scaling"} sumRevenue={sumRevenue} sumNewRevenues={sumNewRevenues} handleClose={handleClose} handleSubmit={handleSubmit} />

            <DeleteModal show={showDelete} fromObj={removeId && getObj(removeId, removeFrom)} data={removeFrom} id={removeId} handleClose={handleClose} handleDelete={handleDelete} />
        </>
    )
}