import React from 'react';

import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';

import { Spinner } from '../../../LoadingContainer/Spinner';

// used when saving existing template/new 
export default function SaveModal({ show, step, setStep, name, setName, isSaving, handleClose, handleCancel, handleSubmit }) {

    const isFirstStep = step === 1;

    return (
        <Modal centered animation={false} fullscreen="md-down" show={show} onHide={handleClose} backdrop="static" className='modal--lg'>
            <Modal.Body>
                <div className="modal-body-heading">
                    <h1 className="modal-body-heading-title">{isFirstStep ? 'Save changes' : 'Save as template'}
                    </h1>
                    <p>{isFirstStep ? 'You are currently working on an existing template. Would you like to save your changes to a new template?' : 'Your configurations will be saved as a template. Make sure you add a unique name to this template.'}</p>
                </div>
                {isFirstStep ? (
                    <Stack className="btn-group-flex">
                        <Button size="lg" onClick={() => setStep(2)}>
                            Save as new template
                        </Button>
                        <Button size="lg" variant="outline-light" onClick={handleClose}>Cancel</Button>
                    </Stack>
                ) : (
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="form-group" controlId="name">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                name="name"
                                placeholder='Template name'
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                autoFocus
                                required
                            />
                        </Form.Group>
                        <Stack direction="horizontal" className="btn-group-flex">
                            <Button variant="outline-light" size="lg" onClick={handleCancel}>
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                size="lg"
                                disabled={!name || isSaving}
                                className='btn-width-lg'
                            >
                                {isSaving ? (
                                    <Spinner />) :
                                    'Add template'}
                            </Button>
                        </Stack>
                    </Form>
                )}
            </Modal.Body>
        </Modal>
    );
}
