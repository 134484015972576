import React from 'react';

import Card from 'react-bootstrap/Card';

import './mapConfigurationCard.scss';

export default function MapConfigurationCard({ type, title, subtitle, children }) {

    return (
        <>
            <Card body className='configuration-card card--md card-with-border text-center'>
                <div className={`create-card-body create-card-body--${type} card-body-heading card-body-heading--sm d-flex-column`}>
                    <Card.Title as="h5">{title}</Card.Title>
                    <Card.Subtitle className='subtitle--dark'>{subtitle}</Card.Subtitle>
                </div>
                {children}
            </Card>
        </>
    );
}
