import React from 'react';

import { capitalizeString, namePatternMatch } from '../../../../utilities/helpers';

import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';

import { WarningCard } from '../../../WarningCard';
import { Spinner } from '../../../LoadingContainer/Spinner';

export default function AddMemberModal({ show, handleClose, roles, member, handleMember, handleRole, handleAdd, id, handleIsEmailValid, isEmailValid, emailErrorMsg, isInviting, isDisabled }) {

    return (
        <Modal className='modal-sm modal--with-margin' centered animation={false} backdrop="static" fullscreen="md-down" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title as="h4">{id ? 'Edit' : 'New'} team member</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form autoComplete="off" onSubmit={handleAdd}>
                    <Form.Group className="form-group" controlId="firstName">
                        <Form.Label>First name</Form.Label>
                        <Form.Control
                            type="text"
                            name="firstName"
                            placeholder="Enter first name"
                            required
                            pattern={namePatternMatch}
                            value={member.firstName || ''}
                            onChange={(e) => handleMember(e.target.validity.valid || e.target.value === '' ? e : member?.firstName)}
                            autoFocus

                        />
                    </Form.Group>
                    <Form.Group className="form-group" controlId="lastName">
                        <Form.Label>Last name</Form.Label>
                        <Form.Control
                            type="text"
                            name="lastName"
                            placeholder="Enter last name"
                            required
                            pattern={namePatternMatch}
                            value={member.lastName || ''}
                            onChange={(e) => handleMember(e.target.validity.valid || e.target.value === '' ? e : member?.lastName)}

                        />
                    </Form.Group>
                    <Form.Group className="form-group" controlId="email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            type="email"
                            name="email"
                            placeholder="Enter email"
                            required
                            disabled={member?.pending}
                            value={member.email || ''}
                            onChange={handleMember}
                            onBlur={(e) => handleIsEmailValid(e.target.value)}
                            className={member?.email && !isEmailValid ? 'error-border' : ''}
                        />
                        {member?.email && !isEmailValid &&
                            <Form.Text className="error">
                                {emailErrorMsg}
                            </Form.Text>
                        }
                    </Form.Group>
                    <Form.Group className="form-group" controlId="role">
                        <Form.Label>Role</Form.Label>
                        <Form.Select
                            name="role"
                            value={member.role?.id || ''}
                            onChange={handleRole}
                            disabled={member?.pending}
                            autoComplete="off"
                        >
                            <option key="-1" disabled value="">Select a Role</option>
                            {roles && roles.map((option, index) => (
                                <option key={index} value={option.id}>{capitalizeString(option.name)}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group controlId="uuid">
                        <Form.Control
                            type="hidden"
                            name="uuid"
                            defaultValue={member ? member?.uuid : ''}
                        />
                    </Form.Group>
                    {member?.pending && (
                        <WarningCard text="Email and Role cannot be changed while invitation is still in pending status" margin="mt" size="lg" styles="card--light card-with-border" />
                    )}
                    <Stack className="btn-group-flex">
                        <Button
                            type="submit"
                            size="lg"
                            disabled={isDisabled}>
                            {isInviting ? (
                                <Spinner />
                            ) :
                                `${id ? 'Update' : 'Add'} team member`
                            }
                        </Button>
                    </Stack>
                </Form>
            </Modal.Body>
        </Modal>
    );
}
