import React from 'react';

import { formatNumber } from '../../../../../../utilities/helpers';

import Modal from 'react-bootstrap/Modal';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';

import { Item } from '../Item';
import { RevenueCard } from '../RevenueCard';

export default function MoveSeatsModal({ show, id, seatsToMove, fromObjs, toObj, sumRevenue, sumNewRevenues, isScaling, handleClose, handleSubmit }) {

    return (
        <Modal show={show} animation={false} onHide={handleClose} centered backdrop='static' className='modal-sm'>
            <Modal.Body>
                <div className="modal-body-heading modal-body-heading-sm">
                    <h1 className='modal-body-heading-title'>{!id ? 'Move' : 'Remove'} seats</h1>
                </div>
                <div className='split-row split-row-lg mb-3'>
                    <span>You are about to move:</span>
                    <span className='num-seats num-seats-lg'>{formatNumber(seatsToMove?.length)}</span>
                </div>
                <div className="list">
                    <Stack gap={5} as="ul">
                        {fromObjs?.length > 0 && (
                            <li>
                                <p className='small fw-medium mb-1'>From</p>
                                <Stack as="ul" gap={2}>
                                    {fromObjs.map((obj, idx) => (
                                        <li key={idx}>
                                            <Item el={obj} size="lg" weight="thin" />
                                        </li>
                                    ))}
                                </Stack>
                            </li>
                        )}
                        <li>
                            <p className='small fw-medium mb-1'>To</p>
                            <Item el={toObj} size="lg" weight="thin" />
                        </li>
                    </Stack>
                </div>
                <div className='seperator seperator-sm split-row split-row-lg'>
                    <span className='normal'>Total new seats</span>
                    <span className='num-seats-lg'>{formatNumber(toObj?.seats?.length + seatsToMove?.length)}</span>
                </div>
                {toObj && isScaling && (
                    <div className="mt-3">
                        <RevenueCard level={!id ? toObj : fromObjs?.map(fromObj => fromObj)} fromObjs={fromObjs?.map(fromObj => fromObj)} seatsToMove={seatsToMove} remove={Boolean(id)} sumRevenue={sumRevenue} sumNewRevenues={sumNewRevenues} />
                    </div>
                )}
                <Stack direction="horizontal" className='btn-group-flex'
                >
                    <Button
                        variant="outline-light"
                        size="lg"
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        size="lg"
                        onClick={handleSubmit}
                    >
                        {!id ? 'Apply' : 'Apply & Delete'}
                    </Button>
                </Stack>
            </Modal.Body>
        </Modal>
    );
}
