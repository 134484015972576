import React, { useState } from 'react';
import { Cropper } from 'react-advanced-cropper';
import 'react-advanced-cropper/dist/style.css'

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Image from 'react-bootstrap/Image';

import { Dropzone } from './Dropzone';

export default function UploadImage({ selectedImage, setSelectedImage, coordinates, setCoordinates, size, altText }) {

    // cropped image inside preview modal 
    const [croppedImage, setCroppedImage] = useState()

    // image when uploading it from file system 
    const [image, setImage] = useState()

    const [show, setShow] = useState(false)

    const handleClose = () => setShow(false)

    const handleSave = () => {
        setSelectedImage(croppedImage);
        handleClose();
    }

    const handleUpload = (img) => {
        setImage(img);
        setShow(true);
    }

    const onCrop = (cropper) => {
        setCoordinates(cropper.getCoordinates());
        setCroppedImage(cropper.getCanvas()?.toDataURL());
    };

    // remove cropped image from popup
    const handleRemove = () => {
        setCroppedImage('')
        setCoordinates()
        setImage()
        handleClose()
    }

    return (
        <>
            {!selectedImage ? (
                <Dropzone handleUpload={handleUpload} />
            ) : (
                <Image src={selectedImage} width={coordinates?.width} height={coordinates?.height} style={{ width: size === 'lg' ? '254px' : '93px', height: size === 'lg' ? '254px' : '93px' }} className='event-image' alt={altText}
                    rounded />
            )}

            {image && (
                <Modal className="modal-lg" centered animation={false} fullscreen="md-down" show={show} onHide={handleClose} backdrop='static' contentClassName='h-md'>
                    <Modal.Body>
                        <div className="modal-body-heading">
                            <h1 className='modal-body-heading-title'>Thumbnail preview</h1>
                        </div>
                        <Cropper
                            src={image[0].preview}
                            maxWidth={1080}
                            maxHeight={1080}
                            onChange={onCrop}
                            className='cropper'
                            autoZoom={false}
                            stencilProps={{
                                aspectRatio: 1 / 1,
                                handlers: {
                                    eastNorth: true,
                                    north: false,
                                    westNorth: true,
                                    west: false,
                                    westSouth: true,
                                    south: false,
                                    eastSouth: true,
                                    east: false
                                }
                            }}
                        />
                    </Modal.Body>
                    <Modal.Footer className='mx-4 px-0'>
                        <Button variant="outline-light"
                            size="lg"
                            onClick={handleRemove}>Cancel</Button>
                        <Button
                            size="lg"
                            onClick={handleSave}>Crop and save</Button>
                    </Modal.Footer>
                </Modal>
            )}
        </>
    );
}
